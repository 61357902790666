import React from 'react';
import { isEmpty, isNaN } from 'lodash';
import NumberFormat from 'react-number-format';
import { URL_DISABLED_FILTER_FLAG, PAGE_OPTIONS } from '../../common/constant';
import {
  toUrlParam,
  isSearchPageActive,
  isViewProjectPageActive,
  searchParams,
} from '../../helpers/urlHelper';
import {
  isUndefined,
  recruitmentStages,
  selectType,
} from '../../helpers/utilityHelper';
import StaticApi from '../../api/staticApi';
import { statics, company, tag } from '../../providers/apiFeedProvider';
import { currencyFormatter } from '../../helpers/currencyHelper';
import CheckBoxFilter from '../../old/components/filter/CheckBoxFilter';
import RangeSelectFilter from '../../old/components/filter/RangeSelectFilter';
import TypeAndPickFilter from '../../old/components/filter/pickAndType/TypeAndPickFilter';
import PickAndSwitchFilterV2 from '../../old/components/filter/pickAndSwitchV2/PickAndSwitchFilterV2';
import { UserTypes } from '../../common/roles';
import FreeTextSearch from '../../old/components/filter/FreeTextSearch/FreeTextSearch';
import StaticSelect from '../../old/components/filter/StaticSelect/StaticSelect';
import TablePagination from '../../old/components/filter/TablePagination/TablePagination';
import TextSearch from '../../old/components/filter/FreeTextSearch/TextSearch';
import PickAndMultiSwitchFilter from '../../old/components/filter/PickAndMultiSwitch/PickAndMultiSwitchFilter';
import TagApi from '../../api/TagApi';

const MIN_MAX_PREFIX = {
  min: 'from',
  max: 'to',
};

const removeDisabledFilters = (filters) => {
  return filters.filter((val) => {
    return val !== URL_DISABLED_FILTER_FLAG;
  });
};

const sortValues = (dataArray) => {
  return dataArray.sort((a, b) => a - b);
};

const getUrlValueAsArray = () => {
  return {
    getUrlValue(queryObj, queryParam) {
      const q = this.q || queryParam;
      let value = queryObj[q];

      if (!value) {
        return [];
      }

      if (!value) {
        return null;
      }

      if (value === URL_DISABLED_FILTER_FLAG) {
        return [URL_DISABLED_FILTER_FLAG];
      }

      if (!Array.isArray(value)) {
        value = [value];
      }

      return removeDisabledFilters(value);
    },
  };
};

const sliderUrlValueAsArray = () => {
  return {
    getUrlValue(queryObj, param) {
      const minParamName = this.q
        ? this.q.min
        : `${param}_${MIN_MAX_PREFIX.min}`;
      const maxParamName = this.q
        ? this.q.max
        : `${param}_${MIN_MAX_PREFIX.max}`;

      let min = queryObj[minParamName];
      let max = queryObj[maxParamName];

      const currencyParamName = this.q ? this.q?.currency : undefined;
      const currency = queryObj[currencyParamName]
        ? queryObj[currencyParamName]
        : 'SGD';

      if (!min && !max && min !== 0 && max !== 0) {
        [min, max] = this.defaultVal;
      }

      if (!min && min !== 0) {
        [min] = this.defaultVal;
      }

      if (!max && max !== 0) {
        [, max] = this.defaultVal;
      }

      if (min > max) {
        [min, max] = this.defaultVal;
      }

      if (currency) {
        return [min, max, currency];
      }

      return [min, max];
    },
  };
};

function searchFilterUrlValueAsObjectsArray() {
  return {
    getUrlValue(queryObj, param) {
      const q = this.q || param;
      const value = queryObj[q];

      if (this.selectType === selectType.SINGLE && value) {
        return value;
      }

      if (!Array.isArray(value)) {
        return [];
      }

      return value;
    },
  };
}

function searchSwitchFilterUrlValueAsObjectsArray() {
  return {
    getUrlValue(queryObj, param) {
      const q = this.q || param;
      const vals = Object.keys(queryObj).filter(
        (qName) => qName.indexOf(`${q}[`) >= 0
      );

      const newVals = vals.map((key) => {
        const val = queryObj[key];
        const id = key.match(/\[([^)]+)\]/)[1];
        return {
          id,
          val,
        };
      });

      return newVals;
    },
  };
}

function getSliderValue() {
  return {
    getValues() {
      return this.enable ? this.value : [];
    },
  };
}

function setHandleValues() {
  return {
    setValues(values) {
      this.value = values;
    },
  };
}

function resetFilter() {
  return {
    reset() {
      this.default = true;
      this.value = this.defaultVal;
    },
  };
}

function initSlider() {
  return {
    init(filters, key) {
      const values = filters[key];
      this.min = values.min;
      this.max = values.max;
      this.defaultVal = [values.min, values.max];
      if (this.default) {
        this.value = [values.min, values.max];
      }
      this.loading = false;
    },
  };
}

function initCheckBox() {
  return {
    init(values, key) {
      this.options = this.setOptions(values[key].options);
      this.loading = false;
    },
  };
}

function getSearchFilterValues() {
  return {
    getValues() {
      return this.value;
    },
  };
}

function setSearchDropDownOptions() {
  return {
    setSearchOptions(options) {
      this.options = options.map((opt) => {
        const { id, label, freeText } = opt;
        return { id, label, freeText };
      });
    },
  };
}

function getCheckBoxValues() {
  return {
    getValues() {
      if (!this.enable) {
        return [];
      }

      const selected = this.default
        ? this.options.map((option) => option.id)
        : this.value;

      return selected;
    },
  };
}

function getNonTranslatedOption() {
  return {
    getOptionLabel(value) {
      return value;
    },
  };
}

function sliderToUrl() {
  return {
    removeUrl(queryName) {
      const minParamName = this.q
        ? this.q.min
        : `${queryName}_${MIN_MAX_PREFIX.min}`;
      const maxParamName = this.q
        ? this.q.max
        : `${queryName}_${MIN_MAX_PREFIX.max}`;

      return {
        [toUrlParam(minParamName)]: undefined,
        [toUrlParam(maxParamName)]: undefined,
      };
    },
    getAsUrl(queryName, newVal) {
      if (newVal === URL_DISABLED_FILTER_FLAG) {
        return this.removeUrl(queryName);
      }

      const values = newVal || this.value;

      if (!Array.isArray(values)) {
        return values;
      }

      const sortedValues = sortValues(values);
      const minParamName = this.q
        ? this.q.min
        : `${queryName}_${MIN_MAX_PREFIX.min}`;
      const maxParamName = this.q
        ? this.q.max
        : `${queryName}_${MIN_MAX_PREFIX.max}`;
      let currencyParamName = '';

      if (queryName === 'salary') {
        currencyParamName = this.q ? this.q.currency : '';
      }

      if (
        sortedValues[0] === this.defaultVal[0] &&
        sortedValues[1] === this.defaultVal[1] &&
        sortedValues[2] === this.defaultVal[2]
      ) {
        if (currencyParamName) {
          return {
            [toUrlParam(minParamName)]: this.defaultVal[0],
            [toUrlParam(maxParamName)]: this.defaultVal[1],
            [toUrlParam(currencyParamName)]: this.defaultVal[2],
          };
        }

        return {
          [toUrlParam(minParamName)]: this.defaultVal[0],
          [toUrlParam(maxParamName)]: this.defaultVal[1],
        };
      }

      if (currencyParamName) {
        return {
          [toUrlParam(minParamName)]: sortedValues[0],
          [toUrlParam(maxParamName)]: sortedValues[1],
          [toUrlParam(currencyParamName)]: sortedValues[2],
        };
      }

      return {
        [toUrlParam(minParamName)]: sortedValues[0],
        [toUrlParam(maxParamName)]: sortedValues[1],
      };
    },
  };
}

function checkBoxToUrl() {
  return {
    getAsUrl(queryName, newVal) {
      const q = this.q || queryName;
      const values = newVal || this.value;

      if (!Array.isArray(values)) {
        return {
          [q]: !isViewProjectPageActive() ? [] : values,
        };
      }

      const sortedValues = sortValues(values);

      return {
        [q]: sortedValues,
      };
    },
  };
}

function searchFilterToUrl() {
  return {
    getAsUrl(queryName, newVal) {
      const values = newVal || this.value;
      const q = this.q || queryName;

      if (this.selectType === selectType.SINGLE) {
        return {
          [q]: newVal === URL_DISABLED_FILTER_FLAG ? undefined : newVal,
        };
      }

      if (!Array.isArray(values)) {
        this.options = [];

        return {
          [q]: [],
        };
      }

      const sortedValues = sortValues(values);

      const queryArray = sortedValues.map((option) => {
        return this.textSearch ? option : option.id;
      });
      this.options = sortedValues;
      return {
        [q]: queryArray,
      };
    },
  };
}

function searchSwitchFilterToUrl() {
  return {
    getAsUrl(queryName, newVal, search) {
      const q = this.q || queryName;

      const locationParams = Object.keys(search).filter(
        (qName) => qName.indexOf(`${q}[`) >= 0
      );

      if (newVal === URL_DISABLED_FILTER_FLAG) {
        return locationParams.reduce((acc, key) => {
          acc[key] = [];
          return acc;
        }, {});
      }

      const vals = newVal.reduce((acc, opt) => {
        if (!(opt.id in acc)) {
          const key = `${q}[${opt.id}]`;
          acc[key] = opt.val ? opt.val : 0;
        }

        return acc;
      }, {});

      locationParams.forEach((param) => {
        if (!(param in vals)) {
          vals[param] = [];
        }
      });

      return vals;
    },
  };
}

function setCheckBoxOptions() {
  return {
    setOptions(values) {
      if (this.textSearch) {
        return values.map((option) => {
          return {
            id: option.toString().toLocaleLowerCase(),
            label: option,
          };
        });
      }
      return values;
    },
  };
}

function toJsonSliderValue() {
  return {
    toJson(queryObj, queryParam, objParam) {
      const q = this.q || queryParam;
      let val = this.getUrlValue(queryObj, q);

      if (!val) {
        val = this.defaultVal;
      }

      const json = this.getAsUrl(objParam, val);
      return json;
    },
  };
}

function toJsonValue() {
  return {
    toJson(queryObj, queryParam) {
      const q = this.q || queryParam;
      return {
        [q]: this.getUrlValue(queryObj, q),
      };
    },
  };
}

function toJsonFilterSwitchValue() {
  return {
    toJson(queryObj, queryParam) {
      const q = this.q || queryParam;
      const values = this.getUrlValue(queryObj, q);
      const obj = values.reduce((acc, val) => {
        acc[`${q}[${val.id}]`] = val.val;
        return acc;
      }, {});

      return {
        ...obj,
      };
    },
  };
}

export function createPerPageFilter(props) {
  return {
    defaultVal: 20,
    param: toUrlParam('pageSize'),
    options: Object.keys(PAGE_OPTIONS).map((key) => ({
      value: PAGE_OPTIONS[key],
      label: PAGE_OPTIONS[key],
    })),
    getAsUrl(queryName, newVal) {
      return {
        [this.param]: !isUndefined(newVal) ? newVal : undefined,
      };
    },
    value: {},
    getUrlValue(queryObj) {
      const perPage = queryObj[this.param];
      return {
        perPage: !isUndefined(perPage) ? perPage : this.defaultVal,
      };
    },
    setValues(values) {
      this.value.perPage = values.perPage ? values.perPage : this.defaultVal;
    },
    toJson(queryObj) {
      const perPage = queryObj[this.param];

      const query = {};

      if (!isUndefined(perPage)) {
        query[this.param] = perPage;
      }
      return query;
    },
    reset() {
      this.setValues({
        perPage: this.defaultVal,
      });
    },
    render(renderProps) {
      return <StaticSelect {...renderProps} options={this.options} />;
    },
    ...props,
  };
}

export function createPageFilter(props) {
  return {
    defaultVal: 1,
    param: toUrlParam('page'),
    getAsUrl(_, newVal) {
      return {
        [this.param]: !isUndefined(newVal) && newVal > 1 ? newVal : undefined,
      };
    },
    value: {},
    getUrlValue(queryObj) {
      const page = queryObj[this.param];
      return {
        page: !isUndefined(page) ? page : this.defaultVal,
      };
    },
    setValues(values) {
      this.value.page = values.page ? values.page : this.defaultVal;
    },
    toJson(queryObj) {
      const page = queryObj[this.param];

      const query = {};

      if (!isUndefined(page)) {
        query[this.param] = page;
      }
      return query;
    },
    reset() {
      this.setValues({
        page: this.defaultVal,
      });
    },
    render(renderProps) {
      return <TablePagination {...renderProps} currentPage={this.value.page} />;
    },
    ...props,
  };
}

export function createTableColumnSortingFilter(props) {
  return {
    paramSortField: toUrlParam('sortBy'),
    paramSortDir: toUrlParam('sortDir'),
    getAsUrl(queryName, newVal) {
      const { sortDir, sortField } = newVal;

      return {
        [this.paramSortField]: sortField,
        [this.paramSortDir]: sortDir,
      };
    },
    value: {},
    getUrlValue(queryObj) {
      const sortBy = queryObj[this.paramSortField];
      const sortDir = queryObj[this.paramSortDir];

      return {
        sortField: !isEmpty(sortBy) ? sortBy : this.defaultSortField,
        sortDir: !isEmpty(sortDir) ? sortDir : this.defaultSortDir,
      };
    },
    setValues(values) {
      this.value.sortField = values.sortField
        ? values.sortField
        : this.defaultSortField;
      this.value.sortDir = values.sortDir
        ? values.sortDir
        : this.defaultSortDir;
    },
    toJson(queryObj) {
      const sortBy = queryObj[this.paramSortField];
      const sortDir = queryObj[this.paramSortDir];

      const query = {};
      if (!isUndefined(sortBy)) {
        query[this.paramSortField] = sortBy;
      }

      if (!isUndefined(sortDir)) {
        query[this.paramSortDir] = sortDir;
      }
      return query;
    },
    reset() {
      this.setValues({
        [this.paramSortField]: this.defaultSortField,
        [this.paramSortDir]: this.defaultSortDir,
      });
    },
    ...props,
  };
}

export function createTextSearchFilter(props, isTextOnly) {
  const newProps = {
    defaultVal: '',
    render(renderProps) {
      return isTextOnly ? (
        <TextSearch {...renderProps} />
      ) : (
        <FreeTextSearch {...renderProps} />
      );
    },
    ...props,
  };

  return {
    ...getSearchFilterValues(),
    ...setHandleValues(),
    ...resetFilter(),
    getUrlValue(queryObj) {
      const value = queryObj[this.param];
      return isEmpty(value) ? '' : value;
    },
    getAsUrl(queryName, newVal) {
      return {
        [this.param]: isEmpty(newVal) ? undefined : newVal,
      };
    },
    toJson(queryObj) {
      const text = queryObj[this.param];

      const query = {};

      if (!isUndefined(text)) {
        query[this.param] = text;
      }

      return query;
    },
    ...newProps,
  };
}

function createSlidingFilter(props) {
  const defaults = {
    enable: true,
    default: true,
    min: 0,
    max: 0,
    currency: 'SGD',
    step: 1,
    loading: true,
    loadingHeight: 0,
    render(renderProps) {
      return <RangeSelectFilter {...renderProps} />;
    },
    ...props,
  };

  const newProps = {
    defaultVal: [defaults.min, defaults.max, defaults.currency],
    value: [defaults.min, defaults.max, defaults.currency],
    ...defaults,
  };

  return {
    ...initSlider(),
    ...resetFilter(),
    ...getSliderValue(),
    ...setHandleValues(),
    ...sliderUrlValueAsArray(),
    ...sliderToUrl(),
    ...toJsonSliderValue(),
    ...newProps,
  };
}

export function createCheckBoxFilter(props) {
  const newProps = {
    enable: true,
    default: true,
    options: [],
    value: [],
    loading: true,
    loadingHeight: 0,
    textSearch: false,
    render(renderProps) {
      return <CheckBoxFilter {...renderProps} />;
    },
    ...props,
  };

  newProps.options = setCheckBoxOptions().setOptions.bind(newProps)(
    newProps.options
  );

  return {
    ...initCheckBox(),
    ...resetFilter(),
    ...getCheckBoxValues(),
    ...setHandleValues(),
    ...getUrlValueAsArray(),
    ...getNonTranslatedOption(),
    ...checkBoxToUrl(),
    ...setCheckBoxOptions(),
    ...toJsonValue(),
    ...newProps,
  };
}

function createSearchFilter(props) {
  const newProps = {
    selectType: selectType.MULTI,
    enableSelectAll: true,
    enable: true,
    default: true,
    options: [],
    value: [],
    loading: true,
    loadingHeight: 150,
    textSearch: true,
    limit: 5,
    render(renderProps) {
      return <TypeAndPickFilter {...renderProps} />;
    },
    ...props,
  };

  return {
    ...getSearchFilterValues(),
    ...setHandleValues(),
    ...searchFilterUrlValueAsObjectsArray(),
    ...searchFilterToUrl(),
    ...setSearchDropDownOptions(),
    ...toJsonValue(),
    ...newProps,
  };
}

function createSearchFilterWithSwitch(props) {
  const newProps = {
    selectType: selectType.MULTI,
    enableSelectAll: true,
    enable: true,
    default: true,
    options: [],
    value: [],
    loading: true,
    loadingHeight: 150,
    textSearch: false,
    limit: 5,
    render(renderProps) {
      return <PickAndSwitchFilterV2 {...renderProps} />;
    },
    ...props,
  };

  return {
    ...getSearchFilterValues(),
    ...setHandleValues(),
    ...searchSwitchFilterUrlValueAsObjectsArray(),
    ...searchSwitchFilterToUrl(),
    ...toJsonFilterSwitchValue(),
    ...newProps,
  };
}

function createSearchFilterWithMultiSwitch(props) {
  const newProps = {
    selectType: selectType.MULTI,
    enableSelectAll: true,
    enable: true,
    default: true,
    options: [],
    value: [],
    loading: true,
    loadingHeight: 150,
    textSearch: false,
    limit: 5,
    render(renderProps) {
      return <PickAndMultiSwitchFilter {...renderProps} />;
    },
    ...props,
  };

  return {
    ...getSearchFilterValues(),
    ...setHandleValues(),
    ...searchSwitchFilterUrlValueAsObjectsArray(),
    ...searchSwitchFilterToUrl(),
    ...toJsonFilterSwitchValue(),
    ...newProps,
  };
}

const nameSearch = createTextSearchFilter({
  param: searchParams.nameSearch,
  visible(roleId) {
    return [UserTypes.Admin].includes(roleId);
  },
});

const textSearch = createTextSearchFilter({
  param: searchParams.textSearch,
});

const companyName = createSearchFilter({
  q: searchParams.companyName,
  loading: false,
  url: company.search.url,
  selectType: selectType.SINGLE,
  enableSelectAll: false,
  showCustomText: true,
  visible(roleId, isSearchPage) {
    if (!isSearchPage) {
      return false;
    }

    return [UserTypes.Admin].includes(roleId);
  },
});

const jobTitle = createSearchFilter({
  q: searchParams.jobTitle,
  loading: false,
  limit: 10,
  url: statics.getJobTitles.url,
  showCustomText: true,
});

const salary = createSlidingFilter({
  q: searchParams.salary,
  min: 0,
  max: 100000,
  step: 100,
  loading: false,
  loadingHeight: 100,
  getMinLabel() {
    return currencyFormatter.format(this.min).noDecimal().shorten().get();
  },
  getMaxLabel() {
    return `${currencyFormatter.format(this.max).noDecimal().shorten().get()}+`;
  },
  getSliderLabel(value) {
    return currencyFormatter.format(value).noDecimal().get();
  },
  inputProps(props) {
    const { inputRef, onChange, t, option, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        allowNegative={false}
      />
    );
  },
});

const noticePeriod = createCheckBoxFilter({
  q: searchParams.noticePeriod,
  options: [
    {
      id: 0,
      label: 'immediate',
    },
    {
      id: 1,
      label: '1_month',
    },
    {
      id: 2,
      label: '2_month',
    },
    {
      id: 3,
      label: '3_month',
    },
  ],
  loading: false,
  loadingHeight: 250,
  default: false,
  getOptions() {
    return this.options;
  },
  getOptionLabel(value, t) {
    const parts = value.toString().split('_');
    if (parts.length > 1) {
      return t('month_count', { count: +parts[0] });
    }
    return t(value);
  },
});

const typeOfEmployment = createCheckBoxFilter({
  q: searchParams.typeOfEmployment,
  options: [
    {
      id: 1,
      label: 'option1',
    },
    {
      id: 2,
      label: 'option2',
    },
    {
      id: 3,
      label: 'option3',
    },
    {
      id: 4,
      label: 'option4',
    },
  ],
  loading: false,
  loadingHeight: 250,
  default: false,
  getOptions() {
    return this.options;
  },
  getOptionLabel(v, t) {
    return t(`typeOfEmploymentOption.${v}.label`);
  },
});

const candidateStatus = createCheckBoxFilter({
  q: searchParams.candidateStatus,
  options: [
    {
      id: 0,
      label: 'option1',
    },
    {
      id: 1,
      label: 'option2',
    },
    {
      id: 2,
      label: 'option3',
    },
    {
      id: 3,
      label: 'option4',
    },
    {
      id: 4,
      label: 'option5',
    },
  ],
  loading: false,
  loadingHeight: 250,
  default: false,
  visible(roleId) {
    return [UserTypes.Admin].includes(roleId);
  },
  getOptions() {
    return this.options;
  },
  getOptionLabel(v, t) {
    return t(`candidateStatusOption.${v}.label`);
  },
});

const experienceAttr = {
  q: searchParams.experience,
  min: 0,
  max: 99,
  step: 1,
  loading: false,
  loadingHeight: 100,
  unit: 'year',
  getMinLabel(t) {
    return `${this.min} ${t(this.unit, { count: this.min })}`;
  },
  getMaxLabel(t) {
    return `${this.max}+ ${t(this.unit, { count: this.max })}`;
  },
  getSliderLabel(value) {
    return value;
  },
  inputProps(props) {
    const { inputRef, onChange, t, option, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        allowNegative={false}
        suffix={` ${t(option.unit, { count: other.value })}`}
      />
    );
  },
};

const experience = createSlidingFilter(experienceAttr);

const experienceForAiMatch = createSlidingFilter({
  ...experienceAttr,
  min: 0,
  max: 80,
});

const locations = createSearchFilterWithSwitch({
  q: searchParams.locations,
  loading: false,
  loadingHeight: 275,
  textSearch: false,
  limit: undefined,
  url: statics.getLocations.url,
  placeHolder: '+ Add location',
  name: 'location',
  asyncObj(params) {
    return StaticApi.getLocations('', params);
  },
});

const workingRemotely = createCheckBoxFilter({
  options: [
    {
      id: true,
      label: 'Only show talent open to remote working',
      tag: searchParams.workingRemotely,
    },
  ],
  loading: false,
  default: false,
  getOptions() {
    return this.options;
  },
  toJson(queryObj, queryParam, objParam) {
    let val = this.getUrlValue(queryObj, queryParam);

    if (!val) {
      val = this.defaultVal;
    }

    const json = this.getAsUrl(objParam, val);
    return json;
  },
  getAsUrl(_, newVal) {
    let values = newVal || this.value;

    if (!Array.isArray(values)) {
      values = isSearchPageActive() ? [] : values;
    } else {
      values = values.sort((a, b) => a - b);
    }

    const enabled = this.options.reduce((acc, opt) => {
      if (values.includes(opt.id)) {
        acc[opt.tag] = true;
      } else {
        acc[opt.tag] = undefined;
      }

      return acc;
    }, {});

    return enabled;
  },
  getUrlValue(queryObj) {
    return this.options.reduce((acc, opt) => {
      if (opt.tag in queryObj) {
        acc.push(opt.id);
      }

      return acc;
    }, []);
  },
});

const currentLocations = createSearchFilter({
  q: searchParams.currentLocations,
  loading: false,
  enableSelectAll: false,
  limit: undefined,
  url: statics.getLocations.url,
  showCustomText: true,
});

const languages = createSearchFilterWithSwitch({
  q: searchParams.languages,
  loading: false,
  loadingHeight: 275,
  textSearch: false,
  limit: undefined,
  url: statics.getLanguages.url,
  placeHolder: '+ Add language',
  name: 'language',
  asyncObj(params) {
    return StaticApi.getLanguages(params);
  },
});

const badges = createSearchFilterWithMultiSwitch({
  q: searchParams.badge,
  loading: false,
  loadingHeight: 275,
  textSearch: false,
  limit: undefined,
  placeHolder: '+ Add tag',
  url: tag.search,
  searchApi: tag.search,
  asyncObj(params) {
    return TagApi.getTagByIds(params);
  },
});

const admins = createSearchFilter({
  q: searchParams.admins,
  loading: false,
  limit: 10,
  url: statics.getAdmins.url,
  showCustomText: false,
  visible(roleId) {
    return [UserTypes.Admin].includes(roleId);
  },
});

const skills = createSearchFilter({
  q: searchParams.skills,
  loading: false,
  limit: undefined,
  url: statics.getSkills.url,
  showCustomText: true,
});

const industry = createSearchFilter({
  q: searchParams.industry,
  loading: false,
  limit: undefined,
  url: statics.getIndustries.url,
  showCustomText: true,
});

const moreOptions = createCheckBoxFilter({
  options: [
    {
      id: 1,
      label: 'Popular profiles',
      tag: 'isp',
      show: ['search', 'project'],
    },
    {
      id: 2,
      label: 'Already shortlisted',
      tag: 'ias',
      show: ['search'],
    },
    {
      id: 3,
      label: 'Hidden',
      tag: 'ih',
      show: ['project'],
    },
    {
      id: 4,
      label: 'Favorited',
      tag: 'isf',
      show: ['project'],
    },
  ],
  loading: false,
  loadingHeight: 275,
  default: false,
  getOptions() {
    const isSearchPage = isSearchPageActive();
    if (isSearchPage) {
      return this.options.filter((opt) => {
        return opt.show.includes('search');
      });
    }

    return this.options.filter((opt) => {
      return opt.show.includes('project');
    });
  },
  toJson(queryObj, queryParam, objParam) {
    let val = this.getUrlValue(queryObj, queryParam);

    if (!val) {
      val = this.defaultVal;
    }

    const json = this.getAsUrl(objParam, val);
    return json;
  },
  getAsUrl(_, newVal) {
    let values = newVal || this.value;

    if (!Array.isArray(values)) {
      values = isSearchPageActive() ? [] : values;
    } else {
      values = values.sort((a, b) => a - b);
    }

    const enabled = this.options.reduce((acc, opt) => {
      if (values.includes(opt.id)) {
        acc[opt.tag] = 1;
      } else {
        acc[opt.tag] = undefined;
      }

      return acc;
    }, {});

    return enabled;
  },
  getUrlValue(queryObj) {
    return this.options.reduce((acc, opt) => {
      if (opt.tag in queryObj) {
        acc.push(opt.id);
      }

      return acc;
    }, []);
  },
});

export const pagination = {
  defaultPage: 1,
  defaultPerPage: 25,
  total: 0,
  paramPage: searchParams.pagination.page,
  paramPerPage: searchParams.pagination.perPage,
  getAsUrl(queryName, newVal, queryObj) {
    const perPage = queryObj[this.paramPerPage];
    const page = parseInt(newVal, 10);

    return {
      [this.paramPage]: !isNaN(page) ? newVal : 1,
      [this.paramPerPage]: perPage,
    };
  },
  value: {},
  getUrlValue(queryObj) {
    const page = queryObj[this.paramPage];
    const perPage = queryObj[this.paramPerPage];

    return {
      [this.paramPage]: page > 0 ? page : this.defaultPage,
      [this.paramPerPage]: perPage > 0 ? perPage : this.defaultPerPage,
    };
  },
  setValues(values) {
    this.value[this.paramPage] = values[this.paramPage]
      ? values[this.paramPage]
      : this.defaultPage;
    this.value[this.paramPerPage] = values[this.paramPerPage]
      ? values[this.paramPerPage]
      : this.defaultPerPage;
  },
  toJson(queryObj) {
    const page = queryObj[this.paramPage];
    const perPage = queryObj[this.paramPerPage];

    const query = {};
    if (!isUndefined(page)) {
      query[this.paramPage] = page;
    }

    if (!isUndefined(perPage)) {
      query[this.paramPerPage] = perPage;
    }
    return query;
  },
  reset() {
    this.setValues({
      [this.paramPage]: this.defaultPage,
      [this.paramPerPage]: this.defaultPerPage,
    });
  },
};

const sorting = {
  options: [
    {
      id: 'recently_added',
      label: 'recently_added',
      visible: true,
    },
    {
      id: 'best_match',
      label: 'best_match',
      visible: true,
    },
  ],
  defaultValue: 'best_match',
  value: 'best_match',
  getAsUrl(queryName, newVal) {
    return {
      [queryName]: newVal,
    };
  },
  getUrlValue(queryObj, queryParam) {
    const sort = queryObj[queryParam];
    let value = sort;

    if (isUndefined(value)) {
      value = this.default;
    } else {
      const option = this.options.find((opt) => {
        return opt.id === value;
      });

      if (option) {
        value = option.id;
      } else {
        value = this.default;
      }
    }

    return value;
  },
  setValues(value) {
    this.value = value;
  },
  toJson(queryObj, queryParam) {
    if (!queryObj[queryParam]) {
      return {};
    }

    return {
      [queryParam]: this.getUrlValue(queryObj, queryParam),
    };
  },
  reset() {
    this.setValues(this.defaultValue);
  },
};

const tabs = recruitmentStages;

const tab = {
  options: [...tabs],
  defaultValue: tabs[0].id,
  value: tabs[0].id,
  getAsUrl(queryName, newVal, newQueryObj) {
    const removedParams = Object.fromEntries(
      Object.keys(newQueryObj).map((key) => [key, undefined])
    );

    return {
      ...removedParams,
      [queryName]: newVal !== 0 ? newVal : undefined,
    };
  },
  getUrlValue(queryObj, queryParam) {
    const activeTab = queryObj[queryParam];
    let value = activeTab;

    if (isUndefined(value)) {
      value = this.defaultValue;
    } else {
      const option = this.options.find((opt) => {
        return opt.id === value;
      });

      if (option) {
        value = option.id;
      } else {
        value = this.default;
      }
    }

    return value;
  },
  setValues(value) {
    this.value = value;
  },
  toJson(queryObj, queryParam) {
    if (!queryObj[queryParam]) {
      return {};
    }

    return {
      [queryParam]: this.getUrlValue(queryObj, queryParam),
    };
  },
  reset() {
    this.setValues(this.defaultValue);
  },
};

const createDataObj = (allTabs) => {
  const data = {};
  allTabs.forEach((t) => {
    data[t.id] = {
      ...t,
      count: 0,
      profiles: [],
      loading: false,
      requestBlocked: false,
    };
  });

  return data;
};

const createBulkUpdateObj = (allTabs) => {
  const data = {};
  allTabs.forEach((t) => {
    data[t.id] = {
      profiles: [],
    };
  });

  return data;
};

const initialState = {
  filters: {
    textSearch,
    nameSearch,
    companyName,
    jobTitle,
    admins,
    salary,
    noticePeriod,
    experience,
    experienceForAiMatch,
    locations,
    currentLocations,
    languages,
    skills,
    industry,
    moreOptions,
    typeOfEmployment,
    workingRemotely,
    candidateStatus,
    badges,
    pagination: {
      ...pagination,
    },
    sorting,
    tab,
  },
  meta: {
    path: '',
    onSaveAsProject: {
      active: false,
      origin: '',
      saving: false,
      profiles: [],
    },
    project: null,
    projectSharedInfo: {},
    projectSharedUserList: [],
    addToAnotherProject: {
      active: false,
      requestInProgress: false,
      profile: null,
    },
    hideProfile: {
      active: false,
      requestInProgress: false,
      profile: null,
    },
    jobDescription: {
      active: false,
      requestInProgress: false,
      profile: null,
      requestAction: null,
    },
    lastRequest: {
      params: {},
    },
    openProjectsList: {
      active: false,
      requestInProgress: false,
      profile: null,
      data: [],
    },
    bulkSelect: {
      profiles: createBulkUpdateObj(tabs),
      semiActiveProfiles: createBulkUpdateObj(tabs),
      requestInProgress: false,
      requestInProgressOrigin: null,
    },
    projectCandidateSearchStart: false,
    candidateAnonymousRevealConfirm: {
      isOpen: false,
      isSingleCandidate: false,
      isSkipCandidate: false,
      isBulkCandidate: false,
      data: null,
    },
  },
  data: createDataObj(tabs),
  resetData() {
    this.data = createDataObj(tabs);
    this.meta = {
      ...this.meta,
      bulkSelect: {
        ...this.meta.bulkSelect,
        profiles: createBulkUpdateObj(tabs),
        semiActiveProfiles: createBulkUpdateObj(tabs),
      },
    };
  },
};

export default initialState;
