import * as types from '../actionTypes';
import initialState, { editAccount } from './initialState';

export default function authorizationReducer(
  state = initialState.currentUser,
  action
) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...action.payload,
        isInitialAuthCheckDone: true,
      };
    case types.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        username: `${action.payload.firstName} ${action.payload.lastName}`,
        isInitialAuthCheckDone: true,
      };
    case types.LOGOFF_SUCCESS:
      return {
        isLoggedIn: false,
        username: '',
        role: 0,
        isInitialAuthCheckDone: true,
      };
    case types.UPDATE_IS_WATCHED_INFO_VIDEO:
      return {
        ...state,
        isWatchedInfoVideo: action.payload.isWatchedInfoVideo,
      };
    case types.UPDATE_LAST_SCHEDULE_DATE:
      return {
        ...state,
        lastScheduleData: action.payload.lastScheduleData,
      };
    default:
      return state;
  }
}

export const editAccountReducer = (state = editAccount, action) => {
  switch (action.type) {
    case types.GET_ACCOUNT_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          getUserInProgress: true,
        },
      };
    case types.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          getUserInProgress: false,
        },
        data: {
          ...action.payload,
        },
      };
    case types.UPDATE_ACCOUNT_VALUES:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case types.UPDATE_ACCOUNT_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          updateUserInProgress: true,
        },
      };
    case types.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          updateUserInProgress: false,
        },
        data: {
          ...action.payload,
        },
      };
    case types.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          updateUserInProgress: false,
        },
      };
    default:
      return state;
  }
};
