// ALERT TYPES
export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';

// AUTH USER SIGNUP ACTION TYPES
export const AUTH_USER_SIGNUP_REQUEST = 'AUTH_USER_SIGNUP_REQUEST';
export const AUTH_USER_SIGNUP_SUCCESS = 'AUTH_USER_SIGNUP_SUCCESS';
export const AUTH_USER_SIGNUP_ERROR = 'AUTH_USER_SIGNUP_ERROR';

// AUTH USER SIGNIN ACTION TYPES
export const AUTH_USER_SIGNIN_REQUEST = 'AUTH_USER_SIGNIN_REQUEST';
export const AUTH_USER_SIGNIN_SUCCESS = 'AUTH_USER_SIGNIN_SUCCESS';
export const AUTH_USER_SIGNIN_ERROR = 'AUTH_USER_SIGNIN_ERROR';

// USER AUTH SUCCESS
export const USER_AUTHORIZED_CHECKED_SUCCESS =
  'USER_AUTHORIZED_CHECKED_SUCCESS';

// USER AUTH ACTONS
export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REDIRECT_TO_LOGIN_PAGE = 'LOGIN_REDIRECT_TO_LOGIN_PAGE';
export const SET_LOGIN_REDIRECT = 'LOGIN_REDIRECT_TO_CUSTOM_PAGE';
export const GET_ACCOUNT_START = 'GET_ACCOUNT_START';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_VALUES = 'UPDATE_ACCOUNT_VALUES';
export const UPDATE_ACCOUNT_START = 'UPDATE_ACCOUNT_START';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';
export const UPDATE_IS_WATCHED_INFO_VIDEO = 'UPDATE_IS_WATCHED_INFO_VIDEO';
export const UPDATE_LAST_SCHEDULE_DATE = 'UPDATE_LAST_SCHEDULE_DATE';

// APP ERRORS
export const SET_APP_ERROR = 'SET_APP_ERROR';
export const CLEAR_APP_ERROR = 'CLEAR_APP_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOFF_SUCCESS = 'LOGOFF_SUCCESS';

export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const AJAX_CALL_SUCCESS = 'AJAX_CALL_SUCCESS';

// SEARCH FILTER
export const CHANGE_FILTER_VALUE = 'CHANGE_FILTER_VALUE';
export const INITIATE_FILTERS = 'INITIATE_FILTERS';
export const SEARCH_START = 'SEARCH_START';
export const SET_PROJECT_TAB = 'SET_PROJECT_TAB';
export const SEARCH_BLOCKED = 'SEARCH_BLOCKED';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const OPEN_ADD_PROJECT_POPUP = 'OPEN_ADD_PROJECT_POPUP';
export const CLOSE_ADD_PROJECT_POPUP = 'CLOSE_ADD_PROJECT_POPUP';
export const CLOSE_ADD_PROJECT_POPUP_SUCCESS =
  'CLOSE_ADD_PROJECT_POPUP_SUCCESS';
export const START_PROJECT_ADD = 'START_PROJECT_ADD';
export const SUCCESS_PROJECT_ADD = 'SUCCESS_PROJECT_ADD';
export const SUCCESS_PROJECT_UPDATE = 'SUCCESS_PROJECT_UPDATE';
export const START_RECRUITMENT_STATUS_CHANGE =
  'START_RECRUITMENT_STATUS_CHANGE';
export const START_RECRUITMENT_STATUS_BULK_CHANGE =
  'START_RECRUITMENT_STATUS_BULK_CHANGE';
export const RECRUITMENT_STATUS_CHANGE_SUCCESS =
  'RECRUITMENT_STATUS_CHANGE_SUCCESS';
export const RECRUITMENT_STATUS_BULK_CHANGE_SUCCESS =
  'RECRUITMENT_STATUS_BULK_CHANGE_SUCCESS';
export const OPEN_ADD_TO_ANOTHER_PROJECT = 'OPEN_ADD_TO_ANOTHER_PROJECT';
export const CLOSE_ADD_TO_ANOTHER_PROJECT = 'CLOSE_ADD_TO_ANOTHER_PROJECT';
export const OPEN_HIDE_PROFILE = 'OPEN_HIDE_PROFILE';
export const CLOSE_HIDE_PROFILE = 'CLOSE_HIDE_PROFILE';
export const START_ADD_TO_ANOTHER_PROJECT = 'START_ADD_TO_ANOTHER_PROJECT';
export const SUCCESS_ADD_TO_ANOTHER_PROJECT = 'SUCCESS_ADD_TO_ANOTHER_PROJECT';

export const OPEN_HIDE_PROFILE_CONFIRMATION = 'OPEN_HIDE_PROFILE_CONFIRMATION';
export const CLOSE_HIDE_PROFILE_CONFIRMATION =
  'CLOSE_HIDE_PROFILE_CONFIRMATION';

export const START_HIDE_PROFILE = 'START_HIDE_PROFILE';
export const SUCCESS_HIDE_PROFILE = 'SUCCESS_HIDE_PROFILE';

export const START_PROFILE_OPTION_REQUEST = 'START_PROFILE_OPTION_REQUEST';
export const SUCCESS_PROFILE_OPTION_REQUEST = 'SUCCESS_PROFILE_OPTION_REQUEST';

export const OPEN_JOB_DESCRIPTION_POPUP = 'OPEN_JOB_DESCRIPTION_POPUP';
export const CLOSE_JOB_DESCRIPTION_POPUP = 'CLOSE_JOB_DESCRIPTION_POPUP';

export const START_SEND_JOB_DESCRIPTION = 'START_SEND_JOB_DESCRIPTION';
export const SUCCESS_SEND_JOB_DESCRIPTION = 'SUCCESS_SEND_JOB_DESCRIPTION';

export const START_SEND_INTERVIEW_REQUEST = 'START_SEND_INTERVIEW_REQUEST';
export const SUCCESS_SEND_INTERVIEW_REQUEST = 'SUCCESS_SEND_INTERVIEW_REQUEST';

export const START_FETCH_PROJECT = 'START_FETCH_PROJECT';
export const SUCCESS_FETCH_PROJECT = 'SUCCESS_FETCH_PROJECT';

export const START_FETCH_PROJECT_SHARED_USER_INFO =
  'START_FETCH_PROJECT_SHARED_USER_INFO';
export const SUCCESS_FETCH_PROJECT_SHARED_USER_INFO =
  'SUCCESS_FETCH_PROJECT_SHARED_USER_INFO';
export const START_FETCH_PROJECT_SHARED_USER_LIST =
  'START_FETCH_PROJECT_SHARED_USER_LIST';
export const SUCCESS_FETCH_PROJECT_SHARED_USER_LIST =
  'SUCCESS_FETCH_PROJECT_SHARED_USER_LIST';

export const START_PROFILE_PROJECT_LIST_FETCH =
  'START_PROFILE_PROJECT_LIST_FETCH';
export const SUCCESS_PROFILE_PROJECT_LIST_FETCH =
  'SUCCESS_PROFILE_PROJECT_LIST_FETCH';
export const CLOSE_PROFILE_PROJECT_LIST_FETCH =
  'CLOSE_PROFILE_PROJECT_LIST_FETCH';

export const SLIDING_FILTER_CHANGE_MIN_MAX = 'SLIDING_FILTER_CHANGE_MIN_MAX';
export const REMOVE_CANDIDATE_PROFILE_FROM_SEARCH =
  'REMOVE_CANDIDATE_PROFILE_FROM_SEARCH';
export const BULK_SELECT_PROFILE = 'BULK_SELECT_PROFILE';

export const PROJECT_CANDIDATE_SEARCH_START = 'PROJECT_CANDIDATE_SEARCH_START';
export const PROJECT_CANDIDATE_SEARCH_STOP = 'PROJECT_CANDIDATE_SEARCH_STOP';

// Show candidate anonymous reveal confirm
export const SHOW_SINGLE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM =
  'SHOW_SINGLE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM';
export const SHOW_SKIP_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM =
  'SHOW_SKIP_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM';
export const SHOW_BULK_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM =
  'SHOW_BULK_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM';
export const CLOSE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM =
  'CLOSE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM';

// project list
export const GET_PROJECT_STATUS_STATS = 'GET_PROJECT_STATUS_STATS';
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST';
export const UPDATE_CHECKBOX = 'UPDATE_CHECKBOX';
export const UPDATE_PROJECT_ADD_MODAL_OPEN = 'UPDATE_PROJECT_ADD_MODAL_OPEN';
export const PROJECT_TYPE_MODAL_OPEN = 'PROJECT_TYPE_MODAL_OPEN';
export const UPDATE_PROJECT_STATUS = 'UPDATE_PROJECT_STATUS';
export const UPDATE_PROJECTS_STATUS = 'UPDATE_PROJECTS_STATUS';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';
export const DELETE_PROJECTS = 'DELETE_PROJECTS';
export const SHOW_HIDE_PROJECT_CREATE_INFO = 'SHOW_HIDE_PROJECT_CREATE_INFO';
export const GET_PROJECT = 'GET_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const FETCH_TAB_COUNTS = 'FETCH_TAB_COUNTS';

// snack bar
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';

// candidate flow
export const GET_CANDIDATE_OBJECT = 'GET_CANDIDATE_OBJECT';
export const UPDATE_CANDIDATE_OBJECT = 'UPDATE_CANDIDATE_OBJECT';
export const SAVE_EDUCATION_CANDIDATE_OBJECT =
  'SAVE_EDUCATION_CANDIDATE_OBJECT';
export const SAVE_EXPERIENCE_CANDIDATE_OBJECT =
  'SAVE_EXPERIENCE_CANDIDATE_OBJECT';
export const UPDATE_EXPERIENCE_CANDIDATE_OBJECT =
  'UPDATE_EXPERIENCE_CANDIDATE_OBJECT';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const DELETE_SKILL = 'DELETE_SKILL';
export const ADD_SKILL = 'ADD_SKILL';
export const UPDATE_SKILL = 'UPDATE_SKILL';
export const UPDATE_CANDIDATE_PROFILE_PIC = 'UPDATE_CANDIDATE_PROFILE_PIC';
export const UPDATE_VISA = 'UPDATE_VISA';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const ADD_LANGUAGE = 'ADD_LANGUAGE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const DELETE_EDUCATION = 'DELETE_EDUCATION';
export const UPDATE_FIRST_LEVEL_VALUES = 'UPDATE_FIRST_LEVEL_VALUES';
export const GET_CANDIDATE_OBJECT_STEP_4 = 'GET_CANDIDATE_OBJECT_STEP_4';
export const SET_EDUCATION_DIALOG_STATUS = 'SET_EDUCATION_DIALOG_STATUS';
export const SET_EXPERIENCE_DIALOG_STATUS = 'SET_EXPERIENCE_DIALOG_STATUS';
export const REMOVE_CANDIDATE_PROFILE = 'REMOVE_CANDIDATE_PROFILE';

// notification
export const GET_NOTIFICATION = 'GET_NOTIFICATION';

// company list
export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';
export const UPDATE_FIRST_LEVEL_COMPANY_VALUES =
  'UPDATE_FIRST_LEVEL_COMPANY_VALUES';
export const UPDATE_COMPANY_STATUS = 'UPDATE_COMPANY_STATUS';
export const UPDATE_FILTER_STATUS = 'UPDATE_FILTER_STATUS';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const UPDATE_COMPANY_ADD_MODAL_OPEN = 'UPDATE_COMPANY_ADD_MODAL_OPEN';

// company user
export const GET_COMPANY_USER_ROLE_STATS = 'GET_COMPANY_USER_ROLE_STATS';
export const GET_COMPANY_USER_LIST = 'GET_COMPANY_USER_LIST';
export const GET_COMPANY_USER = 'GET_COMPANY_USER';
export const GET_COMPANY_USER_ERROR = 'GET_COMPANY_USER_ERROR';
export const CLEAR_COMPANY_USER = 'CLEAR_COMPANY_USER';
export const UPDATE_FIRST_LEVEL_COMPANY_USER_VALUES =
  'UPDATE_FIRST_LEVEL_COMPANY_USER_VALUES';
export const UPDATE_COMPANY_USER_STATUS = 'UPDATE_COMPANY_USER_STATUS';
export const UPDATE_COMPANY_USER_ROLE = 'UPDATE_COMPANY_USER_ROLE';
export const UPDATE_COMPANY_USER_FILTER_STATUS =
  'UPDATE_COMPANY_USER_FILTER_STATUS';
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';
export const UPDATE_COMPANY_USER_ADD_MODAL_OPEN =
  'UPDATE_COMPANY_USER_ADD_MODAL_OPEN';

// app
export const CHANGE_PAGE_TITLE = 'CHANGE_PAGE_TITLE';

// admin
export const GET_ADMIN_LIST = 'GET_ADMIN_LIST';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const GET_ADMIN = 'GET_ADMIN';
export const UPDATE_ADMIN_USER_VALUES = 'UPDATE_ADMIN_USER_VALUES';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_STATUS = 'UPDATE_ADMIN_STATUS';
export const CLEAR_ADMIN = 'CLEAR_ADMIN';

// credit log
export const GET_CREDIT_LOG = 'GET_CREDIT_LOG';
export const ADD_CREDITS_MODAL_OPEN = 'ADD_CREDITS_MODAL_OPEN';
export const GENERATE_ORDER_FORM_MODAL_OPEN = 'GENERATE_ORDER_FORM_MODAL_OPEN';

// active credits
export const GET_ACTIVE_CREDITS = 'GET_ACTIVE_CREDITS';

// candidates
export const ADMIN_CANDIDATE_LIST_SEARCH_STARTS =
  'ADMIN_CANDIDATE_LIST_SEARCH_STARTS';
export const ADMIN_CANDIDATE_LIST_SEARCH_SUCCESS =
  'ADMIN_CANDIDATE_LIST_SEARCH_SUCCESS';
export const ADMIN_CANDIDATE_TOGGLE_SELECT = 'ADMIN_CANDIDATE_TOGGLE_SELECT';
export const ADMIN_CANDIDATE_TOGGLE_SELECT_ALL =
  'ADMIN_CANDIDATE_TOGGLE_SELECT_ALL';
export const TOGGLE_CANDIDATE_STATE_CHANGE_CONFIRMATION =
  'TOGGLE_CANDIDATE_STATE_CHANGE_CONFIRMATION';
export const CANDIDATE_STATE_CHANGE_START = 'CANDIDATE_STATE_CHANGE_START';
export const CANDIDATE_STATE_CHANGE_SUCCESS = 'CANDIDATE_STATE_CHANGE_SUCCESS';
export const ADMIN_CANDIDATE_LIST_STAT_FETCH_SUCCESS =
  'ADMIN_CANDIDATE_LIST_STAT_FETCH_SUCCESS';

// Candidate notes
export const CANDIDATE_NOTES_START_PROCESS = 'CANDIDATE_NOTES_START_PROCESS';
export const CANDIDATE_NOTES_END_PROCESS = 'CANDIDATE_NOTES_END_PROCESS';
export const CANDIDATE_NOTES_START_SUBMITTING =
  'CANDIDATE_NOTES_START_SUBMITTING';
export const CANDIDATE_NOTES_END_SUBMITTING = 'CANDIDATE_NOTES_END_SUBMITTING';
export const INIT_CANDIDATE_NOTES = 'INIT_CANDIDATE_NOTES';
export const LOAD_CANDIDATE_NOTES = 'LOAD_CANDIDATE_NOTES';
export const RESET_CANDIDATE_NOTES = 'LOAD_CANDIDATE_NOTES';
export const PICK_NOTE_FOR_EDIT = 'PICK_NOTE_FOR_EDIT';
export const RESET_NOTE_FOR_EDIT = 'RESET_NOTE_FOR_EDIT';
export const SET_CANDIDATE_NOTES_LOADED_PAGE_NO =
  'SET_CANDIDATE_NOTES_LOADED_PAGE_NO';
export const SET_CANDIDATE_NOTES_SCROL_TOP_SET =
  'SET_CANDIDATE_NOTES_SCROL_TOP_SET';
export const CANDIDATE_NOTE_ADDED = 'CANDIDATE_NOTE_ADDED';

// Candidate nots crud
export const ADD_CANDIDATE_NOTE = 'ADD_CANDIDATE_NOTE';
export const UPDATE_CANDIDATE_NOTE = 'UPDATE_CANDIDATE_NOTE';
export const DELETE_CANDIDATE_NOTE = 'DELETE_CANDIDATE_NOTE';

// Page on load
export const PAGE_LOAD_CHECK_COMPLETE = 'PAGE_LOAD_CHECK_COMPLETE';
export const CHANGE_SHOW_STATE = 'CHANGE_SHOW_STATE';
export const HIDE_ALL_PAGE_LOAD_COMPONENT = 'HIDE_ALL_PAGE_LOAD_COMPONENT';
export const SET_GLOBAL_URL_DATA = 'SET_GLOBAL_URL_DATA';

// Page on load - Interview request
export const SET_SHOW_HIDE_INTERVIEW_REQUEST =
  'SET_SHOW_HIDE_INTERVIEW_REQUEST';

// Tags
export const TAG_LIST_SEARCH_STARTS = 'TAG_LIST_SEARCH_STARTS';
export const TAG_LIST_SEARCH_SUCCESS = 'TAG_LIST_SEARCH_SUCCESS';
export const TAG_TOGGLE_SELECT = 'TAG_TOGGLE_SELECT';
export const TAG_TOGGLE_SELECT_ALL = 'TAG_TOGGLE_SELECT_ALL';
export const TOGGLE_TAG_STATE_CHANGE_CONFIRMATION =
  'TOGGLE_TAG_STATE_CHANGE_CONFIRMATION';
export const TAG_STATE_CHANGE_START = 'TAG_STATE_CHANGE_START';
export const TAG_STATE_CHANGE_SUCCESS = 'TAG_STATE_CHANGE_SUCCESS';
export const TAG_MANAGE_STARTS = 'TAG_MANAGE_STARTS';
export const TAG_MANAGE_ENDS = 'TAG_MANAGE_ENDS';
export const TAG_MANAGE_CHANGE_INPUT = 'TAG_MANAGE_CHANGE_INPUT';
export const TAG_CREATE_SUCCESS = 'TAG_CREATE_SUCCESS';
export const TAG_UPDATE_REQUEST_START = 'TAG_UPDATE_REQUEST_START';
export const TAG_UPDATE_REQUEST_COMPLETED = 'TAG_UPDATE_REQUEST_COMPLETED';
export const TAG_UPDATE_REQUEST_FAILED = 'TAG_UPDATE_REQUEST_FAILED';

// Integration
export const GET_COMPANY_INTEGRATION_LIST_START =
  'GET_COMPANY_INTEGRATION_LIST_START';
export const GET_COMPANY_INTEGRATION_LIST_COMPLETE =
  'GET_COMPANY_INTEGRATION_LIST_COMPLETE';
export const COMPANY_INTEGRATION_MANUAL_SYNC_PROCESS =
  'COMPANY_INTEGRATION_MANUAL_SYNC_PROCESS';
