import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import FilterLoading from '../../loading/FilterLoading';
import { isUndefined } from '../../../../helpers/utilityHelper';
import MultiSelectAsynchronousAutoComplete from '../../dropdown/MultiSelectAsynchronousAutoComplete';
import ClearFiltersButton from '../ClearFiltersButton';

const useStyle = makeStyles({
  filter: {
    maxWidth: '420px',
    '& .MuiChip-deletable': {
      '& .MuiChip-deleteIcon': {
        visibility: 'hidden',
      },
      '&:hover': {
        '& .MuiChip-deleteIcon': {
          visibility: 'visible',
        },
      },
    },
  },
});

const PickAndMultiSwitchFilter = ({
  onChange,
  option,
  field,
  overrideValues,
  onReset,
}) => {
  const [tCommon] = useTranslation('common');

  const classes = useStyle();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isHideClear, setIsHideClear] = useState(false);

  const updateObjectValues = (name, value) => {
    const data = [];
    value.forEach((eachData) => {
      data.push({ id: eachData.id.replace('Tags:', ''), val: 1 });
    });
    onChange(field, data, option);
  };

  const values = useMemo(() => {
    const val = !isUndefined(overrideValues)
      ? overrideValues.value
      : option.getValues();

    if (!val || val.length === 0) {
      setOptions([]);
      setIsHideClear(true);
    } else {
      setIsHideClear(false);
    }

    return val;
  }, [option, overrideValues]);

  useEffect(() => {
    let active = true;
    setLoading(true);

    (async () => {
      if (values && values.length > 0) {
        const response = await option.asyncObj({ id: values.map((v) => v.id) });

        if (active) {
          const data = response.data.map((opt) => {
            const { id, label, src } = opt;
            return {
              id,
              label,
              src,
            };
          });
          setOptions(data);
        }
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearValues = useCallback(() => {
    onReset(field, [], option);
  }, [field, onReset, option]);

  return (
    <div className='filters__item'>
      {!loading ? (
        <>
          <div className='filters__head'>
            <div className='filters__title'>{tCommon(field)}</div>
            {!isHideClear && (
              <ClearFiltersButton
                label={tCommon('labels.clear')}
                clearValues={clearValues}
              />
            )}
          </div>
          <div className={`${classes.filter} filters__body`}>
            <MultiSelectAsynchronousAutoComplete
              name={field}
              value={options}
              placeHolder={option.placeHolder}
              onChanges={updateObjectValues}
              api={option.searchApi}
              autoCompleteProps={{
                selectOnFocus: false,
                clearOnBlur: true,
                handleHomeEndKeys: true,
              }}
              searchPreventOnEmpty
            />
          </div>
        </>
      ) : (
        <FilterLoading />
      )}
    </div>
  );
};

export default memo(PickAndMultiSwitchFilter);
