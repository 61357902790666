export const initProfile = {
  userDetails: {
    created_at: '',
    email: '',
    first_name: '',
    id: 0,
    last_name: '',
    status: 0,
  },
};

export const projects = {
  projectStatusStats: [],
  data: {
    data: {},
    pagination: {
      currentPage: 0,
      pageSize: 0,
      pagesCount: 0,
      dataCount: 0,
    },
    filters: {
      Active: 0,
      Inactive: 0,
      Paused: 0,
    },
    success: true,
  },
  isProjectAddModalOpen: false,
  isProjectTypeModalOpen: false,
  isShowProjectCreateInfo: false,
};

export const singleProject = {
  data: {},
};

export const skillsAndLanguages = {
  data: {
    languages: {},
    skills: {},
    success: true,
  },
};

// data, is-loading
export const candidateObject = {
  data: {},
};

export const candidateNotes = {
  data: [],
  pageNo: 1,
  lastScrollTop: 0,
  allNotesLoaded: false,
  allNotesLoadedTemp: false,
  editNote: {
    key: null,
    guid: null,
    note: '',
    delete: false,
  },
  loading: false,
  submitting: false,
};

export const singleCompany = {
  data: {
    companyName: '',
    legalEntityName: '',
    companyRegistrationNumber: '',
    address: '',
    companyType: 2,
    companyStatus: 1,
    alternateCompanyNames: [],
    validDomains: [],
    linkedInUrl: '',
    country: [],
    ats: '',
    companySource: '',
    companySourceOther: '',
  },
};

export const singleCompanyUser = {
  data: {
    firstName: '',
    lastName: '',
    email: '',
    status: 1,
    phone: '',
    jobTitle: '',
    companyData: null,
  },
};

export const alert = {
  severity: 'success',
};

export default {
  currentUser: {
    username: '',
    role: 0,
    subUserRole: 0,
    profilePic: null,
    companyLogo: null,
    companyName: '',
    isLoggedIn: false,
    accountStatus: 0,
    userId: '',
    authorizedModules: {},
    adminId: undefined,
    isInitialAuthCheckDone: false,
    isWatchedInfoVideo: true,
  },

  ajaxCallSuccess: true,
};

export const notifications = {
  data: {},
};

export const companies = {
  data: {
    data: {},
    pagination: {
      currentPage: 0,
      pageSize: 0,
      pagesCount: 0,
      dataCount: 0,
    },
    filters: {
      pendingRequest: 0,
      inactive: 0,
      active: 0,
    },
    success: true,
  },
  isCompanyAddModalOpen: false,
};

export const companyUsers = {
  userRoleStats: [],
  data: {
    data: {},
    pagination: {
      currentPage: 0,
      pageSize: 0,
      pagesCount: 0,
      dataCount: 0,
    },
    filters: {
      pendingRequest: 0,
      inactive: 0,
      active: 0,
    },
    success: true,
  },
  isCompanyUserAddModalOpen: false,
};

export const adminList = {
  data: {
    data: {},
    pagination: {
      currentPage: 0,
      pageSize: 0,
      pagesCount: 0,
      dataCount: 0,
    },
    filters: {
      inactive: 0,
      active: 0,
    },
    success: true,
  },
};

export const creditLogList = {
  data: {
    data: {},
    pagination: {
      currentPage: 1,
      dataCount: 0,
      orderBy: 'ca',
      orderDir: 'desc',
      pageSize: 25,
      pagesCount: 0,
      total: 0,
    },
    filters: {
      CreditsAddedByGritAdmin: 0,
      CreditsDeductedForExpiry: 0,
      CreditsDeductedForProjectCreation: 0,
      CreditsDeductedForProjectRenewal: 0,
      CreditsDeductedForProjectUpgrade: 0,
      CreditsDeductedForGuaranteeCandidate: 0,
      CreditsUpdatedByGritAdmin: 0,
      CreditsDeletedByGritAdmin: 0,
      CreditsAddedByCompanyUser: 0,
    },
  },
};

export const activeCreditList = {
  data: {
    data: {},
    pagination: {
      currentPage: 1,
      dataCount: 0,
      orderBy: 'ca',
      orderDir: 'desc',
      pageSize: 25,
      pagesCount: 0,
      total: 0,
    },
  },
};

export const app = {
  title: 'GRIT Search',
};

export const editAdmin = {
  data: {
    email: '',
    firstName: '',
    lastName: '',
    status: 1,
    role: 1,
  },
};

export const editAccount = {
  meta: {
    getUserInProgress: false,
    updateUserInProgress: false,
  },
  data: {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    profilePicEdit: '',
    jobTitle: '',
  },
};

export const pageOnLoading = {
  isInitialCheckDone: false,
  urlData: null,
  data: {
    candidateInterviewRequest: {
      isShow: false,
      isLoading: true,
      isApproveAction: false,
      isRejectAction: false,
      isRejectionSuccess: false,
      isAlreadySubmittedAction: false,
      isError: false,
      data: null,
    },
  },
};

export const tagList = {
  data: {
    data: {},
    pagination: {
      currentPage: 0,
      pageSize: 0,
      pagesCount: 0,
      dataCount: 0,
    },
    filters: {
      inactive: 0,
      active: 0,
    },
    success: true,
  },
};

export const integration = {
  companyIntegration: {
    activeIntegration: {},
    availableCompanyIntegration: {},
    isLoading: false,
  },
};
