import _ from 'lodash';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { generatePath } from 'react-router-dom';
import {
  OPEN_ADD_TO_ANOTHER_PROJECT,
  OPEN_HIDE_PROFILE_CONFIRMATION,
  START_PROFILE_OPTION_REQUEST,
  SUCCESS_PROFILE_OPTION_REQUEST,
} from '../redux/actionTypes';
import CandidateApi from '../api/CandidateApi';
import { showSuccessSnackbar } from '../redux/actions/snackbarActions';
import {
  CANDIDATE_STATUS_CONST,
  COMPANY_INTEGRATION_LIST,
  COMPANY_INTEGRATION_TYPE,
  COMPANY_STATUS_CONST,
  PROJECT_ITEM_STATE_CONST,
  PROJECT_TYPES,
} from '../common/constant';
import { UserTypes, UserTypesAllowedProjectFunnelTabs } from '../common/roles';
import CopyIcon from '../old/images/copy.svg';
import FavoriteIcon from '../old/images/favorite.svg';
import HideIcon from '../old/images/hide.svg';
import UnHideIcon from '../old/images/un-hide.svg';
import CloseIcon from '../old/images/close.svg';
import MoveIcon from '../old/images/move.svg';
import DownloadIcon from '../old/images/download.svg';
import Edit3Icon from '../old/images/edit-3.svg';
import { editTalent } from '../common/paths';
import notAllowedEmailDomainsArr from '../config/notAllowedEmailDomainsConfig';

export const recruitmentStages = [
  {
    id: 0,
    tag: 'open',
    sort: 1,
    prev: null,
    next: 1,
    reject: false,
    visibility: {
      [UserTypes.Admin]: ['*'],
    },
  },
  {
    id: 1,
    tag: 'qualify',
    sort: 2,
    prev: 0,
    next: 2,
    reject: true,
    visibility: {
      [UserTypes.Admin]: ['*'],
    },
  },
  {
    id: 2,
    tag: 'shortlist',
    sort: 3,
    prev: 1,
    next: 3,
    reject: true,
    visibility: {
      [UserTypes.Admin]: ['*'],
      [UserTypes.RaasCompany]: ['*'],
    },
  },
  {
    id: 3,
    tag: 'interviewing',
    sort: 4,
    prev: 2,
    next: 7,
    reject: true,
    visibility: {
      [UserTypes.Admin]: ['*'],
      [UserTypes.RaasCompany]: ['*'],
    },
  },
  {
    id: 7,
    tag: 'interviewing2',
    sort: 8,
    prev: 3,
    next: 4,
    reject: true,
    visibility: {
      [UserTypes.Admin]: ['*'],
      [UserTypes.RaasCompany]: ['interview_scheduled'],
    },
  },
  {
    id: 4,
    tag: 'offered',
    sort: 5,
    prev: 3,
    next: 5,
    reject: true,
    visibility: {
      [UserTypes.Admin]: ['*'],
      [UserTypes.RaasCompany]: ['interview_scheduled'],
    },
  },
  {
    id: 5,
    tag: 'placed',
    sort: 6,
    prev: 4,
    next: null,
    reject: false,
    visibility: {
      [UserTypes.Admin]: ['*'],
      [UserTypes.RaasCompany]: ['interview_scheduled'],
    },
  },
  {
    id: 6,
    tag: 'rejected',
    sort: 7,
    prev: null,
    next: null,
    reject: false,
    visibility: {
      [UserTypes.Admin]: ['*'],
      [UserTypes.RaasCompany]: ['*'],
    },
  },
];

// Check the given role have access to the specified recruitment stage to view.
export const checkRecruitmentTabAllowedForRole = (role, tabId) => {
  // Long list feature no longer available (GS-922)
  return (
    role > 0 &&
    (UserTypesAllowedProjectFunnelTabs[role].includes('*') ||
      UserTypesAllowedProjectFunnelTabs[role].includes(tabId))
  );
};

// Get matched rules for stages visibility
export const getMatchedRulesOfStagesVisibility = (candidate) => {
  const matchRules = ['*'];

  if (candidate) {
    const stagesArr = [
      PROJECT_ITEM_STATE_CONST.INTERVIEWING,
      PROJECT_ITEM_STATE_CONST.INTERVIEWING2,
      PROJECT_ITEM_STATE_CONST.OFFERED,
      PROJECT_ITEM_STATE_CONST.PLACED,
    ];

    if (
      stagesArr.includes(candidate.recruitmentStatus) ||
      (candidate.recruitmentStatus === PROJECT_ITEM_STATE_CONST.REJECTED &&
        stagesArr.includes(candidate.rejectedFrom))
    ) {
      matchRules.push('interview_scheduled');
    }
  }
  return matchRules;
};

export const getCurrentRecruitmentStage = (stage) => {
  return recruitmentStages.find((tab) => tab.id === stage);
};

// Check any rules matched with defined recruitment rules
export const isOneOfRulesMatched = (recruitmentStageRules, matchedRules) => {
  return recruitmentStageRules?.some((r) => matchedRules.indexOf(r) >= 0);
};

// Get candidate profile recruitment options for move to button
export const getCandidateProfileMoveToOptions = (
  currentrecruitmentStage,
  loggedUserRole,
  matchedRules
) => {
  return recruitmentStages.filter(
    (stage) =>
      stage.tag !== 'open' &&
      stage.id !== currentrecruitmentStage &&
      ((loggedUserRole === UserTypes.RaasCompany &&
        stage.id !== PROJECT_ITEM_STATE_CONST.SHORTLIST) ||
        loggedUserRole !== UserTypes.RaasCompany) &&
      isOneOfRulesMatched(stage.visibility[loggedUserRole], matchedRules)
  );
};

const pdfDownLoad = async (profileId) => {
  // Get profile PDF buffer stream
  const getPdfRes64String = await CandidateApi.downloadPDF(profileId).then(
    (response) => {
      return response.data.error !== undefined ? 'error' : response.data;
    }
  );

  if (
    getPdfRes64String !== '[object Object]' &&
    getPdfRes64String !== 'error'
  ) {
    // decode base64 string, remove space for IE compatibility
    const binary = atob(getPdfRes64String.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }
};

export const downloadCurrentCV = async (candidatesObject) => {
  const { resumePath, guid: id } = candidatesObject;
  // Get profile PDF buffer stream
  const cv = await CandidateApi.downloadCV(id).then((response) => {
    return response.data.error !== undefined ? 'error' : response.data;
  });
  // decode base64 string, remove space for IE compatibility
  const binary = atob(cv.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const mimeType = resumePath.split('.');
  let blob;
  if (mimeType[1] === 'pdf') {
    blob = new Blob([view], { type: 'application/pdf' });
  } else if (mimeType[1] === 'docx') {
    blob = new Blob([view], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
  } else if (mimeType[1] === 'doc') {
    blob = new Blob([view], { type: 'application/msword' });
  }
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
};

export const downloadFile = async (fileData, path, isBlob) => {
  // decode base64 string, remove space for IE compatibility
  const binary = atob(fileData.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  const mimeType = path ? path.split('.') : ['', 'pdf'];
  let blob;

  if (mimeType[1] === 'pdf') {
    blob = new Blob([view], { type: 'application/pdf' });
  } else if (mimeType[1] === 'docx') {
    blob = new Blob([view], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
  } else if (mimeType[1] === 'doc') {
    blob = new Blob([view], { type: 'application/msword' });
  }

  if (isBlob) {
    return blob;
  }

  const url = URL.createObjectURL(blob);
  return url;
};

export const candidateProfileOptions = {
  add_to_another_project: {
    id: 1,
    icon: CopyIcon,
    label: 'add_to_another_project',
    trigger(dispatch, payload) {
      dispatch({
        type: OPEN_ADD_TO_ANOTHER_PROJECT,
        payload,
      });
    },
  },
  add_to_favories: {
    id: 2,
    icon: FavoriteIcon,
    label: 'add_to_favories',
    async trigger(dispatch, payload, state) {
      const tab = state.filters.tab.value;
      const data = {
        project_id: state.meta.project,
        candidate_id: payload.profileId,
        favourite: 1,
      };

      dispatch({
        type: START_PROFILE_OPTION_REQUEST,
        payload: {
          ...data,
          profileId: payload.profileId,
          tab,
        },
      });

      await CandidateApi.toggleFavories(data);

      dispatch({
        type: SUCCESS_PROFILE_OPTION_REQUEST,
        payload: {
          ...data,
          tab,
          profileId: payload.profileId,
          variety: {
            key: 'isFavorited',
            value: true,
          },
        },
        toast: {
          msgNamespace: 'messages',
          msgKey: 'successMessage',
          show: showSuccessSnackbar,
        },
      });
    },
  },
  hide_profile: {
    id: 3,
    icon: HideIcon,
    label: 'hide_profile',
    trigger(dispatch, payload) {
      dispatch({
        type: OPEN_HIDE_PROFILE_CONFIRMATION,
        payload,
      });
    },
  },
  un_hide_profile: {
    id: 4,
    icon: UnHideIcon,
    label: 'unhide_profile',
    async trigger(dispatch, payload, state) {
      const tab = state.filters.tab.value;
      const data = {
        project_id: state.meta.project,
        candidate_id: payload.profileId,
        hide: 0,
      };

      dispatch({
        type: START_PROFILE_OPTION_REQUEST,
        payload: {
          ...data,
          profileId: payload.profileId,
          tab,
        },
      });

      await CandidateApi.toggleHide(data);

      dispatch({
        type: SUCCESS_PROFILE_OPTION_REQUEST,
        payload: {
          ...data,
          tab,
          profileId: payload.profileId,
          variety: {
            key: 'isHidden',
            value: false,
          },
        },
        toast: {
          msgNamespace: 'messages',
          msgKey: 'successMessage',
          show: showSuccessSnackbar,
        },
      });
    },
  },
  remove_from_favories: {
    id: 5,
    icon: FavoriteIcon,
    label: 'remove_from_favories',
    async trigger(dispatch, payload, state) {
      const tab = state.filters.tab.value;
      const data = {
        project_id: state.meta.project,
        candidate_id: payload.profileId,
        favourite: 0,
      };

      dispatch({
        type: START_PROFILE_OPTION_REQUEST,
        payload: {
          ...data,
          profileId: payload.profileId,
          tab,
        },
      });

      await CandidateApi.toggleFavories(data);

      dispatch({
        type: SUCCESS_PROFILE_OPTION_REQUEST,
        payload: {
          ...data,
          tab,
          profileId: payload.profileId,
          variety: {
            key: 'isFavorited',
            value: false,
          },
        },
        toast: {
          msgNamespace: 'messages',
          msgKey: 'successMessage',
          show: showSuccessSnackbar,
        },
      });
    },
  },
  skip: {
    id: 6,
    icon: MoveIcon,
    label: 'skip',
    subMenu: [],
    getSubMenu(currentrecruitmentStage, loggedUserRole, matchedRules) {
      const currentrecruitmentStageObj = getCurrentRecruitmentStage(
        currentrecruitmentStage
      );

      // Exclude open stage and reject stage from skip options
      // Exclude current stage from skip options
      // Exclude options if profile is in rejected stage
      // Exclude stages from skip options if logged user not have permission to see it or one of the rules not matched
      const subMenu = recruitmentStages.filter(
        (stage) =>
          stage.tag !== 'open' &&
          stage.tag !== 'rejected' &&
          currentrecruitmentStageObj.tag !== 'rejected' &&
          stage.id !== currentrecruitmentStage &&
          isOneOfRulesMatched(stage.visibility[loggedUserRole], matchedRules)
      );

      const formattedMenus = subMenu.map((opt) => {
        return {
          ...opt,
          label: `candidateStages.${opt.tag}`,
        };
      });

      this.subMenu = formattedMenus;

      return this;
    },
    prepareData(payload) {
      return [
        'option',
        payload.profile.guid,
        payload.profile.recruitmentStatus,
        payload.subOption.id,
      ];
    },
  },
  reject_profile: {
    id: 7,
    icon: CloseIcon,
    label: 'reject',
    prepareData(payload) {
      return [
        'option',
        payload.profile.guid,
        payload.profile.recruitmentStatus,
        6,
        { ...payload?.formData },
      ];
    },
  },
  download: {
    id: 8,
    icon: DownloadIcon,
    label: 'download_profile',
    trigger(dispatch, payload) {
      (async () => {
        await pdfDownLoad(payload.profile.guid);
      })();
    },
  },
  download_cv: {
    id: 9,
    icon: DownloadIcon,
    label: 'download_cv',
    trigger(dispatch, payload) {
      (async () => {
        await downloadCurrentCV(payload.profile);
      })();
    },
  },
  candidate_edit: {
    id: 10,
    icon: Edit3Icon,
    label: 'edit_candidate',
    trigger(dispatch, payload) {
      window.open(
        generatePath(editTalent, {
          id: payload.profile.guid,
        })
      );
    },
  },
};

export const isUndefined = (val) => {
  return typeof val === 'undefined';
};

export const getNextRecruitmentStage = (stage, useDefault) => {
  const currentStage = recruitmentStages.find((tab) => tab.id === stage);

  const nextStage = isUndefined(currentStage)
    ? currentStage
    : recruitmentStages.find((tab) => tab.id === currentStage.next);

  return isUndefined(nextStage) && useDefault
    ? recruitmentStages[1]
    : nextStage;
};

export const checkJsonIfExists = (value, jsonArray, key) => {
  return !!jsonArray.find((el) => el[key] === value);
};

export const getCandidateProfileOptions = (
  isSearchPage,
  loggedUserRole,
  matchedRules = [],
  actionMatchWithRoleAndStatus = false,
  profile = {}
) => {
  const options = [];

  if (isSearchPage && actionMatchWithRoleAndStatus) {
    options.push({ ...candidateProfileOptions.add_to_another_project });
  }

  if (
    loggedUserRole === UserTypes.Admin &&
    profile.recruitmentStatus > PROJECT_ITEM_STATE_CONST.SUGGESTED &&
    profile.status !== CANDIDATE_STATUS_CONST.SEMI_ACTIVE
  ) {
    options.push({
      ...candidateProfileOptions.skip.getSubMenu(
        profile.recruitmentStatus,
        loggedUserRole,
        matchedRules
      ),
    });
  }

  if (
    loggedUserRole === UserTypes.Admin &&
    profile.resumePath &&
    profile.resumePath !== 'null'
  ) {
    options.push({ ...candidateProfileOptions.download_cv });
  } else if (
    loggedUserRole === UserTypes.RaasCompany &&
    profile?.firstName &&
    profile.blindResumePath &&
    profile.blindResumePath !== 'null'
  ) {
    options.push({ ...candidateProfileOptions.download_cv });
  }

  if (isSearchPage) {
    return options;
  }

  if (profile.status !== CANDIDATE_STATUS_CONST.SEMI_ACTIVE) {
    if (profile.isFavorited) {
      options.push({ ...candidateProfileOptions.remove_from_favories });
    } else {
      options.push({ ...candidateProfileOptions.add_to_favories });
    }
  }

  if (
    profile.recruitmentStatus !== PROJECT_ITEM_STATE_CONST.SUGGESTED &&
    (profile.recruitmentStatus < PROJECT_ITEM_STATE_CONST.PLACED ||
      profile.recruitmentStatus === PROJECT_ITEM_STATE_CONST.INTERVIEWING2) &&
    profile.status !== CANDIDATE_STATUS_CONST.SEMI_ACTIVE
  ) {
    options.push({ ...candidateProfileOptions.reject_profile });
  }

  if (
    actionMatchWithRoleAndStatus &&
    loggedUserRole !== UserTypes.RaasCompany
  ) {
    options.push({ ...candidateProfileOptions.add_to_another_project });
  }

  if (loggedUserRole === UserTypes.Admin) {
    options.push({ ...candidateProfileOptions.candidate_edit });
  }

  return options;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm), 10)} ${sizes[i]}`;
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isEmailDomainAllowed = (email) => {
  const domain = email.toLocaleLowerCase().split('@').pop().split('.')[0];

  return !notAllowedEmailDomainsArr.includes(domain);
};

export const isValidPhone = (phone) => {
  return phone.match(
    /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,9}$/im
  );
};

export const isValidURL = (url) => {
  return url.match(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
  );
};

export const isValidLinkedIn = (url) => {
  return url.match(
    /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/
  );
};

export const isValidInstagram = (url) => {
  return url.match(
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/gim
  );
};

export const isValidTwitter = (url) => {
  return url.match(/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/);
};

export const isValidGithub = (url) => {
  return url.includes('github');
};

export const isValidStackOverflow = (url) => {
  return url.includes('stackoverflow');
};

export const isValidDribble = (url) => {
  return url.includes('dribbble');
};

export const selectType = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
};

export const getCandidateNameWithLatestJobTitleAndExperience = (
  experience,
  commonTrans
) => {
  let userName = commonTrans('anonymouse');
  let period = '';
  let industry = '';
  try {
    if (experience && !_.isEmpty(experience)) {
      let exp = experience;
      exp = exp.sort((a, b) => {
        // @ts-ignore
        return (
          // eslint-disable-next-line no-nested-ternary
          (a.currentPosition === b.currentPosition
            ? 0
            : a.currentPosition
            ? -1
            : 1) ||
          parseISO(b.startDate) - parseISO(a.startDate) ||
          a.title.localeCompare(b.title)
        );
      });

      userName = exp[0].title && exp[0].title !== '' ? exp[0].title : userName;
      period = `${moment(exp[0].startDate).format('YYYY')} - ${moment(
        exp[0].endDate
      ).format('YYYY')}`;
      industry = exp[0]?.industry?.label || '';
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error: ', e);
  }

  return { userName, period, industry };
};

export const getShowingResults = (
  selected,
  currentPage,
  pageSize,
  total = 0,
  pageResult = 0
) => {
  if (selected.length) {
    return `${selected.length} selected out of ${total}`;
  }
  const indexOfLast = currentPage * pageSize;
  const indexOfFirst = indexOfLast - pageSize;
  const from = indexOfFirst + 1;
  const to = indexOfFirst + pageResult;
  let fromTo = '';

  if (from === 1 && to === 0) {
    fromTo = '0';
  } else {
    fromTo = `${from} - ${to} `;
  }

  return `Showing ${fromTo} out of ${total}`;
};

export const getCandidateNoticePeriodLabel = (
  noticePeriodInMonths,
  noticePeriod,
  tp
) => {
  if (noticePeriodInMonths === 0) {
    if (noticePeriod && noticePeriod.type === 3) {
      return tp('noticePeriodOptions.unsure.label');
    }

    if (noticePeriod && noticePeriod.type === 2) {
      return tp('noticePeriodOptions.specificDate.label', {
        date: noticePeriod.option,
      });
    }
    return tp('noticePeriodOptions.immediately.label');
  }

  return tp('noticePeriodOptions.months.subLabel', {
    months: noticePeriodInMonths,
  });
};

export const stageActionMatchWithRoleAndStatus = (loggedUserRole, profile) => {
  return (
    (loggedUserRole === UserTypes.Admin &&
      profile.status === CANDIDATE_STATUS_CONST.ACTIVE) ||
    (loggedUserRole === UserTypes.Admin &&
      profile.status === CANDIDATE_STATUS_CONST.SEMI_ACTIVE) ||
    ((loggedUserRole === UserTypes.Company ||
      loggedUserRole === UserTypes.RaasCompany) &&
      profile.status === CANDIDATE_STATUS_CONST.ACTIVE)
  );
};

export const showEmploymentTypes = (arr, tc) => {
  let text = '';

  arr.forEach((object, index) => {
    text += tc(`rolePage.form.employmentType.option${object}.label`);
    if (!isUndefined(arr[index + 1])) {
      if (isUndefined(arr[index + 2])) {
        text += ' or ';
      } else {
        text += ', ';
      }
    }
  });
  text = text.slice(0, 1).toUpperCase() + text.slice(1).toLocaleLowerCase();

  return text;
};

export const getOnlyIds = (arr) => {
  const idArr = [];

  arr.forEach((item) => {
    idArr.push(item.id);
  });

  return idArr;
};

export const getCompanyStatus = (bulkStatus) => {
  if (Number(bulkStatus) === COMPANY_STATUS_CONST.PENDING_REQUEST) {
    return { name: 'Pending', value: 'pendingRequest' };
  }

  if (Number(bulkStatus) === COMPANY_STATUS_CONST.ACTIVE) {
    return { name: 'Active', value: 'active' };
  }

  return { name: 'Inactive', value: 'inactive' };
};

export const getUpdatedFiltersByCreation = (statues, status) => {
  const filtersObj = {};

  statues.forEach((el) => {
    filtersObj[el.filterKey] = el.filterValue;
  });

  filtersObj[status] += 1;

  return filtersObj;
};

export const getUpdatedFilters = (data, statues, bulkChangeStatus) => {
  const filtersObj = {};

  statues.forEach((el) => {
    filtersObj[el.filterKey] = el.filterValue;
  });

  data.forEach((item) => {
    statues.forEach((el) => {
      if (el.statusName === item.status) {
        filtersObj[el.filterKey] -= 1;
      }
    });
  });

  filtersObj[bulkChangeStatus] += data.length;

  return filtersObj;
};

export const createProperLinkedInLink = (url) => {
  let externalLink = url;

  if (url.slice(0, 3) === 'www') {
    externalLink = `https://${url}`;
  } else if (url.includes('http') && !url.includes('https')) {
    externalLink = url.replace('http', 'https');
  } else if (!url.includes('http') && !url.includes('https')) {
    externalLink = `https://${url}`;
  }

  return externalLink;
};

export const getAvatarName = (word) => {
  const wordArr = word ? word.split(' ') : [];
  let name = '';

  if (wordArr.length > 0 && wordArr[0]) {
    name = wordArr[0].slice(0, 1);
  }

  if (wordArr.length > 1 && wordArr[1]) {
    name += wordArr[1].slice(0, 1);
  }

  return name?.toUpperCase();
};

export const getAtsOptionBySort = () => {
  return _.sortBy(COMPANY_INTEGRATION_LIST, (ats) => {
    if (ats.id === COMPANY_INTEGRATION_TYPE.OTHER) {
      return 1;
    }
    return ats.label?.toLowerCase();
  });
};

export const getGUIDById = (id) => {
  const idArr = id.split(':');
  return idArr && idArr.length > 1 ? idArr[1] : null;
};

export const getNoOfColumns = (id) => {
  const table = document.querySelector(`#${id} tHead`);
  if (table) {
    return table?.rows[0]?.cells?.length || 0;
  }

  return 0;
};

export const commonValidationBeforeNextRecruitmentStage = (
  role,
  companyType,
  projectType,
  isGuaranteed,
  isAdminProject,
  nextStageId
) => {
  const resReturn = {
    openUpgradeProject: false,
    openGuaranteeCandidateProject: false,
    moveNext: false,
  };

  if (
    (UserTypes.RaasCompany === role || UserTypes.Admin === role) &&
    !isAdminProject &&
    UserTypes.RaasCompany === companyType &&
    PROJECT_TYPES.FREE === projectType &&
    (nextStageId === PROJECT_ITEM_STATE_CONST.INTERVIEWING ||
      nextStageId === PROJECT_ITEM_STATE_CONST.INTERVIEWING2 ||
      nextStageId === PROJECT_ITEM_STATE_CONST.OFFERED ||
      nextStageId === PROJECT_ITEM_STATE_CONST.PLACED)
  ) {
    resReturn.openUpgradeProject = true;
    return resReturn;
  }

  if (
    (UserTypes.RaasCompany === role || UserTypes.Admin === role) &&
    !isAdminProject &&
    UserTypes.RaasCompany === companyType &&
    !isGuaranteed &&
    PROJECT_TYPES.POWER === projectType &&
    (nextStageId === PROJECT_ITEM_STATE_CONST.OFFERED ||
      nextStageId === PROJECT_ITEM_STATE_CONST.PLACED)
  ) {
    resReturn.openGuaranteeCandidateProject = true;
    return resReturn;
  }

  resReturn.moveNext = true;
  return resReturn;
};
