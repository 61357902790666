import queryString from 'query-string';
import { LOCATION_CHANGE } from 'connected-react-router';
import { v4 as uuidv4 } from 'uuid';
import { indexOf } from 'lodash';
import {
  toUrlParam,
  isSearchPageActive,
  urlParseOptions,
  isViewProjectPageActive,
  formatUrl,
} from '../../helpers/urlHelper';
import {
  SEARCH_START,
  SEARCH_SUCCESS,
  OPEN_ADD_PROJECT_POPUP,
  CLOSE_ADD_PROJECT_POPUP,
  CLOSE_ADD_PROJECT_POPUP_SUCCESS,
  START_PROJECT_ADD,
  START_RECRUITMENT_STATUS_CHANGE,
  RECRUITMENT_STATUS_CHANGE_SUCCESS,
  OPEN_ADD_TO_ANOTHER_PROJECT,
  CLOSE_ADD_TO_ANOTHER_PROJECT,
  START_ADD_TO_ANOTHER_PROJECT,
  SUCCESS_ADD_TO_ANOTHER_PROJECT,
  OPEN_HIDE_PROFILE_CONFIRMATION,
  CLOSE_HIDE_PROFILE_CONFIRMATION,
  START_HIDE_PROFILE,
  SUCCESS_HIDE_PROFILE,
  START_PROFILE_OPTION_REQUEST,
  SUCCESS_PROFILE_OPTION_REQUEST,
  OPEN_JOB_DESCRIPTION_POPUP,
  CLOSE_JOB_DESCRIPTION_POPUP,
  START_SEND_JOB_DESCRIPTION,
  SUCCESS_SEND_JOB_DESCRIPTION,
  SUCCESS_FETCH_PROJECT,
  SUCCESS_PROJECT_ADD,
  SEARCH_BLOCKED,
  START_PROFILE_PROJECT_LIST_FETCH,
  SUCCESS_PROFILE_PROJECT_LIST_FETCH,
  CLOSE_PROFILE_PROJECT_LIST_FETCH,
  SLIDING_FILTER_CHANGE_MIN_MAX,
  REMOVE_CANDIDATE_PROFILE_FROM_SEARCH,
  BULK_SELECT_PROFILE,
  START_RECRUITMENT_STATUS_BULK_CHANGE,
  RECRUITMENT_STATUS_BULK_CHANGE_SUCCESS,
  START_SEND_INTERVIEW_REQUEST,
  SUCCESS_SEND_INTERVIEW_REQUEST,
  PROJECT_CANDIDATE_SEARCH_START,
  PROJECT_CANDIDATE_SEARCH_STOP,
  SHOW_SINGLE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  CLOSE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  SHOW_SKIP_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  SHOW_BULK_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  START_FETCH_PROJECT_SHARED_USER_INFO,
  SUCCESS_FETCH_PROJECT_SHARED_USER_INFO,
  START_FETCH_PROJECT_SHARED_USER_LIST,
  SUCCESS_FETCH_PROJECT_SHARED_USER_LIST,
  SET_PROJECT_TAB,
} from '../actionTypes';
import {
  CANDIDATE_STATUS_CONST,
  PROJECT_ITEM_STATE_CONST,
  URL_DISABLED_FILTER_FLAG,
} from '../../common/constant';
import initialState from './searchFilterInitialState';
import { recruitmentStages } from '../../helpers/utilityHelper';

window.queryString = queryString;
const setValues = (state, lastSync) => {
  const queryObj = queryString.parse(
    formatUrl(window.location.search),
    urlParseOptions
  );

  const newState = { ...state };

  if (lastSync) {
    const queryParam = toUrlParam(lastSync);
    const value = newState[lastSync].getUrlValue(queryObj, queryParam);
    const changes = {
      value,
    };

    const changed = {
      ...state,
      [lastSync]: {
        ...state[lastSync],
        default: false,
        ...changes,
      },
    };

    if (lastSync === 'tab') {
      changed.sorting.reset();
    }

    if (lastSync !== 'pagination') {
      changed.pagination.reset();
    }

    return {
      ...changed,
    };
  }

  const isSearchPage = isSearchPageActive();

  Object.keys(newState).forEach((param) => {
    const queryParam = toUrlParam(param);
    const value = newState[param].getUrlValue(queryObj, queryParam);

    if (value) {
      const currentValue = newState[param].value;

      if (
        Array.isArray(currentValue) &&
        JSON.stringify(currentValue) === JSON.stringify(value)
      ) {
        return;
      }

      newState[param] = {
        ...newState[param],
        enable:
          isSearchPage && value.length
            ? true
            : value !== URL_DISABLED_FILTER_FLAG,
        default: false,
      };

      newState[param].setValues(value);
      newState[param].loading = false;
    } else {
      newState[param].reset();

      newState[param] = {
        ...newState[param],
      };
    }
  });

  return { ...newState };
};

const searchFilterReducer = (state = initialState, action) => {
  const projectViewPage = isViewProjectPageActive();
  if (!isSearchPageActive() && projectViewPage === null) {
    return state;
  }

  switch (action.type) {
    case SEARCH_START:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload]: {
            ...state.data[action.payload],
            loading: true,
            requestBlocked: false,
          },
        },
      };
    case SET_PROJECT_TAB:
      return {
        ...state,
        filters: {
          ...state.filters,
          tab: {
            ...state.filters.tab,
            value: action.payload,
          },
        },
      };
    case PROJECT_CANDIDATE_SEARCH_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          projectCandidateSearchStart: true,
        },
      };
    case PROJECT_CANDIDATE_SEARCH_STOP:
      return {
        ...state,
        meta: {
          ...state.meta,
          projectCandidateSearchStart: false,
        },
      };
    case SEARCH_BLOCKED:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload]: {
            ...state.data[action.payload],
            requestBlocked: true,
            profiles: [],
            count: 0,
          },
        },
      };
    case LOCATION_CHANGE: {
      if (action.payload.location.pathname !== state.meta.path) {
        state.resetData();
      }

      return {
        ...state,
        filters: {
          ...setValues(state.filters, action.payload.location.lastSync),
        },
        meta: {
          ...state.meta,
          path: action.payload.location.pathname,
          project: projectViewPage === null ? null : projectViewPage,
        },
      };
    }
    case SEARCH_SUCCESS: {
      const suggested = action.payload.tab === 0;

      const profiles = action.payload.data.map((profile) => {
        let fullName = '';

        const isPublicCandidate =
          profile.visibility === true || profile.visibility === 'true';

        const adminAnonimas =
          !isPublicCandidate && (profile.firstName || profile.lastName);

        if (isPublicCandidate || adminAnonimas) {
          fullName = profile.firstName
            ? `${profile.firstName} ${profile.lastName}`
            : '';
        }

        const experience = Array.isArray(profile.experience)
          ? profile.experience.map((exp) => ({
              ...exp,
              guid: uuidv4(),
            }))
          : [];

        const education = Array.isArray(profile.education)
          ? profile.education.map((exp) => ({
              ...exp,
              guid: uuidv4(),
            }))
          : [];

        let lastUpdated = null;
        if (
          !suggested &&
          profile.lastRecruitmentUpdatedAt &&
          profile.lastRecruitmentUpdatedAt !== '' &&
          profile.lastRecruitmentUpdatedAt !== null &&
          typeof profile.lastRecruitmentUpdatedAt !== 'object'
        ) {
          lastUpdated = new Date(profile.lastRecruitmentUpdatedAt);
        }

        let rejectedFrom = null;
        if (
          !suggested &&
          (profile.rejectedFrom || profile.rejectedFrom === 0)
        ) {
          rejectedFrom = profile.rejectedFrom;
        }

        const visibilityIcon = profile.visibilityIcon === true;

        return {
          ...profile,
          experience,
          education,
          fullName,
          adminAnonimas,
          lastUpdated,
          rejectedFrom,
          visibility: isPublicCandidate,
          visibilityIcon,
          bulkSelected:
            state.meta.bulkSelect.profiles[action.payload.tab].profiles.indexOf(
              profile.guid
            ) >= 0,
        };
      });
      return {
        ...state,
        meta: {
          ...state.meta,
          lastRequest: {
            ...state.meta.lastRequest,
            params: action.payload.lastRequestParams,
          },
        },
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            profiles,
            loading: false,
            count:
              action.payload.count >= 0
                ? action.payload.count
                : state.data[action.payload.tab].count,
          },
        },
      };
    }
    case OPEN_ADD_PROJECT_POPUP:
      return {
        ...state,
        meta: {
          ...state.meta,
          onSaveAsProject: {
            active: true,
            origin: action.payload.origin,
            profile: action.payload.profile,
          },
        },
      };
    case CLOSE_ADD_PROJECT_POPUP:
      return {
        ...state,
        meta: {
          ...state.meta,
          onSaveAsProject: {
            ...state.meta.onSaveAsProject,
            active: false,
            profile: [],
          },
        },
      };
    case CLOSE_ADD_PROJECT_POPUP_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          onSaveAsProject: {
            ...state.meta.onSaveAsProject,
            active: false,
            origin: '',
            profile: [],
          },
        },
      };
    case START_PROJECT_ADD:
      return {
        ...state,
        meta: {
          ...state.meta,
          onSaveAsProject: {
            ...state.meta.onSaveAsProject,
            saving: true,
          },
        },
      };
    case SUCCESS_PROJECT_ADD:
      return {
        ...state,
        meta: {
          ...state.meta,
          onSaveAsProject: {
            active: false,
            origin: '',
            saving: false,
            profiles: [],
          },
        },
      };
    case START_RECRUITMENT_STATUS_CHANGE: {
      const candidates = state.data[action.payload.from].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          return {
            ...profile,
            profileOptionChangeInProgress: action.payload.origin === 'option',
            recruitmentChange: {
              origin: action.payload.origin,
              inProgress: true,
              from: action.payload.from,
              to: action.payload.to,
            },
          };
        }
      );

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.from]: {
            ...state.data[action.payload.from],
            profiles: candidates,
          },
        },
      };
    }

    case RECRUITMENT_STATUS_CHANGE_SUCCESS: {
      const candidates = state.data[action.payload.from].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          return {
            ...profile,
            inactiveShow: true,
            recruitmentStatus: action.payload.to,
            profileOptionChangeInProgress: false,
            recruitmentChange: {
              origin: null,
              inProgress: false,
              from: null,
              to: null,
            },
          };
        }
      );

      const bulkSelectedProfiles =
        state.meta.bulkSelect.profiles[action.payload.tab].profiles;
      const indexOfProfile = indexOf(
        bulkSelectedProfiles,
        action.payload.profileId
      );

      if (indexOfProfile >= 0) {
        bulkSelectedProfiles.splice(indexOfProfile, 1);
      }

      return {
        ...state,
        meta: {
          ...state.meta,
          bulkSelect: {
            ...state.meta.bulkSelect,
            profiles: {
              ...state.meta.bulkSelect.profiles,
              [action.payload.tab]: {
                ...state.meta.bulkSelect.profiles[action.payload.tab],
                profiles: bulkSelectedProfiles,
              },
            },
          },
        },
        data: {
          ...state.data,
          [action.payload.from]: {
            ...state.data[action.payload.from],
            count:
              action.payload.from === PROJECT_ITEM_STATE_CONST.SUGGESTED
                ? state.data[action.payload.from].count - 1
                : action.payload.projectRecruitmentStats[action.payload.from],
            profiles: candidates,
          },
          [action.payload.to]: {
            ...state.data[action.payload.to],
            count: action.payload.projectRecruitmentStats[action.payload.to],
          },
        },
      };
    }
    case OPEN_ADD_TO_ANOTHER_PROJECT:
      return {
        ...state,
        meta: {
          ...state.meta,
          addToAnotherProject: {
            ...state.meta.addToAnotherProject,
            active: true,
            profile: action.payload.profileId,
          },
        },
      };
    case CLOSE_ADD_TO_ANOTHER_PROJECT:
      return {
        ...state,
        meta: {
          ...state.meta,
          addToAnotherProject: {
            ...state.meta.addToAnotherProject,
            active: false,
            profile: null,
          },
        },
      };
    case START_ADD_TO_ANOTHER_PROJECT:
      return {
        ...state,
        meta: {
          ...state.meta,
          addToAnotherProject: {
            ...state.meta.addToAnotherProject,
            requestInProgress: true,
          },
        },
      };
    case SUCCESS_ADD_TO_ANOTHER_PROJECT: {
      let newState = {
        ...state,
        meta: {
          ...state.meta,
          addToAnotherProject: {
            ...state.meta.addToAnotherProject,
            active: false,
            requestInProgress: false,
            profile: null,
          },
        },
      };

      if (action.payload.bulk) {
        const suggestedState = recruitmentStages.find(
          (stage) => stage.tag === 'open'
        );

        const tab = suggestedState.id;
        const bulkUpdated = newState.meta.bulkSelect.profiles[tab].profiles;
        const candidates = newState.data[tab].profiles.map((profile) => {
          if (bulkUpdated.includes(profile.guid)) {
            return {
              ...profile,
              bulkSelected: false,
            };
          }

          return profile;
        });

        newState = {
          ...newState,
          meta: {
            ...newState.meta,
            bulkSelect: {
              ...newState.meta.bulkSelect,
              profiles: {
                ...newState.meta.bulkSelect.profiles,
                [suggestedState.id]: {
                  profiles: [],
                },
              },
            },
          },
          data: {
            ...newState.data,
            [tab]: {
              ...newState.data[tab],
              profiles: candidates,
            },
          },
        };
      }

      return newState;
    }
    case OPEN_HIDE_PROFILE_CONFIRMATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          hideProfile: {
            ...state.meta.hideProfile,
            active: true,
            profile: action.payload.profileId,
          },
        },
      };
    case CLOSE_HIDE_PROFILE_CONFIRMATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          hideProfile: {
            ...state.meta.hideProfile,
            active: false,
            profile: null,
          },
        },
      };
    case START_HIDE_PROFILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          hideProfile: {
            ...state.meta.hideProfile,
            requestInProgress: true,
          },
        },
      };
    case SUCCESS_HIDE_PROFILE: {
      const candidates = state.data[action.payload.tab].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          const newProfile = {
            ...profile,
            profileOptionChangeInProgress: false,
          };

          if (action.payload.variety) {
            newProfile[action.payload.variety.key] =
              action.payload.variety.value;
          }

          return newProfile;
        }
      );

      return {
        ...state,
        meta: {
          ...state.meta,
          hideProfile: {
            ...state.meta.hideProfile,
            active: false,
            requestInProgress: false,
            profile: null,
          },
        },
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            profiles: candidates,
          },
        },
      };
    }
    case START_PROFILE_OPTION_REQUEST: {
      const candidates = state.data[action.payload.tab].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          return {
            ...profile,
            profileOptionChangeInProgress: true,
          };
        }
      );

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            profiles: candidates,
          },
        },
      };
    }
    case SUCCESS_PROFILE_OPTION_REQUEST: {
      const candidates = state.data[action.payload.tab].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          const newProfile = {
            ...profile,
            profileOptionChangeInProgress: false,
          };

          if (action.payload.variety) {
            newProfile[action.payload.variety.key] =
              action.payload.variety.value;
          }

          return newProfile;
        }
      );

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            profiles: candidates,
          },
        },
      };
    }
    case OPEN_JOB_DESCRIPTION_POPUP:
      return {
        ...state,
        meta: {
          ...state.meta,
          jobDescription: {
            active: true,
            profile: action.payload.profileId,
            requestAction: action.payload.requestAction,
          },
        },
      };
    case CLOSE_JOB_DESCRIPTION_POPUP:
      return {
        ...state,
        meta: {
          ...state.meta,
          jobDescription: {
            active: false,
            profile: null,
            requestAction: null,
          },
        },
      };
    case START_SEND_INTERVIEW_REQUEST: {
      const candidates = state.data[action.payload.tab].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          const newProfile = {
            ...profile,
            projectInterviewRequested: true,
            interview: {
              requestInProgress: true,
            },
          };

          return newProfile;
        }
      );

      return {
        ...state,
        meta: {
          ...state.meta,
          jobDescription: {
            ...state.meta.jobDescription,
            requestInProgress: true,
          },
        },
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            profiles: candidates,
          },
        },
      };
    }
    case SUCCESS_SEND_INTERVIEW_REQUEST: {
      const candidates = state.data[action.payload.tab].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          const newProfile = {
            ...profile,
            projectInterviewRequested: true,
            interview: {
              requestInProgress: false,
            },
          };

          return newProfile;
        }
      );

      return {
        ...state,
        meta: {
          ...state.meta,
          jobDescription: {
            active: false,
            requestInProgress: false,
            profile: null,
            requestAction: null,
          },
        },
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            profiles: candidates,
          },
        },
      };
    }
    case START_SEND_JOB_DESCRIPTION: {
      const candidates = state.data[action.payload.tab].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          const newProfile = {
            ...profile,
            projectWhiteListRequested: true,
            whitelist: {
              requestInProgress: true,
            },
          };

          if (action.payload.variety) {
            newProfile[action.payload.variety.key] =
              action.payload.variety.value;
          }

          return newProfile;
        }
      );

      return {
        ...state,
        meta: {
          ...state.meta,
          jobDescription: {
            ...state.meta.jobDescription,
            requestInProgress: true,
          },
        },
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            profiles: candidates,
          },
        },
      };
    }
    case SUCCESS_SEND_JOB_DESCRIPTION: {
      const candidates = state.data[action.payload.tab].profiles.map(
        (profile) => {
          if (profile.guid !== action.payload.profileId) {
            return profile;
          }

          const newProfile = {
            ...profile,
            projectWhiteListRequested: true,
            whitelist: {
              requestInProgress: false,
            },
          };

          if (action.payload.variety) {
            newProfile[action.payload.variety.key] =
              action.payload.variety.value;
          }

          return newProfile;
        }
      );

      return {
        ...state,
        meta: {
          ...state.meta,
          jobDescription: {
            active: false,
            requestInProgress: false,
            profile: null,
            requestAction: null,
          },
        },
        data: {
          ...state.data,
          [action.payload.tab]: {
            ...state.data[action.payload.tab],
            profiles: candidates,
          },
        },
      };
    }
    case SUCCESS_FETCH_PROJECT: {
      const newData = Object.keys(state.data).map((stageId) => {
        const count =
          state.data[stageId].tag !== 'open'
            ? action.payload.project.stats[state.data[stageId].tag]
            : state.data[stageId].count;

        return {
          ...state.data[stageId],
          count,
        };
      });

      return {
        ...state,
        data: newData,
        meta: {
          ...state.meta,
        },
      };
    }
    case START_PROFILE_PROJECT_LIST_FETCH:
      return {
        ...state,
        meta: {
          ...state.meta,
          openProjectsList: {
            active: true,
            requestInProgress: true,
            profile: action.payload.profile,
            data: [],
          },
        },
      };
    case SUCCESS_PROFILE_PROJECT_LIST_FETCH:
      return {
        ...state,
        meta: {
          ...state.meta,
          openProjectsList: {
            ...state.meta.openProjectsList,
            requestInProgress: false,
            data: action.payload.data,
          },
        },
      };
    case CLOSE_PROFILE_PROJECT_LIST_FETCH:
      return {
        ...state,
        meta: {
          ...state.meta,
          openProjectsList: {
            ...state.meta.openProjectsList,
            active: false,
            requestInProgress: false,
            profile: null,
          },
        },
      };
    case SLIDING_FILTER_CHANGE_MIN_MAX: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.field]: {
            ...state.filters[action.payload.field],
            min: action.payload.limits[0],
            max: action.payload.limits[1],
          },
        },
      };
    }
    case REMOVE_CANDIDATE_PROFILE_FROM_SEARCH: {
      const tab = state.filters.tab.value;

      const candidates = state.data[tab].profiles.filter((profile) => {
        return profile.guid !== action.payload.guid;
      });

      return {
        ...state,
        data: {
          ...state.data,
          [tab]: {
            ...state.data[tab],
            profiles: candidates,
          },
        },
      };
    }
    case BULK_SELECT_PROFILE: {
      const tab = state.filters.tab.value;
      const selectedProfiles = [
        ...state.meta.bulkSelect.profiles[tab].profiles,
      ];
      const indexOfProfile = indexOf(selectedProfiles, action.payload.guid);

      if (indexOfProfile >= 0) {
        selectedProfiles.splice(indexOfProfile, 1);
      } else {
        selectedProfiles.push(action.payload.guid);
      }

      const candidates = state.data[tab].profiles.map((profile) => {
        if (profile.guid !== action.payload.guid) {
          return profile;
        }

        return {
          ...profile,
          bulkSelected: indexOfProfile === -1,
        };
      });

      const selectedSemiActiveProfiles = [
        ...state.meta.bulkSelect.semiActiveProfiles[tab].profiles,
      ];

      if (action.payload.status === CANDIDATE_STATUS_CONST.SEMI_ACTIVE) {
        const indexOfSemiActiveProfile = indexOf(
          selectedSemiActiveProfiles,
          action.payload.guid
        );

        if (indexOfSemiActiveProfile >= 0) {
          selectedSemiActiveProfiles.splice(indexOfProfile, 1);
        } else {
          selectedSemiActiveProfiles.push(action.payload.guid);
        }
      }

      return {
        ...state,
        meta: {
          ...state.meta,
          bulkSelect: {
            ...state.meta.bulkSelect,
            profiles: {
              ...state.meta.bulkSelect.profiles,
              [tab]: {
                ...state.meta.bulkSelect.profiles[tab],
                profiles: selectedProfiles,
              },
            },
            semiActiveProfiles: {
              ...state.meta.bulkSelect.semiActiveProfiles,
              [tab]: {
                ...state.meta.bulkSelect.semiActiveProfiles[tab],
                profiles: selectedSemiActiveProfiles,
              },
            },
          },
        },
        data: {
          ...state.data,
          [tab]: {
            ...state.data[tab],
            profiles: candidates,
          },
        },
      };
    }
    case START_RECRUITMENT_STATUS_BULK_CHANGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          bulkSelect: {
            ...state.meta.bulkSelect,
            requestInProgress: true,
            requestInProgressOrigin: action.payload,
          },
        },
      };
    case RECRUITMENT_STATUS_BULK_CHANGE_SUCCESS: {
      const bulkUpdated =
        state.meta.bulkSelect.profiles[action.payload.from].profiles;
      const updatedCount = bulkUpdated.length;

      const candidates = state.data[action.payload.from].profiles.map(
        (profile) => {
          if (bulkUpdated.includes(profile.guid)) {
            return {
              ...profile,
              bulkSelected: false,
              inactiveShow: true,
            };
          }

          return profile;
        }
      );

      return {
        ...state,
        meta: {
          ...state.meta,
          bulkSelect: {
            ...state.meta.bulkSelect,
            requestInProgress: false,
            requestInProgressOrigin: null,
            profiles: {
              ...state.meta.bulkSelect.profiles,
              [action.payload.from]: {
                ...state.meta.bulkSelect.profiles[action.payload.from],
                profiles: [],
              },
            },
          },
        },
        data: {
          ...state.data,
          [action.payload.from]: {
            ...state.data[action.payload.from],
            count:
              action.payload.from === PROJECT_ITEM_STATE_CONST.SUGGESTED
                ? state.data[action.payload.from].count - updatedCount
                : action.payload.projectRecruitmentStats[action.payload.from],
            profiles: candidates,
          },
          [action.payload.to]: {
            ...state.data[action.payload.to],
            count: action.payload.projectRecruitmentStats[action.payload.to],
          },
        },
      };
    }
    case SHOW_SINGLE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM:
      return {
        ...state,
        meta: {
          ...state.meta,
          candidateAnonymousRevealConfirm: {
            ...state.meta.candidateAnonymousRevealConfirm,
            isOpen: true,
            isSingleCandidate: true,
            data: action.payload,
          },
        },
      };
    case SHOW_SKIP_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM:
      return {
        ...state,
        meta: {
          ...state.meta,
          candidateAnonymousRevealConfirm: {
            ...state.meta.candidateAnonymousRevealConfirm,
            isOpen: true,
            isSkipCandidate: true,
            data: action.payload,
          },
        },
      };
    case SHOW_BULK_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM:
      return {
        ...state,
        meta: {
          ...state.meta,
          candidateAnonymousRevealConfirm: {
            ...state.meta.candidateAnonymousRevealConfirm,
            isOpen: true,
            isBulkCandidate: true,
            data: action.payload,
          },
        },
      };
    case CLOSE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM:
      return {
        ...state,
        meta: {
          ...state.meta,
          candidateAnonymousRevealConfirm: {
            ...initialState.meta.candidateAnonymousRevealConfirm,
          },
        },
      };
    case START_FETCH_PROJECT_SHARED_USER_INFO:
      return {
        ...state,
        meta: {
          ...state.meta,
          projectSharedInfo: {},
        },
      };
    case SUCCESS_FETCH_PROJECT_SHARED_USER_INFO:
      return {
        ...state,
        meta: {
          ...state.meta,
          projectSharedInfo: action.payload,
        },
      };
    case START_FETCH_PROJECT_SHARED_USER_LIST:
      return {
        ...state,
        meta: {
          ...state.meta,
          projectSharedUserList: [],
        },
      };
    case SUCCESS_FETCH_PROJECT_SHARED_USER_LIST:
      return {
        ...state,
        meta: {
          ...state.meta,
          projectSharedUserList: action.payload,
        },
      };
    default:
      return state;
  }
};

export default searchFilterReducer;
