import axios from 'axios';
import { statics } from '../providers/apiFeedProvider';

class StaticApi {
  static getJobTitles(title = '') {
    return axios({
      url: `${statics.getJobTitles.url}?title=${title}`,
      method: statics.getJobTitles.method,
    });
  }

  static getLocations(title = '', params = {}) {
    return axios({
      url: `${statics.getLocations.url}?title=${title}`,
      method: statics.getLocations.method,
      params,
    });
  }

  static getLocationsIpInfo() {
    return axios({
      url: `${statics.getLocationsIpInfo.url}`,
      method: statics.getLocationsIpInfo.method,
    });
  }

  static getLocationById(params) {
    return axios({
      url: `${statics.getLocationById.url}`,
      method: statics.getLocationById.method,
      params,
    });
  }

  static getLocationByTitle(params) {
    return axios({
      url: `${statics.getLocationByTitle.url}`,
      method: statics.getLocationByTitle.method,
      params,
    });
  }

  static getLocationByCode(params) {
    return axios({
      url: `${statics.getLocationByCode.url}`,
      method: statics.getLocationByCode.method,
      params,
    });
  }

  static getLanguages(params) {
    return axios({
      url: `${statics.getLanguages.url}`,
      method: statics.getLanguages.method,
      params,
    });
  }

  static getLanguagesByCode(params) {
    return axios({
      url: `${statics.getLanguagesByCode.url}`,
      method: statics.getLanguagesByCode.method,
      params,
    });
  }

  static getSkills(params) {
    return axios({ ...statics.getSkills, params });
  }

  static getIndustries(params) {
    return axios({ ...statics.getIndustries, params });
  }

  static getLocationsData(params) {
    return axios({ ...statics.getLocationsData, params });
  }
}

export default StaticApi;
