import { Button, CircularProgress } from '@mui/material';
import React, { memo } from 'react';

const BtnComp = ({
  id = '',
  type,
  hrefLink,
  loading = false,
  disabled,
  label,
  handleBtnClick,
  startIcon,
  endIcon,
  btnStyle,
  className = '',
}) => {
  return (
    <Button
      id={id}
      type={type || 'button'}
      href={hrefLink}
      className={`common-btn ${className} ${
        disabled || loading ? 'btn-disabled' : ''
      }`}
      startIcon={loading ? null : startIcon}
      endIcon={loading ? null : endIcon}
      onClick={handleBtnClick}
      disabled={disabled || loading}
      disableRipple
      style={btnStyle || {}}
    >
      {loading ? <CircularProgress size={20} color='inherit' /> : label}
    </Button>
  );
};

export default memo(BtnComp);
