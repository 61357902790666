import Cookies from 'universal-cookie';

export const getCookieByName = (name) => {
  const cookies = new Cookies();
  return cookies.get(name);
};

export const deleteCookieByName = (name) => {
  const cookies = new Cookies();
  return cookies.remove(name);
};
