/* eslint-disable import/prefer-default-export */
import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { isObject } from 'lodash';

const RenderAutoCompleteItem = memo(
  ({ properties, opt, selectAllTxt, enableSelectAll }) => {
    const isObj = isObject(opt);

    if (enableSelectAll) {
      if (opt === selectAllTxt || (isObj && opt.label === selectAllTxt)) {
        return (
          <Typography variant='body2' color='primary' {...properties}>
            {selectAllTxt}
          </Typography>
        );
      }
    }
    return <span {...properties}>{!isObj ? opt : opt.label}</span>;
  }
);

export { RenderAutoCompleteItem };
