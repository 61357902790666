/* eslint-disable */
import {
  getFromLocalStorage,
  setToLocalStorage,
  removeLocalStorage,
} from '../helpers/storageHelper';

const AUTHORIZATION_DATA_LOCAL_STORAGE_KEY = 'AUTHORIZATION_DATA';

class AuthorizationService {
  static authenticate(authorizationData) {
    setToLocalStorage(
      AUTHORIZATION_DATA_LOCAL_STORAGE_KEY,
      JSON.stringify(authorizationData)
    );
  }

  static isAuthenticated() {
    const authorizationData = getFromLocalStorage(
      AUTHORIZATION_DATA_LOCAL_STORAGE_KEY
    );

    return authorizationData && authorizationData.length > 0;
  }

  static logOff() {
    removeLocalStorage(AUTHORIZATION_DATA_LOCAL_STORAGE_KEY);
  }

  static getAuthorizationData() {
    const data = getFromLocalStorage(AUTHORIZATION_DATA_LOCAL_STORAGE_KEY);
    if (data && data.length > 0) {
      return JSON.parse(data);
    }

    return null;
  }

  static isAuthorized() {
    try {
      const data = AuthorizationService.getAuthorizationData();

      if (data?.role !== undefined && data?.authorizedModules !== undefined) {
        return {
          role: data.role,
          authorizedModules: data.authorizedModules,
          flowStep: data.flowStep,
        };
      } else {
        return {
          role: '',
          authorizedModules: {},
          flowStep: '',
        };
      }
    } catch (e) {
      return {
        role: '',
        authorizedModules: {},
        flowStep: '',
      };
    }
  }
}

export default AuthorizationService;
