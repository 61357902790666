import axios from 'axios';
import { company } from '../providers/apiFeedProvider';

class CompanyApi {
  static getCompaniesList(params) {
    return axios({
      ...company.filter,
      params,
    });
  }

  static getCompany(params) {
    return axios({
      ...company.get,
      params,
    });
  }

  static createCompany(params) {
    return axios({
      ...company.create,
      data: params,
    });
  }

  static updateCompany(params) {
    return axios({
      ...company.update,
      data: params,
    });
  }

  static deleteCompanies(params) {
    return axios({
      ...company.delete,
      data: {
        keys: params,
      },
    });
  }

  static updateCompaniesStatus(data, status) {
    return axios({
      ...company.bulkStatusUpdate,
      data: {
        keys: data,
        status,
      },
    });
  }
}

export default CompanyApi;
