import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/logos/grit-logo.svg';
import IconComp from '../icons/IconComp';
import FigChevronDown from '../../../assets/icons/figma-pro-2-8/arrows/FigChevronDown/FigChevronDown';
import FigXClose from '../../../assets/icons/figma-pro-2-8/general/FigXClose/FigXClose';

const NavbarMobile = ({
  handleHamburgerMenu,
  menuArr,
  history,
  navigateToUrl,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [tNav] = useTranslation('navbar');

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className='header'>
        <Link to='/' className='nav-bar-logo'>
          <img src={Logo} alt={tNav('title')} />
        </Link>
        <IconComp
          icon={<FigXClose onClick={handleHamburgerMenu(false)} />}
          classes='nav-bar-icon'
          className='close-btn'
        />
      </div>

      {menuArr.map((menu) =>
        menu?.menuList
          ? menu.allow && (
              <Accordion
                expanded={expanded === menu.title.replaceAll(' ', '-')}
                onChange={handleChange(menu.title.replaceAll(' ', '-'))}
                className='accordion-item'
                key={menu.title}
              >
                <AccordionSummary
                  expandIcon={
                    <IconComp
                      icon={<FigChevronDown />}
                      classes='nav-bar-icon'
                    />
                  }
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  {menu.title}
                </AccordionSummary>
                <AccordionDetails>
                  {menu.menuList.map(
                    (subMenu) =>
                      !subMenu?.hasDivider &&
                      subMenu.allow && (
                        <span
                          onClick={() => {
                            navigateToUrl(
                              subMenu,
                              subMenu.actionRedirectBlockPath
                            );
                          }}
                          key={subMenu.name}
                          className='nav-links'
                          role='presentation'
                        >
                          {subMenu.icon} {subMenu.name}
                        </span>
                      )
                  )}
                </AccordionDetails>
              </Accordion>
            )
          : menu.allow && (
              <span
                onClick={
                  menu.path ? () => history.push(menu.path) : menu.clickFunc
                }
                key={menu.title}
                className='nav-links'
                role='presentation'
              >
                {menu.title}
              </span>
            )
      )}
    </>
  );
};

export default NavbarMobile;
