import _ from 'lodash';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';

// Add new language whitelist key here and add a folder with same key in locale
const whitelistLang = ['en'];
const allLang = {};

// Preload all languages to resource instead of using backend with xhr to avoid the cache issue.
// So moved locale folder from public to inside the src
whitelistLang.forEach((lang) => {
  allLang[lang] = {};

  // eslint-disable-next-line global-require,import/no-dynamic-require
  const files = require(`../lang/${lang}`);
  if (files.default && _.size(files.default) > 0) {
    _.forEach(files.default, async (val) => {
      const eachFileName = val.replace('./', '');

      // eslint-disable-next-line global-require,import/no-dynamic-require
      const eachFileContent = require(`../lang/${lang}/${eachFileName}`);
      allLang[lang][eachFileName.replace('.json', '')] = {
        ...eachFileContent,
      };
    });
  }
});

i18n
  .use(LanguageDetector)
  .use(Backend)
  .init({
    whitelist: whitelistLang,
    nonExplicitWhitelist: true,
    load: 'languageOnly',
    detection: {
      lookupQuerystring: 'lang',
    },
    // Preload all languages to resource instead of using backend with xhr to avoid the cache issue.
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   loadPath: '/locales/{{lng}}/{{ns}}.json',
    // },
    resources: { ...allLang },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    nsSeparator: '.',
    keySeparator: '.',

    interpolation: {
      formatSeparator: ',',
    },

    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
