import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthorizationService from '../authorization/AuthorizationService';
import { UserTypes } from '../common/roles';
import { setPageTitle } from '../redux/actions/appActions';
import { allCompanies, allProjects, talentProfile } from '../common/paths';
import LayoutWithNavbar from '../components/layouts/LayoutWithNavbar';
import LayoutWithoutNavbarForSignUps from '../components/layouts/LayoutWithoutNavbarForSignUps';

const userAuthenticated = () => {
  return AuthorizationService.isAuthenticated();
};

const userAuthorized = () => {
  return AuthorizationService.isAuthorized();
};

const PublicRoutes = ({
  component: Component,
  restricted,
  changePageTitle,
  pageTitle,
  pageTitleDisable,
  showSignUpLayout,
  ...rest
}) => {
  // restricted = false meaning public route
  // restricted = true meaning restricted public route
  const [tCommon] = useTranslation('common');
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated = userAuthenticated();
        const { role } = userAuthorized();

        if (isAuthenticated && restricted) {
          if (role === UserTypes.Admin) return <Redirect to={allCompanies} />;
          if (role === UserTypes.Company) return <Redirect to={allProjects} />;
          if (role === UserTypes.Candidate)
            return <Redirect to={talentProfile} />;
        }

        const title = pageTitle
          ? `${pageTitle} | ${tCommon('pageTitles.gritSearch')}`
          : tCommon('pageTitles.gritSearch');
        if (!pageTitleDisable) changePageTitle(title);

        return showSignUpLayout ? (
          <LayoutWithoutNavbarForSignUps>
            <Component {...props} />
          </LayoutWithoutNavbarForSignUps>
        ) : (
          <LayoutWithNavbar>
            <Component {...props} />
          </LayoutWithNavbar>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  changePageTitle: (payload) => dispatch(setPageTitle(payload)),
});

export default connect(null, mapDispatchToProps)(PublicRoutes);
