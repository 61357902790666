import { hot } from 'react-hot-loader/root';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { showSuccessSnackbar } from '../redux/actions/snackbarActions';
import routesProvider from '../providers/routesProvider';
import PublicRoutes from '../routes/PublicRoutes';
import PrivateRoutes from '../routes/PrivateRoutes';
import {
  HTTP_STATUS_FORBIDDEN,
  HTTP_STATUS_UNAUTHORIZED,
} from '../common/constant';
import * as authorizationActions from '../redux/actions/authorizationActions';
import PageLoadItem from '../pages/pageLoad/PageLoadItem';
import '../sass/combine.scss';
import CrispHandler from '../components/shared/crisp/CrispHandler';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7F56D9',
    },
    action: {
      disabled: '#667085',
      disabledBackground: '#cccccc',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const App = ({
  dispatch,
  title,
  isInitialAuthCheckDone,
  isPageOnLoadingCheckDone,
}) => {
  const [tCommon] = useTranslation('common');
  const [tMessages] = useTranslation('messages');
  const [tTalent] = useTranslation('talent');
  const history = useHistory();

  const handleLogout = async () => {
    const redirectUrl = '/auth/login';
    try {
      await dispatch(authorizationActions.logoff(history, redirectUrl));
    } catch (err) {
      history.push('/auth/login');
    }
  };

  useMemo(() => {
    axios.interceptors.request.use(
      (config) => {
        if (config.hideLoading) {
          dispatch(hideLoading());
        } else {
          dispatch(showLoading());
        }
        return config;
      },
      (error) => {
        dispatch(hideLoading());
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        dispatch(hideLoading());
        return response;
      },
      (error) => {
        dispatch(hideLoading());
        if (
          error.response &&
          error.response.status === HTTP_STATUS_UNAUTHORIZED &&
          error.response.data &&
          (error.response.data.message === 'user.notActivate' ||
            error.response.data.message === 'user.notFound')
        ) {
          (async () => {
            await handleLogout();
          })();
        }

        if (
          error.response &&
          error.response.status === HTTP_STATUS_FORBIDDEN &&
          error.response.data
        ) {
          if (
            ['project.not.found', 'project.notFound'].includes(
              error.response.data.message
            )
          ) {
            history.push('/projects');
            dispatch(
              showSuccessSnackbar(tMessages('projectNotFound'), 'error')
            );
          } else if (error.response.data.message === 'downloadForbidden') {
            dispatch(
              showSuccessSnackbar(
                tTalent('errorMessages.downloadForbidden'),
                'error'
              )
            );
          }
        }

        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      {isInitialAuthCheckDone && isPageOnLoadingCheckDone && (
        <>
          <CrispHandler />
          <Helmet>
            <title>{`${title || tCommon('pageTitles.gritSearch')}`}</title>
          </Helmet>
          <Switch>
            {routesProvider.map((route, i) => {
              if (route.private) {
                return (
                  <PrivateRoutes
                    key={route.path}
                    keyAttr={i}
                    exact
                    allow={route.allow}
                    pageTitle={tCommon(route.pageTitle)}
                    pageTitleDisable={route.pageTitleDisable || false}
                    restricted={route.restricted}
                    path={route.path}
                    hasCustomContainer={route?.hasCustomContainer}
                    component={route.component}
                  />
                );
              }

              return (
                <PublicRoutes
                  key={route.path}
                  exact
                  restricted={route.restricted}
                  pageTitle={tCommon(route.pageTitle)}
                  pageTitleDisable={route.pageTitleDisable || false}
                  path={route.path}
                  showSignUpLayout={route?.showSignUpLayout}
                  component={route.component}
                />
              );
            })}
            <Redirect to='404' />
          </Switch>
          <PageLoadItem />
        </>
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  const { app } = state;
  const currentUser = state.authorizationReducer;
  const { pageOnLoading } = state;

  return {
    title: app.title,
    isInitialAuthCheckDone: currentUser.isInitialAuthCheckDone,
    isPageOnLoadingCheckDone: pageOnLoading.isInitialCheckDone,
  };
};

export default hot(connect(mapStateToProps, null)(App));
