import axios from 'axios';
import { creditLog } from '../providers/apiFeedProvider';

class CreditApi {
  static getCreditLogList(params) {
    return axios({
      ...creditLog.getCreditLogList,
      params,
    });
  }

  static getActiveCreditList(params) {
    return axios({
      ...creditLog.getActiveCreditList,
      params,
    });
  }

  static addCredit(data) {
    return axios({
      ...creditLog.addCredit,
      data,
    });
  }

  static updateCredit(creditId, data) {
    return axios({
      ...creditLog.updateCredit(creditId),
      data,
    });
  }

  static deleteCredit(creditId, creditDataId) {
    return axios({
      ...creditLog.deleteCredit(creditId, creditDataId),
    });
  }

  static generateOrderForm(data) {
    return axios({
      ...creditLog.generateOrderForm,
      data,
    });
  }

  static instantPurchaseCredit(data) {
    return axios({
      ...creditLog.instantPurchaseCredit,
      data,
    });
  }
}

export default CreditApi;
