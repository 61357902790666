import queryString from 'query-string';
import { LOCATION_CHANGE } from 'connected-react-router';
import { pull, uniq } from 'lodash';
import initialState from './adminCandidateListInitialState';
import {
  urlParseOptions,
  toUrlParam,
  isCandidateLisAdminPageActive,
} from '../../helpers/urlHelper';
import {
  ADMIN_CANDIDATE_LIST_SEARCH_SUCCESS,
  ADMIN_CANDIDATE_LIST_SEARCH_STARTS,
  ADMIN_CANDIDATE_TOGGLE_SELECT,
  ADMIN_CANDIDATE_TOGGLE_SELECT_ALL,
  TOGGLE_CANDIDATE_STATE_CHANGE_CONFIRMATION,
  CANDIDATE_STATE_CHANGE_START,
  CANDIDATE_STATE_CHANGE_SUCCESS,
  ADMIN_CANDIDATE_LIST_STAT_FETCH_SUCCESS,
} from '../actionTypes';

const setValues = (state, lastSync) => {
  const queryObj = queryString.parse(window.location.search, urlParseOptions);

  const newState = { ...state };

  if (lastSync) {
    const queryParam = toUrlParam(lastSync);
    const value = newState[lastSync].getUrlValue(queryObj, queryParam);
    const changes = {
      value,
    };

    const changed = {
      ...state,
      [lastSync]: {
        ...state[lastSync],
        default: false,
        ...changes,
      },
    };

    if (lastSync !== 'pagination') {
      changed.pagination.reset();
    }

    return {
      ...changed,
    };
  }

  Object.keys(newState).forEach((param) => {
    const queryParam = toUrlParam(param);
    const value = newState[param].getUrlValue(queryObj, queryParam);

    if (value) {
      const currentValue = newState[param].value;

      if (
        Array.isArray(currentValue) &&
        JSON.stringify(currentValue) === JSON.stringify(value)
      ) {
        return;
      }

      newState[param] = {
        ...newState[param],
        default: false,
      };

      newState[param].setValues(value);
      newState[param].loading = false;
    } else {
      newState[param].reset();

      newState[param] = {
        ...newState[param],
      };
    }
  });

  return { ...newState };
};

const setColumns = (columns, newFilters) => {
  return Object.keys(columns).reduce((acc, column) => {
    if (columns[column].sort === 'disabled') {
      acc[column] = columns[column];
      return acc;
    }

    acc[column] = {
      ...columns[column],
      sort:
        newFilters.sorting.value.sortField === toUrlParam(column)
          ? newFilters.sorting.value.sortDir
          : false,
    };

    return acc;
  }, {});
};

const adminCandidateListReducer = (state = initialState, action) => {
  if (!isCandidateLisAdminPageActive()) {
    return state;
  }

  switch (action.type) {
    case LOCATION_CHANGE: {
      const newFilters = setValues(
        state.filters,
        action.payload.location.lastSync
      );

      return {
        ...state,
        filters: {
          ...newFilters,
        },
        columns: {
          ...setColumns(state.columns, newFilters),
        },
      };
    }
    case ADMIN_CANDIDATE_LIST_SEARCH_STARTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
          lastSearch: action.payload.search,
        },
      };
    case ADMIN_CANDIDATE_LIST_SEARCH_SUCCESS: {
      let selectedCountOfPage = 0;
      const candidates = action.payload.data.map((candidate) => {
        const isSelected = state.meta.selected.includes(candidate.guid);

        if (isSelected) {
          selectedCountOfPage += 1;
        }

        return {
          ...candidate,
          selected: isSelected,
          createdAt: candidate.createdAt,
        };
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          total: action.payload.total,
          totalCandidates: action.payload.totalCandidates,
          selectedCountOfPage,
        },
        data: candidates,
      };
    }
    case ADMIN_CANDIDATE_TOGGLE_SELECT: {
      const selected = [...state.meta.selected];
      let { selectedCountOfPage } = state.meta;

      const candidates = state.data.map((candidate) => {
        if (candidate.guid !== action.payload.guid) {
          return candidate;
        }

        if (!candidate.selected) {
          selectedCountOfPage += 1;
          selected.push(candidate.guid);
        } else {
          selectedCountOfPage -= 1;
          pull(selected, candidate.guid);
        }

        return {
          ...candidate,
          selected: !candidate.selected,
        };
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          selected: uniq([...selected]),
          selectedCountOfPage,
        },
        data: candidates,
      };
    }
    case ADMIN_CANDIDATE_TOGGLE_SELECT_ALL: {
      const allSelected =
        state.meta.selectedCountOfPage === state.data.length &&
        state.meta.selectedCountOfPage > 0;

      const selectedList = [...state.meta.selected];
      const candidates = state.data.map((candidate) => {
        if (!allSelected) {
          selectedList.push(candidate.guid);
        } else {
          pull(selectedList, candidate.guid);
        }

        return {
          ...candidate,
          selected: !allSelected,
        };
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          selected: uniq([...selectedList]),
          selectedCountOfPage: !allSelected ? candidates.length : 0,
        },
        data: candidates,
      };
    }
    case TOGGLE_CANDIDATE_STATE_CHANGE_CONFIRMATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          stateChange: {
            ...state.meta.stateChange,
            active: !state.meta.stateChange.active,
          },
        },
      };
    case CANDIDATE_STATE_CHANGE_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          stateChange: {
            ...state.meta.stateChange,
            requestInProgress: true,
          },
        },
      };
    case CANDIDATE_STATE_CHANGE_SUCCESS: {
      const candidates = state.data.map((candidate) => {
        if (!action.payload.candidateIds.includes(candidate.guid)) {
          return candidate;
        }

        return {
          ...candidate,
          status: action.payload.status,
          selected: false,
        };
      });

      return {
        ...state,
        filters: {
          ...state.filters,
          status: {
            ...state.filters.status,
          },
        },
        meta: {
          ...state.meta,
          selected: [],
          selectedCountOfPage: 0,
          stateChange: {
            ...state.meta.stateChange,
            active: false,
            requestInProgress: false,
          },
        },
        data: candidates,
      };
    }
    case ADMIN_CANDIDATE_LIST_STAT_FETCH_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          status: {
            ...state.filters.status,
            optionCounts: Object.keys(state.filters.status.optionCounts).reduce(
              (acc, key) => {
                acc[key] = action.payload.stats[key];
                return acc;
              },
              {}
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default adminCandidateListReducer;
