import React from 'react';
import { connect } from 'react-redux';

const ErrorBoundary = ({ error, children }) => {
  const { code, message } = error;

  if (code) {
    return (
      <div>
        <div>{message || 'Looks like something went wrong...!'}</div>
      </div>
    );
  }

  return children;
};

function mapStateToProps(state, props) {
  return {
    error: state.errorReducer,
    children: props.children,
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
