import { format, formatDistanceStrict, addMinutes } from 'date-fns';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInMonths from 'date-fns/differenceInMonths';
import { isDate } from 'lodash';
import { READABLE_DATE_TIME_FORMAT } from '../common/constant';

export const dateDiffInYearsAndMonths = (date1, date2, t) => {
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  const years = differenceInYears(endDate, startDate);
  const months = differenceInMonths(endDate, startDate);
  const monthsMinYears = months - years * 12;

  const yearsTranslated = t('year', { count: years });
  const monthsTranslated = t('monthCount', { count: monthsMinYears });

  let finalString = '';
  if (years > 0) {
    finalString += `${years} ${yearsTranslated}`;
  }

  if (years > 0 && monthsMinYears > 0) {
    finalString += ` and `;
  }

  if (monthsMinYears > 0) {
    finalString += monthsTranslated;
  }

  return finalString;
};

export const humanize = (dateInUserTimeZone) => {
  const date = isDate(dateInUserTimeZone)
    ? dateInUserTimeZone
    : new Date(dateInUserTimeZone);

  const asString = formatDistanceStrict(date, new Date(), {
    includeSeconds: true,
  });

  return asString;
};

export const convertToUtcAndFormat = (
  dateString,
  requiredFormat = READABLE_DATE_TIME_FORMAT
) => {
  const date = new Date(dateString);
  return format(addMinutes(date, date.getTimezoneOffset()), requiredFormat);
};

export const addMinutesToTime = (date, minutes) => {
  return addMinutes(new Date(date), minutes);
};

export const getStartEndDateTime = (
  minutesStep = 15,
  autoSelectEndTimeGap = 30
) => {
  const t1 = new Date();
  let t2 = new Date();

  const start = Math.round(t1.getMinutes() / (minutesStep || 1));

  if (minutesStep > 0) {
    if (start === 3) {
      t1.setMinutes(0);
      t2.setMinutes(30);
    } else {
      const startMin = (start + 1) * 15;
      t1.setMinutes(startMin);
      t2.setMinutes(0);
      t2 = addMinutes(t2, startMin + (autoSelectEndTimeGap || 1));
    }
  }

  return {
    date: t1.toISOString(),
    startTime: t1.toISOString(),
    endTime: t2.toISOString(),
  };
};
