import { GET_NOTIFICATION } from '../actionTypes';
import NotificationApi from '../../api/NotificationApi';

const getNotification = (payload) => async (dispatch) => {
  const notifications = await NotificationApi.getNotification(payload);

  dispatch({
    type: GET_NOTIFICATION,
    payload: notifications.data,
  });
};

export default getNotification;
