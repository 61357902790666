export const UserTypes = Object.freeze({
  Admin: 1,
  Company: 2,
  Candidate: 3,
  RaasCompany: 4,
  CommunityUser: 5,
});

export const AdminSubUserRoles = Object.freeze({
  General: 1,
  Advocate: 2,
});

export const CompanySubUserRoles = Object.freeze({
  Admin: 1,
  Member: 2,
});

// Create, Update and Delete actions on projects
export const UserTypesCUDProjectActions = [1, 2, 4];

export const requestToInterviewAction = [2];

export const UserTypesAllowedProjectFunnelTabs = {
  1: ['*'],
  4: [2, 3, 4, 5, 6, 7],
};

// Create, Update and Delete actions on notes
export const UserTypesCUDNotesActions = [1, 2, 4];
