import React from 'react';
import { CircularProgress, Dialog } from '@mui/material';
import CommonModalAction from './CommonModalAction';
import FigXClose from '../../../assets/icons/figma-pro-2-8/general/FigXClose/FigXClose';
import IconComp from '../icons/IconComp';

const CommonModal = ({
  customClass = 'max-width-408',
  id = '',
  closeBtn = true,
  icon,
  title,
  desc,
  children,
  open,
  onCancel,
  cancelBtnFun = null,
  onConfirm,
  hasDeleteBtn,
  onDelete,
  ctaBtnLabel,
  cancelBtnLabel,
  confirmBtnLabel,
  loading = false,
  loadingCancel = false,
  loadingDelete = false,
  confirmIcon,
  cancelBtnId = '',
  confirmBtnId = '',
  deleteBtnId = '',
  preLoading = false,
  isNeedActionBtn = true,
  confirmBtnDisabled = false,
  secondaryConfirmBtnLabel,
  secondaryConfirmBtnId,
  secondaryConfirmBtnType,
  secondaryBtnOnConfirm,
  secondaryConfirmBtnLoading,
  secondaryConfirmBtnDisabled,
  secondaryConfirmBtnClass,
  secondaryConfirmIcon,
}) => {
  return (
    <Dialog
      className={customClass}
      open={open || preLoading}
      onClose={closeBtn ? onCancel : null}
      aria-labelledby='common-modal'
      id={id}
      maxWidth='400'
      fullWidth
    >
      {closeBtn && (
        <IconComp
          icon={
            <FigXClose
              className='close-btn'
              onClick={onCancel}
              id={`${id}-close-btn`}
            />
          }
        />
      )}
      <div className='common-modal-wrapper'>
        <span className='modal-icon'>{icon}</span>
        {preLoading ? (
          <div className='modal-loading-wrapper'>
            <CircularProgress />
          </div>
        ) : (
          <>
            {title && <p className='title'>{title}</p>}
            {desc && <p className='description'>{desc}</p>}
            {children}
          </>
        )}
      </div>
      {!preLoading && isNeedActionBtn && (
        <CommonModalAction
          cancelBtnLabel={cancelBtnLabel}
          cancelBtnId={cancelBtnId}
          cancelBtnFun={cancelBtnFun}
          onCancel={onCancel}
          loadingCancel={loadingCancel}
          hasDeleteBtn={hasDeleteBtn}
          deleteBtnId={deleteBtnId}
          onDelete={onDelete}
          loadingDelete={loadingDelete}
          confirmBtnLabel={confirmBtnLabel}
          ctaBtnLabel={ctaBtnLabel}
          confirmBtnId={confirmBtnId}
          onConfirm={onConfirm}
          loading={loading}
          confirmIcon={confirmIcon}
          confirmBtnDisabled={confirmBtnDisabled}
          secondaryConfirmBtnLabel={secondaryConfirmBtnLabel}
          secondaryConfirmBtnId={secondaryConfirmBtnId}
          secondaryConfirmBtnType={secondaryConfirmBtnType}
          secondaryBtnOnConfirm={secondaryBtnOnConfirm}
          secondaryConfirmBtnLoading={secondaryConfirmBtnLoading}
          secondaryConfirmBtnDisabled={secondaryConfirmBtnDisabled}
          secondaryConfirmBtnClass={secondaryConfirmBtnClass}
          secondaryConfirmIcon={secondaryConfirmIcon}
        />
      )}
    </Dialog>
  );
};

export default CommonModal;
