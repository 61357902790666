import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { orderBy } from 'lodash';
import TextBox from '../pickAndType/TextBox';
import FilterLoading from '../../loading/FilterLoading';
import SelectedOptionsV2 from './SelectedOptionsV2';
import { isUndefined } from '../../../../helpers/utilityHelper';
import ClearFiltersButton from '../ClearFiltersButton';
import { RenderAutoCompleteItem } from '../SearchFiltersCommon/SearchFiltersCommon';
import { toUrlParam } from '../../../../helpers/urlHelper';

const filter = createFilterOptions();

const PickAndSwitchFilterV2 = ({
  onChange,
  option,
  field,
  overrideValues,
  onReset,
}) => {
  const [tCommon] = useTranslation('common');
  const selectAllTxt = tCommon('labels.selectAll');

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isHideClear, setIsHideClear] = useState(false);

  const values = useMemo(() => {
    return !isUndefined(overrideValues)
      ? overrideValues.value
      : option.getValues();
  }, [option, overrideValues]);

  useEffect(() => {
    let active = true;
    setLoading(true);

    (async () => {
      const response = await option.asyncObj({ [toUrlParam('all')]: 1 });

      if (active) {
        const data = response.data.map((opt) => {
          return {
            id: opt.code ? opt.code : opt.id,
            label: opt.label,
            switch: 0,
          };
        });
        setOptions(data);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeSelected = (newValues) => {
    onChange(field, newValues, option);
  };

  const addSelected = (_, newValues) => {
    const selectAll = newValues.find((val) => val.id === 'select_all');
    let newVals = newValues;

    if (selectAll) {
      const selectedAll = filter(options, {
        inputValue,
        getOptionLabel: (opt) => opt.label,
      });

      newVals = [...selectedAll, ...values];
    }

    newVals = newVals.map((opt) => {
      return {
        ...opt,
        val: !isUndefined(opt.val) ? opt.val : opt.switch,
      };
    });
    if (option.limit && option.limit <= newVals.length) {
      newVals = newVals.slice(Math.max(newVals.length - option.limit, 0));
    }

    const selected = {};
    const data = [];
    for (let a = newVals.length - 1; a >= 0; a -= 1) {
      const opt = newVals[a];

      if (!(opt.id in selected)) {
        data.push(opt);
      }

      selected[opt.id] = 1;
    }

    onChange(field, orderBy(data, ['id'], ['asc']), option);
  };

  const clearValues = useCallback(() => {
    onReset(field, [], option);
  }, [field, onReset, option]);

  const valuesForChips = useMemo(() => {
    const valuesArray = values.map((val) => {
      const exists = options.find((obj) => {
        return obj.id === val.id;
      });

      if (exists) {
        return {
          ...exists,
          switch: val.val || val.switch,
        };
      }

      return null;
    });

    return valuesArray.filter((obj) => obj !== null);
  }, [options, values]);

  useEffect(() => {
    setIsHideClear(!valuesForChips || valuesForChips.length === 0);
  }, [valuesForChips]);

  const onInputChange = (e) => {
    if (e.target.value.length > 1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <div className='filters__item'>
      {options.length ? (
        <>
          <div className='filters__head'>
            <div className='filters__title'>
              {tCommon(field)}
              {option.limit && (
                <small>{tCommon('max', { count: option.limit })}</small>
              )}
            </div>
            {!isHideClear && (
              <ClearFiltersButton label='Reset' clearValues={clearValues} />
            )}
          </div>
          <SelectedOptionsV2
            options={valuesForChips}
            onRemove={removeSelected}
            onChange={addSelected}
            name={option.name}
          />
          <div className='filters__body'>
            <Autocomplete
              // classes={{
              //   popper: classes.autocompletePopper,
              // }}
              fullWidth
              filterSelectedOptions
              selectOnFocus
              clearOnBlur
              disableClearable
              multiple
              open={open}
              // onOpen={() => {
              //   setOpen(true);
              // }}
              onClose={(_, reason) => {
                if (reason !== 'toggleInput') {
                  setOpen(false);
                }
              }}
              openOnFocus={false}
              loading={loading}
              options={options}
              value={values}
              onChange={addSelected}
              inputValue={inputValue}
              isOptionEqualToValue={(opt, val) => {
                return opt.id === val.id;
              }}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              getOptionLabel={(opt) => opt.label}
              renderTags={() => null}
              filterOptions={(opt, params) => {
                const filtered = filter(opt, params);
                if (filtered.length > 0) {
                  filtered.push(
                    option.textSearch
                      ? selectAllTxt
                      : {
                          id: 'select_all',
                          label: selectAllTxt,
                          freeText: true,
                        }
                  );
                }
                return filtered;
              }}
              renderOption={(props, opt) => {
                return (
                  <RenderAutoCompleteItem
                    properties={{ ...props }}
                    opt={opt}
                    isTextSearch={option.textSearch}
                    selectAllTxt={selectAllTxt}
                    enableSelectAll={option.enableSelectAll}
                  />
                );
              }}
              renderInput={(params) => (
                <TextBox
                  {...params}
                  placeHolder={option.placeHolder}
                  className='grit-input'
                  onChange={onInputChange}
                />
              )}
            />
          </div>
        </>
      ) : (
        <FilterLoading />
      )}
    </div>
  );
};

export default memo(PickAndSwitchFilterV2);
