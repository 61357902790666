import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { onPageLoadProvide } from '../../providers/pageLoadProvider';
import {
  getFromSessionStorage,
  removeSessionStorage,
} from '../../helpers/storageHelper';
import {
  handleHidePageLoadComponent,
  setGlobalUrlData,
} from '../../redux/actions/pageOnLoadingAction';

const PageLoadItem = ({
  pageOnLoading,
  isAuthenticated,
  handleHidePageLoadComponentAction,
  setGlobalUrlDataAction,
}) => {
  return (
    <>
      {/* eslint-disable-next-line consistent-return */}
      {_.map(onPageLoadProvide, (val, ind) => {
        if (
          (isAuthenticated && val.private) ||
          (!isAuthenticated && !val.private)
        ) {
          // eslint-disable-next-line consistent-return
          if (pageOnLoading?.data?.[ind]?.isShow) {
            // When loading this component remove the session storage data
            const data = getFromSessionStorage(ind);
            removeSessionStorage(ind);

            // only set the data if available to avoid conflict in re render
            if (data) {
              setGlobalUrlDataAction(data);
            }

            return (
              <val.component
                key={ind}
                ind={ind}
                urlParamsInput={pageOnLoading.urlData}
                handleHidePageLoadComponent={() =>
                  handleHidePageLoadComponentAction(ind)
                }
              />
            );
          }
        }
      })}
    </>
  );
};

const mapStateToProps = (state) => {
  const { pageOnLoading, authorizationReducer } = state;
  return {
    pageOnLoading,
    isAuthenticated: authorizationReducer.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleHidePageLoadComponentAction: (index) =>
    dispatch(handleHidePageLoadComponent(index)),
  setGlobalUrlDataAction: (data) => dispatch(setGlobalUrlData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageLoadItem);
