import { LOCATION_CHANGE } from 'connected-react-router';
import { map } from 'lodash';
import { candidateNotes } from './initialState';
import {
  INIT_CANDIDATE_NOTES,
  LOAD_CANDIDATE_NOTES,
  RESET_CANDIDATE_NOTES,
  LOGOUT_SUCCESS,
  CANDIDATE_NOTES_START_PROCESS,
  CANDIDATE_NOTES_END_PROCESS,
  PICK_NOTE_FOR_EDIT,
  RESET_NOTE_FOR_EDIT,
  CANDIDATE_NOTES_START_SUBMITTING,
  CANDIDATE_NOTES_END_SUBMITTING,
  ADD_CANDIDATE_NOTE,
  UPDATE_CANDIDATE_NOTE,
  DELETE_CANDIDATE_NOTE,
  SET_CANDIDATE_NOTES_LOADED_PAGE_NO,
  SET_CANDIDATE_NOTES_SCROL_TOP_SET,
  CANDIDATE_NOTE_ADDED,
} from '../actionTypes';

const candidateNotesReducer = (state = candidateNotes, action) => {
  switch (action.type) {
    case INIT_CANDIDATE_NOTES:
      return {
        ...state,
        data: action.payload,
        loading: false,
        submitting: false,
        allNotesLoaded:
          action?.payload?.length > 0 && action?.payload.length < 20,
      };
    case LOAD_CANDIDATE_NOTES: {
      const existingNotes = state.data;
      const existingNotesKeys = map(existingNotes, 'key');
      const newItems = action.payload.filter((item) => {
        return !existingNotesKeys.includes(item.key);
      });

      return {
        ...state,
        data: existingNotes.concat(newItems),
        loading: false,
        allNotesLoaded: newItems.length === 0,
      };
    }
    case CANDIDATE_NOTES_START_PROCESS:
      return { ...state, loading: true };
    case CANDIDATE_NOTES_END_PROCESS:
      return { ...state, loading: false };
    case PICK_NOTE_FOR_EDIT:
      return { ...state, editNote: action.payload };
    case RESET_NOTE_FOR_EDIT:
      return {
        ...state,
        editNote: {
          key: null,
          guid: null,
          note: '',
          delete: false,
        },
        submitting: false,
      };
    case CANDIDATE_NOTES_START_SUBMITTING:
      return { ...state, submitting: true };
    case CANDIDATE_NOTES_END_SUBMITTING:
      return { ...state, submitting: false };
    case ADD_CANDIDATE_NOTE: {
      state.data.unshift(action.payload);
      return {
        ...state,
        data: state.data,
        allNotesLoaded: true,
        allNotesLoadedTemp: state.allNotesLoaded,
      };
    }
    case UPDATE_CANDIDATE_NOTE: {
      const updatedNote = state.data;
      updatedNote[action.payload.key] = action.payload.data;
      return { ...state, data: updatedNote };
    }
    case DELETE_CANDIDATE_NOTE: {
      const deletedNote = state.data.filter((note) => {
        return note.guid !== action.payload;
      });

      return { ...state, data: deletedNote };
    }
    case SET_CANDIDATE_NOTES_LOADED_PAGE_NO:
      return { ...state, pageNo: action.payload.pageNo };
    case SET_CANDIDATE_NOTES_SCROL_TOP_SET:
      return { ...state, lastScrollTop: action.payload.lastScrollTop };
    case CANDIDATE_NOTE_ADDED:
      return { ...state, allNotesLoaded: state.allNotesLoadedTemp };
    case LOGOUT_SUCCESS:
    case RESET_CANDIDATE_NOTES:
    case LOCATION_CHANGE:
      return state;
    default:
      return state;
  }
};

export default candidateNotesReducer;
