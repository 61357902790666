import React, { memo } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StaticSelect = ({ option, options, field, onChange }) => {
  const [t] = useTranslation('commonV1');

  const onPageChange = (e) => {
    onChange(field, e.target.value, option);
  };

  return (
    <div className='filters__item'>
      <div className='filters__head'>
        <div className='filters__title'>{t(option.label || field)}</div>
      </div>
      <FormControl>
        <Select
          disableUnderline
          className='form-group__input'
          value={option.value.perPage}
          defaultValue={option.defaultVal}
          onChange={onPageChange}
        >
          {options.map((opt) => {
            return (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default memo(StaticSelect);
