import React from 'react';
import {
  createTextSearchFilter,
  createCheckBoxFilter,
  createTableColumnSortingFilter,
  createPerPageFilter,
  createPageFilter,
} from './searchFilterInitialState';
import { CANDIDATE_STATUS_CONST } from '../../common/constant';

const query = createTextSearchFilter({ label: 'search', param: 'search' });

const status = createCheckBoxFilter({
  label: 'status',
  options: Object.keys(CANDIDATE_STATUS_CONST).map((key) => ({
    id: CANDIDATE_STATUS_CONST[key],
    label: CANDIDATE_STATUS_CONST[key],
  })),
  loading: false,
  loadingHeight: 250,
  default: false,
  optionCounts: Object.keys(CANDIDATE_STATUS_CONST).reduce((acc, key) => {
    acc[CANDIDATE_STATUS_CONST[key]] = null;
    return acc;
  }, {}),
  getOptions() {
    return this.options;
  },
  getOptionLabel(value, tcan) {
    return tcan(`labels.statuses.${value}`);
  },
  getPostFix(value) {
    return (
      <span className='filters__count'>
        {this.optionCounts[value.id] >= 0 ? this.optionCounts[value.id] : 0}
      </span>
    );
  },
});

const sorting = createTableColumnSortingFilter({
  defaultSortField: 'ca',
  defaultSortDir: 'desc',
});

const perPage = createPerPageFilter({ defaultVal: 25 });

const pagination = createPageFilter({ param: 'p' });

const initialState = {
  filters: { query, status, pagination, perPage, sorting },
  columns: {
    name: {
      label: 'candidate_name',
      id: 'name',
      numeric: false,
      sort: false,
      getLabel(t) {
        return t(this.label);
      },
    },
    email: {
      label: 'email',
      id: 'email',
      numeric: false,
      sort: false,
      getLabel(t) {
        return t(this.label);
      },
    },
    status: {
      label: 'status',
      id: 'status',
      numeric: false,
      sort: false,
      getLabel(t) {
        return t(this.label);
      },
    },
    current_location: {
      label: 'current_location',
      id: 'current_location',
      numeric: false,
      sort: 'disabled',
      getLabel(t) {
        return t(this.label);
      },
    },
    latest_company_name: {
      label: 'latest_company_name',
      id: 'latest_company_name',
      numeric: false,
      sort: 'disabled',
      getLabel(t) {
        return t(this.label);
      },
    },
    latest_job_title: {
      label: 'latest_job_title',
      id: 'latest_job_title',
      numeric: false,
      sort: 'disabled',
      getLabel(t) {
        return t(this.label);
      },
    },
    expected_salary: {
      label: 'expected_salary',
      id: 'expected_salary',
      numeric: true,
      sort: 'disabled',
      getLabel(t) {
        return t(this.label);
      },
    },
    date_created: {
      label: 'date_created',
      id: 'date_created',
      numeric: false,
      sort: 'desc',
      getLabel(t) {
        return t(this.label);
      },
    },
  },
  meta: {
    loading: false,
    selected: [],
    selectedCountOfPage: 0,
    total: 0,
    stateChange: {
      active: false,
      requestInProgress: false,
    },
    lastSearch: '',
  },
  data: [],
};

export default initialState;
