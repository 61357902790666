import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import errorReducer from './reducers/errorReducer';
import alertReducer from './reducers/alertReducer';
import userAuthorizationReducer from './reducers/userAuthorizationReducer';
import userRegistrationReducer from './reducers/userRegistrationReducer';
import searchFilterReducer from './reducers/searchFilterReducer';
import snackBarReducer from './reducers/snackBarReducer';
import authorizationReducer, {
  editAccountReducer,
} from './reducers/authorizationReducer';
import candidateReducer from './reducers/candidateReducer';
import notificationReducer from './reducers/notificationReducer';
import companiesReducer, {
  singleCompanyReducer,
} from './reducers/companiesReducer';
import adminReducer, { editAdminReducer } from './reducers/adminReducer';
import appReducer from './reducers/appReducer';
import adminCandidateListReducer from './reducers/adminCandidateListReducer';
import candidateNotesReducer from './reducers/candidateNotesReducer';
import pageOnLoadingReducer from './reducers/pageOnLoadingReducer';
import tagListReducer, { tagManageReducer } from './reducers/tagReducer';
import integrationReducer from './reducers/integrationReducer';
import {
  companyUsersReducer,
  singleCompanyUserReducer,
} from './reducers/companyUserReducer';
import creditLogReducer from './reducers/creditLogReducer';
import activeCreditsReducer from './reducers/activeCreditsReducer';
import {
  projectReducer,
  singleProjectReducer,
} from './reducers/projectsReducer';

const rootReducer = (history) =>
  combineReducers({
    app: appReducer,
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
    errorReducer,
    alertReducer,
    snackbar: snackBarReducer,
    notificationReducer,
    userAuthorizationReducer,
    pageOnLoading: pageOnLoadingReducer,
    userRegistrationReducer,
    authorizationReducer,
    searchFilter: searchFilterReducer,
    projects: projectReducer,
    singleProject: singleProjectReducer,
    companies: companiesReducer,
    singleCompany: singleCompanyReducer,
    companyUsers: companyUsersReducer,
    singleCompanyUser: singleCompanyUserReducer,
    adminList: adminReducer,
    creditLogList: creditLogReducer,
    activeCreditList: activeCreditsReducer,
    editAdmin: editAdminReducer,
    editAccount: editAccountReducer,
    adminCandidateList: adminCandidateListReducer,
    candidates: candidateReducer,
    candidateNotes: candidateNotesReducer,
    tagList: tagListReducer,
    tagManage: tagManageReducer,
    integration: integrationReducer,
  });

export default rootReducer;
