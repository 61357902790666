const notAllowedEmailDomainsArr = [
  'gmail',
  'yahoo',
  'outlook',
  'hotmail',
  'msn',
  'live',
  'mailinator',
  'aol',
  'test',
];

export default notAllowedEmailDomainsArr;
