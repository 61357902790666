import queryString from 'query-string';
import { LOCATION_CHANGE } from 'connected-react-router';
import { pull, uniq } from 'lodash';
import {
  TAG_LIST_SEARCH_STARTS,
  TAG_LIST_SEARCH_SUCCESS,
  TAG_MANAGE_CHANGE_INPUT,
  TAG_MANAGE_ENDS,
  TAG_MANAGE_STARTS,
  TAG_STATE_CHANGE_START,
  TAG_STATE_CHANGE_SUCCESS,
  TAG_TOGGLE_SELECT,
  TAG_TOGGLE_SELECT_ALL,
  TOGGLE_TAG_STATE_CHANGE_CONFIRMATION,
  TAG_CREATE_SUCCESS,
  TAG_UPDATE_REQUEST_COMPLETED,
} from '../actionTypes';
import { tagList } from './initialState';
import initialState, { tagManageInitialState } from './tagListInitialState';
import {
  isTagListPageActive,
  toUrlParam,
  urlParseOptions,
} from '../../helpers/urlHelper';

const setValues = (state, lastSync) => {
  const queryObj = queryString.parse(window.location.search, urlParseOptions);
  const newState = { ...state };

  if (lastSync) {
    const queryParam = toUrlParam(lastSync);
    const value = newState[lastSync].getUrlValue(queryObj, queryParam);
    const changes = {
      value,
    };

    const changed = {
      ...state,
      [lastSync]: {
        ...state[lastSync],
        default: false,
        ...changes,
      },
    };

    if (lastSync !== 'pagination') {
      changed.pagination.reset();
    }

    return {
      ...changed,
    };
  }

  Object.keys(newState).forEach((param) => {
    const queryParam = toUrlParam(param);
    const value = newState[param].getUrlValue(queryObj, queryParam);

    if (value) {
      const currentValue = newState[param].value;

      if (
        Array.isArray(currentValue) &&
        JSON.stringify(currentValue) === JSON.stringify(value)
      ) {
        return;
      }

      newState[param] = {
        ...newState[param],
        default: false,
      };

      newState[param].setValues(value);
      newState[param].loading = false;
    } else {
      newState[param].reset();

      newState[param] = {
        ...newState[param],
      };
    }
  });

  return { ...newState };
};

const setColumns = (columns, newFilters) => {
  return Object.keys(columns).reduce((acc, column) => {
    if (columns[column].sort === 'disabled') {
      acc[column] = columns[column];
      return acc;
    }

    acc[column] = {
      ...columns[column],
      sort:
        newFilters.sorting.value.sortField === toUrlParam(column)
          ? newFilters.sorting.value.sortDir
          : false,
    };

    return acc;
  }, {});
};

const tagListReducer = (state = initialState, action) => {
  if (typeof state === 'undefined') {
    return tagList;
  }

  if (!isTagListPageActive()) {
    return state;
  }

  switch (action.type) {
    case LOCATION_CHANGE: {
      const newFilters = setValues(
        state.filters,
        action.payload.location.lastSync
      );

      return {
        ...state,
        filters: {
          ...newFilters,
        },
        columns: {
          ...setColumns(state.columns, newFilters),
        },
      };
    }
    case TAG_LIST_SEARCH_STARTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
          lastSearch: action.payload.search,
        },
      };
    case TAG_LIST_SEARCH_SUCCESS: {
      let selectedCountOfPage = 0;
      const tags = action.payload.data.map((tag) => {
        const isSelected = state.meta.selected.includes(tag.guid);

        if (isSelected) {
          selectedCountOfPage += 1;
        }

        return {
          ...tag,
          selected: isSelected,
          createdAt: tag.createdAt,
        };
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          searchTotal: action.payload.pagination.dataCount,
          total: action.payload.pagination.total,
          selectedCountOfPage,
        },
        data: tags,
        filters: {
          ...state.filters,
          status: {
            ...state.filters.status,
            optionCounts: Object.keys(state.filters.status.optionCounts).reduce(
              (acc, key) => {
                acc[key] = action.payload.filters[key];
                return acc;
              },
              {}
            ),
          },
        },
      };
    }
    case TAG_TOGGLE_SELECT: {
      const selected = [...state.meta.selected];
      let { selectedCountOfPage } = state.meta;

      const tags = state.data.map((tag) => {
        if (tag.guid !== action.payload.guid) {
          return tag;
        }

        if (!tag.selected) {
          selectedCountOfPage += 1;
          selected.push(tag.guid);
        } else {
          selectedCountOfPage -= 1;
          pull(selected, tag.guid);
        }

        return {
          ...tag,
          selected: !tag.selected,
        };
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          selected: uniq([...selected]),
          selectedCountOfPage,
        },
        data: tags,
      };
    }
    case TAG_TOGGLE_SELECT_ALL: {
      const allSelected =
        state.meta.selectedCountOfPage === state.data.length &&
        state.meta.selectedCountOfPage > 0;

      const selectedList = [...state.meta.selected];
      const tags = state.data.map((tag) => {
        if (!allSelected) {
          selectedList.push(tag.guid);
        } else {
          pull(selectedList, tag.guid);
        }

        return {
          ...tag,
          selected: !allSelected,
        };
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          selected: uniq([...selectedList]),
          selectedCountOfPage: !allSelected ? tags.length : 0,
        },
        data: tags,
      };
    }
    case TOGGLE_TAG_STATE_CHANGE_CONFIRMATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          stateChange: {
            ...state.meta.stateChange,
            active: !state.meta.stateChange.active,
          },
        },
      };
    case TAG_STATE_CHANGE_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          stateChange: {
            ...state.meta.stateChange,
            requestInProgress: true,
          },
        },
      };
    case TAG_STATE_CHANGE_SUCCESS: {
      const tags = state.data.map((tag) => {
        if (!action.payload.tagIds.includes(tag.guid)) {
          return tag;
        }

        return {
          ...tag,
          status: action.payload.status,
          selected: false,
        };
      });

      return {
        ...state,
        filters: {
          ...state.filters,
          status: {
            ...state.filters.status,
          },
        },
        meta: {
          ...state.meta,
          selected: [],
          selectedCountOfPage: 0,
          stateChange: {
            ...state.meta.stateChange,
            active: false,
            requestInProgress: false,
          },
        },
        data: tags,
      };
    }
    case TAG_CREATE_SUCCESS: {
      state.data.unshift(action.payload.data.data);

      let selectedCountOfPage = 0;
      const tags = state.data.map((tag) => {
        const isSelected = state.meta.selected.includes(tag.guid);

        if (isSelected) {
          selectedCountOfPage += 1;
        }

        return {
          ...tag,
          selected: isSelected,
          createdAt: tag.createdAt,
        };
      });

      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          searchTotal: state.meta.searchTotal,
          total: state.meta.total,
          selectedCountOfPage,
        },
        data: tags,
        filters: state.filters,
      };
    }
    case TAG_UPDATE_REQUEST_COMPLETED: {
      const selectedCountOfPage = 0;
      const findIndex = state.data.findIndex(
        (el) => el._id === action.payload._id
      );
      if (findIndex > -1) {
        /* eslint-disable no-param-reassign */
        const existTag = state.data[findIndex];
        if (existTag) {
          let exIcon = state.data[findIndex].icon.split('/').pop();
          const newIcon = action.payload.icon.split('/').pop();
          exIcon = existTag.icon.replace(exIcon, newIcon);
          state.data[findIndex] = {
            _id: action.payload._id,
            guid: action.payload.guid,
            name: action.payload.name,
            description: action.payload.description,
            identifier: action.payload.identifier,
            icon: exIcon,
            noOfTagged: action.payload.noOfTagged,
            createdAt: action.payload.createdAt,
            status: action.payload.status,
            selected: existTag.selected,
          };
        }
        /* eslint-enable no-param-reassign */
      }

      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          searchTotal: state.meta.searchTotal,
          total: state.meta.total,
          selectedCountOfPage,
        },
        data: state.data,
        filters: state.filters,
      };
    }

    default:
      return state;
  }
};

export const tagManageReducer = (state = tagManageInitialState, action) => {
  if (typeof state === 'undefined') {
    return tagManageInitialState;
  }

  switch (action.type) {
    case TAG_MANAGE_STARTS:
      return {
        ...state,
        isAddEditEnable: true,
        ...action.payload,
      };
    case TAG_MANAGE_ENDS:
      return tagManageInitialState;
    case TAG_MANAGE_CHANGE_INPUT:
      return {
        ...state,
        tag: {
          ...state.tag,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default tagListReducer;
