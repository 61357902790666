import { translate } from '../../helpers/langHelper';
import { isUndefined } from '../../helpers/utilityHelper';

const toastMessageMiddleware = (store) => (next) => async (action) => {
  if (action.toast) {
    const { dispatch } = store;
    const { toast } = action;

    const message = await translate(
      toast.msgNamespace,
      toast.msgKey,
      isUndefined(toast.other) ? {} : toast.other
    );

    dispatch(toast.show(message));
  }

  return next(action);
};

export default toastMessageMiddleware;
