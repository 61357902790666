import axios from 'axios';
import { REACT_APP_SERVER_URL } from '../config/envConfig';

class NotificationApi {
  static notificationApi = `${REACT_APP_SERVER_URL}/api/v1/notifications`;

  static getNotification(params) {
    return axios.get(`${NotificationApi.notificationApi}`, {
      params,
    });
  }
}

export default NotificationApi;
