import {
  GET_CREDIT_LOG,
  ADD_CREDITS_MODAL_OPEN,
  GENERATE_ORDER_FORM_MODAL_OPEN,
} from '../actionTypes';
import CreditApi from '../../api/CreditApi';

export const getCreditLogList = (payload) => async (dispatch) => {
  try {
    const creditLogList = await CreditApi.getCreditLogList(payload);
    dispatch({
      type: GET_CREDIT_LOG,
      payload: creditLogList.data,
    });
  } catch (err) {
    dispatch({
      type: GET_CREDIT_LOG,
      payload: {},
    });
  }
};

export const addCreditsModalOpenAction = (open) => async (dispatch) => {
  dispatch({
    type: ADD_CREDITS_MODAL_OPEN,
    payload: open,
  });
};

export const generateOrderFormAddModalOpenAction =
  (open) => async (dispatch) => {
    dispatch({
      type: GENERATE_ORDER_FORM_MODAL_OPEN,
      payload: open,
    });
  };
