import { GET_ACTIVE_CREDITS } from '../actionTypes';
import { creditLogList } from './initialState';

const activeCreditsReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return creditLogList;
  }
  switch (action.type) {
    case GET_ACTIVE_CREDITS:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

export default activeCreditsReducer;
