import React from 'react';
import { DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BtnComp from '../buttons/BtnComp';
import IconComp from '../icons/IconComp';
import FigTrash01 from '../../../assets/icons/figma-pro-2-8/general/FigTrash01/FigTrash01';
import FigMessageChatCircle from '../../../assets/icons/figma-pro-2-8/communication/FigMessageChatCircle/FigMessageChatCircle';
import { REACT_APP_REACH_US_MAIL } from '../../../config/envConfig';

const CommonModalAction = ({
  cancelBtnLabel,
  ctaId,
  cancelBtnId,
  cancelBtnFun,
  onCancel,
  loadingCancel,
  hasDeleteBtn,
  deleteBtnId,
  onDelete,
  loadingDelete,
  confirmBtnType = 'button',
  confirmBtnLabel,
  ctaBtnLabel,
  confirmBtnId,
  onConfirm,
  loading,
  confirmBtnDisabled,
  confirmIcon,
  cusClass,
  secondaryConfirmBtnLabel,
  secondaryConfirmBtnId,
  secondaryConfirmBtnType,
  secondaryBtnOnConfirm,
  secondaryConfirmBtnLoading,
  secondaryConfirmBtnDisabled,
  secondaryConfirmBtnClass,
  secondaryConfirmIcon,
}) => {
  const [tCommon] = useTranslation('common');
  return (
    <DialogActions className={`modal-action-wrapper ${cusClass}`}>
      {ctaBtnLabel && (
        <div>
          <BtnComp
            type='link'
            id={ctaId}
            label={ctaBtnLabel}
            className='cancel-btn'
            startIcon={<IconComp icon={<FigMessageChatCircle />} />}
            hrefLink={`mailto:${REACT_APP_REACH_US_MAIL}`}
          />
        </div>
      )}
      <div className='main-action-wrapper'>
        {cancelBtnLabel && (
          <BtnComp
            id={cancelBtnId}
            handleBtnClick={cancelBtnFun || onCancel}
            label={cancelBtnLabel}
            className='cancel-btn'
            loading={loadingCancel}
          />
        )}
        {hasDeleteBtn && (
          <BtnComp
            id={deleteBtnId}
            label={tCommon('labels.delete')}
            handleBtnClick={onDelete}
            loading={loadingDelete}
            className='delete-btn'
            startIcon={<IconComp icon={<FigTrash01 />} />}
          />
        )}
        {secondaryConfirmBtnLabel && (
          <BtnComp
            id={secondaryConfirmBtnId}
            type={secondaryConfirmBtnType}
            handleBtnClick={secondaryBtnOnConfirm}
            label={secondaryConfirmBtnLabel}
            loading={secondaryConfirmBtnLoading}
            disabled={secondaryConfirmBtnDisabled}
            className={secondaryConfirmBtnClass || 'save-btn'}
            startIcon={secondaryConfirmIcon}
          />
        )}
        {confirmBtnLabel && (
          <BtnComp
            id={confirmBtnId}
            type={confirmBtnType}
            handleBtnClick={onConfirm}
            label={confirmBtnLabel}
            loading={loading}
            disabled={confirmBtnDisabled}
            className='save-btn'
            startIcon={confirmIcon}
          />
        )}
      </div>
    </DialogActions>
  );
};

export default CommonModalAction;
