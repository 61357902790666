import { countries } from '../common/country';
import { UserTypes } from '../common/roles';

const countryListForDifferentVisaStatus = [
  'sg',
  'singapore',
  'hk',
  'hong kong',
];

export const isDifferentCountryForVisaStatus = (country) => {
  return country
    ? countryListForDifferentVisaStatus.includes(country.toLowerCase())
    : false;
};

export const locationNestedData = (visaObj, countrySelected) => {
  const country = countrySelected.replace(' ', '_').toLowerCase();
  const obj = {
    1: {},
    2: {
      1: {},
      2: {},
    },
  };

  // level_1
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= Object.values(visaObj.level_1).length; i++) {
    obj[1][i] = {
      label: visaObj.level_1[i],
      val: i === 1,
    };
  }

  if (
    !countryListForDifferentVisaStatus.includes(countrySelected.toLowerCase())
  ) {
    return obj;
  }

  // level_2_1
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= Object.values(visaObj.level_2_1[country]).length; i++) {
    obj[2][1][i] = {
      label: visaObj.level_2_1[country][i],
      val: i,
    };
  }

  // level_2_2
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= Object.values(visaObj.level_2_2[country]).length; i++) {
    obj[2][2][i] = {
      label: visaObj.level_2_2[country][i],
      val: i,
    };
  }

  return obj;
};

export const searchVisaTypeOption = {
  doesNotMatter: 0,
  required: 1,
  notRequired: 2,
};

export const getCountryCodeByName = (countryName) => {
  const country = countries.find((val) => {
    // eslint-disable-next-line eqeqeq
    return val.name == countryName;
  });

  // eslint-disable-next-line no-nested-ternary
  return country?.code || null;
};

export const getFlagUrlByCountryCode = (countryCode) => {
  return `/flags/${countryCode.toUpperCase()}.svg`;
};

export const redirectToCrisp = (crispSession, role) => {
  if (crispSession && role === UserTypes.Admin) {
    window.open(
      `https://app.crisp.chat/website/653b3ada-d5f1-45a3-b34e-0210faa3fa9b/inbox/${crispSession}/`,
      '_blank'
    );
  }
};
