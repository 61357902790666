import jwt from 'jwt-decode';
import { replace } from 'connected-react-router';
import { isEmpty } from 'lodash';
import * as types from '../actionTypes';
import { ajaxCallError } from './ajaxStatusActions';
import AuthorizationApi from '../../api/AuthorizationApi';
import AuthorizationService from '../../authorization/AuthorizationService';
import AuthorizationData from '../../authorization/AuthorizationData';
import {
  HTTP_STATUS_UNAUTHORIZED,
  COMMUNITY_URL,
  CANDIDATE_STATUS_CONST,
} from '../../common/constant';
import { UserTypes } from '../../common/roles';
import {
  UPDATE_ACCOUNT_VALUES,
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_IS_WATCHED_INFO_VIDEO,
} from '../actionTypes';
import { editAccount } from '../reducers/initialState';
import { showSuccessSnackbar, showErrorSnackbar } from './snackbarActions';
import {
  deleteCookieByName,
  getCookieByName,
} from '../../helpers/cookieHelper';

const loginSuccess = (currentUser) => {
  return { type: types.LOGIN_SUCCESS, payload: currentUser };
};

const logoffSuccess = () => {
  return { type: types.LOGOFF_SUCCESS };
};

const saveAuthData = (result, dispatch) => {
  let expiration = '';
  if (result.accessToken) {
    const tokenData = jwt(result.accessToken);
    expiration = new Date(0);
    expiration.setUTCSeconds(tokenData.exp);
  }

  const authorizationData = new AuthorizationData(
    result.accessToken,
    expiration,
    result.user ? `${result.user.firstName} ${result.user.lastName}` : '',
    result?.user?.userRole || 0,
    result.status,
    result?.user?.flowStep || '',
    result?.user?.firstName || '',
    result?.user?.lastName || '',
    result?.user?.email || '',
    result?.user?.companyName || '',
    result?.user?.isWatchedInfoVideo || undefined,
    result.user ? result.user._id : '',
    result?.authorizedModules || {},
    result?.user?.adminId || undefined,
    result?.user?.lastScheduleData || undefined
  );

  AuthorizationService.authenticate(authorizationData);

  dispatch(
    loginSuccess({
      username: result.user
        ? `${result.user.firstName} ${result.user.lastName}`
        : '',
      email: result.user?.email,
      role: result.user ? result.user.userRole : 0,
      subUserRole: result.user ? result.user.subUserRole : 0,
      profilePic: result?.user?.profilePic || null,
      companyLogo: result?.user?.company?.logo || null,
      companyName: result?.user?.company?.name || null,
      isLoggedIn: true,
      accountStatus: result.status,
      // eslint-disable-next-line no-underscore-dangle
      userId: result.user ? result.user._id : '',
      authorizedModules: result.authorizedModules
        ? result.authorizedModules
        : {},
      adminId: result.user?.adminId ? result.user.adminId : undefined,
      isWatchedInfoVideo: result.user?.isWatchedInfoVideo
        ? result.user.isWatchedInfoVideo
        : undefined,
      lastScheduleData: result.user?.lastScheduleData
        ? result.user.lastScheduleData
        : undefined,
    })
  );
};

export function loginFromToken(history) {
  return async (dispatch) => {
    const data = AuthorizationService.getAuthorizationData();
    if (data?.token) {
      const expirationDate = Date.parse(data.expiration);
      const currentDate = Date.now();

      try {
        const response = await AuthorizationApi.checkLoginCookieBased();
        const result = response.data;

        if (
          expirationDate > currentDate &&
          (result.user.status === CANDIDATE_STATUS_CONST.ACTIVE ||
            (result.user.userRole === UserTypes.Candidate &&
              result.user.status === CANDIDATE_STATUS_CONST.SEMI_ACTIVE))
        ) {
          // Reorder the auth data if new data missing
          if (!data.email) {
            saveAuthData(result, dispatch);
          }

          dispatch(
            loginSuccess({
              username: result.user
                ? `${result.user.firstName} ${result.user.lastName}`
                : data.userName,
              email: result.user?.email,
              role: data.role,
              subUserRole: result?.user?.subUserRole,
              profilePic: result?.user?.profilePic || null,
              companyLogo: result?.user?.company?.logo || null,
              companyName: result?.user?.company?.name || null,
              isLoggedIn: true,
              accountStatus: data.accountStatus,
              // eslint-disable-next-line no-underscore-dangle
              userId: data.userID,
              authorizedModules: data.authorizedModules,
              adminId: result.user?.adminId ? result.user.adminId : undefined,
              isWatchedInfoVideo: result.user?.isWatchedInfoVideo
                ? result.user.isWatchedInfoVideo
                : undefined,
              lastScheduleData: result.user?.lastScheduleData
                ? result.user.lastScheduleData
                : undefined,
            })
          );

          if (data.role === UserTypes.CommunityUser) {
            window.location.href = COMMUNITY_URL;
          }

          const isSSO = getCookieByName('isSSO');
          const ssoClient = getCookieByName('ssoClient');
          if (isSSO && isSSO === 'true' && ssoClient) {
            deleteCookieByName('isSSO');
            deleteCookieByName('ssoClient');
            window.location.href = ssoClient;
          }
        } else {
          AuthorizationService.logOff();
          dispatch(logoffSuccess());
        }
      } catch (error) {
        AuthorizationService.logOff();
        dispatch(logoffSuccess());
        history.push(`/`);
      }
    } else {
      try {
        const response = await AuthorizationApi.checkLoginCookieBased();
        const result = response.data;

        if (
          result.user.status === CANDIDATE_STATUS_CONST.ACTIVE ||
          (result.user.userRole === UserTypes.Candidate &&
            result.user.status === CANDIDATE_STATUS_CONST.SEMI_ACTIVE)
        ) {
          saveAuthData(result, dispatch);
        } else {
          dispatch(logoffSuccess());
        }

        if (result.user.userRole === UserTypes.CommunityUser) {
          window.location.href = COMMUNITY_URL;
        }

        const isSSO = getCookieByName('isSSO');
        const ssoClient = getCookieByName('ssoClient');
        if (isSSO && isSSO === 'true' && ssoClient) {
          deleteCookieByName('isSSO');
          deleteCookieByName('ssoClient');
          window.location.href = ssoClient;
        }

        history.push(
          `/${
            result.user && result.user.userRole === UserTypes.Admin
              ? 'search'
              : 'projects'
          }`
        );
      } catch (error) {
        AuthorizationService.logOff();
        dispatch(logoffSuccess());
      }
    }
  };
}

export function logoff(history, redirectUrl) {
  return async (dispatch) => {
    try {
      await AuthorizationApi.logout();
      AuthorizationService.logOff();
      dispatch({
        type: types.LOGOUT_SUCCESS,
      });
    } catch (e) {
      AuthorizationService.logOff();
      dispatch(ajaxCallError());
    }

    dispatch(logoffSuccess());
    if (redirectUrl && redirectUrl.length > 0) {
      window.location.href = redirectUrl;
    }
  };
}

export function login(data, history, redirectUrl, isNewUser = false) {
  return async (dispatch) => {
    try {
      const result = data;
      saveAuthData(result, dispatch);

      if (result.user.userRole === UserTypes.CommunityUser) {
        window.location.href = COMMUNITY_URL;
        return;
      }

      const isSSO = getCookieByName('isSSO');
      const ssoClient = getCookieByName('ssoClient');
      if (isSSO && isSSO === 'true' && ssoClient) {
        deleteCookieByName('isSSO');
        deleteCookieByName('ssoClient');
        window.location.href = ssoClient;
        return;
      }

      if (redirectUrl.redirectUrl) {
        const redirect = decodeURIComponent(redirectUrl.redirectUrl);
        dispatch(replace(redirect));
        return;
      }

      history.push(
        `/${
          result.user && result.user.userRole === UserTypes.Admin
            ? 'search'
            : `projects${isNewUser ? '?is_new_user=true' : ''}`
        }`
      );
    } catch (error) {
      if (error.response.status === HTTP_STATUS_UNAUTHORIZED) {
        if (error?.response?.data?.redirect_url) {
          window.location.href = error.response.data.redirect_url;
        } else {
          throw error.response.data
            ? error.response.data.errors
            : [{ key: 'error', message: error.response.message }];
        }
      } else {
        dispatch(ajaxCallError());
      }
    }
  };
}

export function register(data, history, redirectUrl) {
  return async (dispatch) => {
    try {
      const response = await AuthorizationApi.register(data);
      const result = response.data;
      if (result.user && result.user.userRole === UserTypes.Company) {
        history.push('/auth/login');
      } else {
        saveAuthData(result, dispatch);
        history.push(redirectUrl);
      }
    } catch (error) {
      if (error.response.status) {
        // eslint-disable-next-line no-throw-literal
        throw error.response.data
          ? [
              {
                key: 'error',
                message: error.response.data.errors
                  ? error.response.data.errors[0].message
                  : 'errors.internalServerError',
              },
            ]
          : [{ key: 'error', message: 'errors.internalServerError' }];
      } else {
        dispatch(ajaxCallError());
      }
    }
  };
}

export function verify(data) {
  return async (dispatch) => {
    try {
      await AuthorizationApi.verify(data);
    } catch (error) {
      if (error.response.status) {
        // eslint-disable-next-line no-throw-literal
        throw error.response.data
          ? [{ key: 'error', message: error.response.data.message }]
          : [{ key: 'error', message: 'errors.internalServerError' }];
      } else {
        dispatch(ajaxCallError());
      }
    }
  };
}

export const getAccount = () => async (dispatch) => {
  dispatch({
    type: types.GET_ACCOUNT_START,
  });

  const account = await AuthorizationApi.getAccount();

  dispatch({
    type: types.GET_ACCOUNT_SUCCESS,
    payload: account.data ? account.data.data : editAccount.data,
  });
};

export const updateAccountValues = (name, value) => ({
  type: UPDATE_ACCOUNT_VALUES,
  payload: { [name]: value },
});

export const updateUserAccount = (data, history) => async (dispatch) => {
  dispatch({
    type: UPDATE_ACCOUNT_START,
  });

  let errorToast = {};
  try {
    const formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('email', data.email);
    formData.append('profilePicEdit', data.profileEdit);
    formData.append('phone', data?.phone || '');
    formData.append('jobTitle', data?.jobTitle || '');

    const response = await AuthorizationApi.updateAccount(formData);

    if (response.data.success) {
      dispatch({
        type: UPDATE_ACCOUNT_SUCCESS,
        payload: { ...data },
        toast: {
          msgNamespace: 'myProfile',
          msgKey: 'messages.saveSuccess',
          show: showSuccessSnackbar,
        },
      });

      dispatch(loginFromToken(history));
    } else {
      errorToast = {
        msgNamespace: 'myProfile',
        msgKey: 'messages.saveError',
        show: showErrorSnackbar,
      };
    }
  } catch (error) {
    const err = error.response.data
      ? [
          {
            key: 'myProfile',
            message: error.response.data.errors
              ? error.response.data.errors[0].message
              : 'messages.internalServerError',
          },
        ]
      : [{ key: 'error', message: 'messages.internalServerError' }];
    if (err) {
      errorToast = {
        msgNamespace: 'myProfile',
        msgKey: err[0] ? err[0].message : err,
        show: showErrorSnackbar,
      };
    }
  }

  if (!isEmpty(errorToast)) {
    dispatch({
      type: types.UPDATE_ACCOUNT_ERROR,
      toast: errorToast,
    });
  }
};

export const communitySocialSignUp = async () => {
  return AuthorizationApi.communitySocialSignUp();
};

export const communityTraditionalSignUp = (data) => {
  return async (dispatch) => {
    try {
      const response = await AuthorizationApi.communityTraditionalSignUp(data);
      const result = response.data;
      saveAuthData(result, dispatch);
      window.location.href = COMMUNITY_URL;
      return;
    } catch (error) {
      if (error.response.status) {
        // eslint-disable-next-line no-throw-literal
        throw error.response.data
          ? [
              {
                key: 'error',
                message: error.response.data.errors
                  ? error.response.data.errors[0].message
                  : error.response.data.message,
              },
            ]
          : [{ key: 'error', message: 'errors.internalServerError' }];
      } else {
        dispatch(ajaxCallError());
      }
    }
  };
};

export const magicSignUp = (data) => {
  return async (dispatch) => {
    try {
      await AuthorizationApi.communityMagicLinkSignUp(data);
      return;
    } catch (error) {
      if (error.response.status) {
        // eslint-disable-next-line no-throw-literal
        throw error.response.data
          ? [
              {
                key: 'error',
                message: error.response.data.errors
                  ? error.response.data.errors[0].message
                  : error.response.data.message,
              },
            ]
          : [{ key: 'error', message: 'errors.internalServerError' }];
      } else {
        dispatch(ajaxCallError());
      }
    }
  };
};

export const markAsInfoVideoWatched = () => {
  return async (dispatch) => {
    try {
      await AuthorizationApi.markAsInfoVideoWatched();
      dispatch({
        type: UPDATE_IS_WATCHED_INFO_VIDEO,
        payload: { isWatchedInfoVideo: true },
      });
    } catch (error) {
      dispatch(ajaxCallError());
    }
  };
};
