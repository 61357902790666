import {
  GET_CREDIT_LOG,
  ADD_CREDITS_MODAL_OPEN,
  GENERATE_ORDER_FORM_MODAL_OPEN,
} from '../actionTypes';
import { creditLogList } from './initialState';

const creditLogReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return creditLogList;
  }
  switch (action.type) {
    case GET_CREDIT_LOG:
      return { ...state, data: action.payload };
    case ADD_CREDITS_MODAL_OPEN: {
      return { ...state, isAddCreditsModalOpen: action.payload };
    }
    case GENERATE_ORDER_FORM_MODAL_OPEN: {
      return { ...state, isGenerateOrderFormModalOpen: action.payload };
    }
    default:
      return state;
  }
};

export default creditLogReducer;
