import { CHANGE_PAGE_TITLE } from '../actionTypes';
import { app } from './initialState';

const appReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return app;
  }
  if (action.type === CHANGE_PAGE_TITLE) {
    return { ...state, title: action.payload };
  }
  return state;
};

export default appReducer;
