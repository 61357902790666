import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { showSuccessSnackbar } from '../../../redux/actions/snackbarActions';

const AuthMessage = ({ showMessage }) => {
  const { search } = useLocation();
  const [tAuth] = useTranslation('auth');

  const showOnloadMessages = () => {
    const urlParameters = new URLSearchParams(search);
    const errMsg = `errorMessages.`;

    if (urlParameters.has('exitemail')) {
      showMessage(tAuth(`${errMsg}emailAlreadyExist`), 'error');
    }

    if (urlParameters.has('userStatusErr')) {
      showMessage(tAuth(`${errMsg}userStatusErr`), 'error');
    }
    if (urlParameters.has('userPending')) {
      showMessage(tAuth(`${errMsg}userPending`), 'error');
    }
    if (urlParameters.has('userPendingActivation')) {
      showMessage(tAuth(`${errMsg}userPendingActivation`), 'error');
    }
    if (urlParameters.has('userNotActivate')) {
      showMessage(tAuth(`${errMsg}userNotActivate`), 'error');
    }
    if (urlParameters.has('userNotFound')) {
      showMessage(tAuth(`${errMsg}userNotFound`), 'error');
    }
    if (urlParameters.has('companyNotFound')) {
      showMessage(tAuth(`${errMsg}companyNotFound`), 'error');
    }
    if (urlParameters.has('companyPending')) {
      showMessage(tAuth(`${errMsg}companyPending`), 'error');
    }
    if (urlParameters.has('companyNotActivate')) {
      showMessage(tAuth(`${errMsg}companyNotActivate`), 'error');
    }
    if (urlParameters.has('companyAndUserRoleMismatch')) {
      showMessage(tAuth(`${errMsg}companyAndUserRoleMismatch`), 'error');
    }
  };

  useEffect(() => {
    showOnloadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const mapDispatchToProps = (dispatch) => ({
  showMessage: (text, severity) =>
    dispatch(showSuccessSnackbar(text, severity)),
});

export default connect(null, mapDispatchToProps)(AuthMessage);
