import axios from 'axios';
import { REACT_APP_SERVER_URL } from '../config/envConfig';
import { project } from '../providers/apiFeedProvider';

class ProjectApi {
  static filterApi = `${REACT_APP_SERVER_URL}/api/v1/projects`;

  static createProject(data) {
    return axios({
      ...project.create,
      data,
    });
  }

  static addJobDescription(data) {
    return axios({
      ...project.addJobDescription,
      data,
    });
  }

  static updateProjectModal(data) {
    return axios({
      ...project.updateProjectModal,
      data,
    });
  }

  static updateProjectBasedOnFilters(params) {
    return axios({
      ...project.updateProjectBasedOnFilters,
      params,
    });
  }

  static upgradeProject(data) {
    return axios({
      ...project.upgradeProject,
      data,
    });
  }

  static cancelProject(data) {
    return axios({
      ...project.cancelProject,
      data,
    });
  }

  static guaranteeCandidateToProject(data) {
    return axios({
      ...project.guaranteeCandidateToProject,
      data,
    });
  }

  static getProject(projectGuid) {
    return axios({
      ...project.get(projectGuid),
    });
  }

  static duplicateProject(data) {
    return axios({
      ...project.duplicateProject,
      data,
    });
  }

  static search(params, tab) {
    return axios({
      ...project.search(tab),
      params,
    });
  }

  static getProjectsList(params) {
    return axios.get(`${ProjectApi.filterApi}/list`, {
      params,
    });
  }

  static deleteProjects(params) {
    return axios.delete(`${ProjectApi.filterApi}`, {
      data: {
        keys: params,
      },
    });
  }

  static updateProjects(data, status) {
    return axios.put(`${ProjectApi.filterApi}`, {
      keys: data,
      status,
    });
  }

  static grantProjectAccess(data) {
    return axios({
      ...project.grantAccess,
      data,
    });
  }

  static setNewCandidateSeen(data) {
    return axios({
      ...project.setCandidateSeen,
      data,
    });
  }

  static checkAndApproveInterviewRequest(data) {
    return axios({
      ...project.checkAndApproveInterviewRequest,
      data,
    }).catch(() => {
      return {
        success: false,
      };
    });
  }

  static submitInterviewRequestAsReject(data) {
    return axios({
      ...project.submitInterviewRequestAsReject,
      data,
    }).catch(() => {
      return {
        success: false,
      };
    });
  }

  static downloadJD(projectId) {
    return axios(project.downloadJD(projectId));
  }

  static downloadJDPublicly(interviewGuid) {
    return axios(project.downloadJDPublicly(interviewGuid));
  }

  static getProjectAccessUserList(projectId) {
    return axios({
      ...project.getProjectAccessUserList,
      params: { project_id: projectId },
    });
  }

  static getProjectAccessUserInfo(projectId) {
    return axios({
      ...project.getProjectAccessUserInfo,
      params: { project_id: projectId },
    });
  }

  static getProjectPipelineStats(params) {
    return axios({
      ...project.pipelineStats,
      params,
    });
  }

  static getProjectShareUsers(params) {
    return axios({
      ...project.getProjectShareUsers,
      params,
    });
  }

  static addUserToProject(data) {
    return axios({
      ...project.addUserToProject,
      data,
    });
  }

  static allUserAccessToProject(data) {
    return axios({
      ...project.allUserAccessToProject,
      data,
    });
  }

  static deleteSharedUser(data) {
    return axios({
      ...project.deleteSharedUser,
      data,
    });
  }

  static getProjectTabByCandidate(params) {
    return axios({
      ...project.getProjectTabByCandidate,
      params,
    });
  }

  static projectStatusStats(params) {
    return axios({
      ...project.projectStatusStats,
      params,
    });
  }

  static checkCompanyCreditsAvailability(companyId) {
    return axios({
      ...project.checkCompanyCreditsAvailability(companyId),
    });
  }

  static searchByJDAIMatch(data) {
    return axios({
      ...project.searchByJDAIMatch,
      data,
    });
  }

  static sendQualifyWhatsappMessage(data) {
    return axios({
      ...project.sendQualifyWhatsappMessage,
      data,
    });
  }

  static getAllWhatsappTemplates(nextHash) {
    return axios({
      ...project.getAllWhatsappTemplates(nextHash),
    });
  }
}

export default ProjectApi;
