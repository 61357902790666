import { SET_APP_ERROR, CLEAR_APP_ERROR } from '../actionTypes';

const initialState = { code: null, message: null };

export default function error(state = initialState, action) {
  switch (action.type) {
    case SET_APP_ERROR:
      return action.payload;
    case CLEAR_APP_ERROR:
      return initialState;
    default:
      return state;
  }
}
