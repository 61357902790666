import React from 'react';
import { UserTypes } from '../../../common/roles';
import GroupOfAvatarTitleSubTitleComp from '../avatars-with-title/GroupOfAvatarTitleSubTitleComp';

const AccountMenu = ({
  userPic,
  companyLogo,
  userAvatarName,
  companyAvatarName,
  title,
  subTitle,
  onMouseEnter,
  onMouseLeave,
  companyAccountMenu,
  candidateAccountMenu,
  adminAccountMenu,
  ref,
  role,
}) => {
  return (
    <ul className='menus'>
      <li
        ref={ref}
        className='menu-items'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <GroupOfAvatarTitleSubTitleComp
          userPic={userPic}
          userAvatarName={userAvatarName}
          companyLogo={companyLogo}
          companyAvatarName={companyAvatarName}
          title={title}
          subTitle={subTitle}
        />

        {(role === UserTypes.Company || role === UserTypes.RaasCompany) &&
          companyAccountMenu}

        {role === UserTypes.Candidate && candidateAccountMenu}

        {role === UserTypes.Admin && adminAccountMenu}
      </li>
    </ul>
  );
};

export default AccountMenu;
