import React from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FiX } from 'react-icons/fi';
import { URL_DISABLED_FILTER_FLAG } from '../../../../common/constant';

export const filterChipsConfig = {
  color: 'default',
  size: 'small',
};

const useStyles = makeStyles(() => ({
  chip: {
    '& .MuiChip-deleteIcon': {
      visibility: 'hidden',
    },
    '&:hover': {
      '& .MuiChip-deleteIcon': {
        visibility: 'visible',
      },
    },
  },
}));

const SelectedOptions = ({ options, onRemove }) => {
  const classes = useStyles();

  const handleDelete = (index) => {
    let newValues = [...options];

    if (options.length === 1 && options[0] === URL_DISABLED_FILTER_FLAG) {
      newValues = [];
    }

    if (index >= 0) {
      newValues.splice(index, 1);
    }

    onRemove(newValues);
  };

  return (
    <div className='filter-chip-wrp'>
      {options.map((opt, index) => {
        return (
          <Chip
            key={opt}
            label={opt}
            onDelete={() => handleDelete(index)}
            {...filterChipsConfig}
            size='small'
            deleteIcon={<FiX />}
            className={`grit-chip grit-chip--filter ${classes.chip}`}
          />
        );
      })}
    </div>
  );
};

export default SelectedOptions;
