import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import FilterLoading from '../../loading/FilterLoading';
import SelectedOptions from './SelectedOptions';
import ClearFiltersButton from '../ClearFiltersButton';
import { isUndefined, selectType } from '../../../../helpers/utilityHelper';
import AsynchronousAutoComplete from '../../dropdown/AsynchronousAutoComplete';
import { RenderAutoCompleteItem } from '../SearchFiltersCommon/SearchFiltersCommon';

const TypeAndPickFilter = ({
  onChange,
  option,
  field,
  overrideValues,
  onReset,
}) => {
  const [isHideClear, setIsHideClear] = useState(false);
  const [tCommon] = useTranslation('common');
  const selectAllTxt = tCommon('labels.selectAll');
  const isMultiSelect = option.selectType === selectType.MULTI;

  const values = useMemo(() => {
    if (!isMultiSelect) {
      return !isUndefined(overrideValues)
        ? overrideValues.value
        : option.getValues();
    }

    return !isUndefined(overrideValues)
      ? [...overrideValues.value]
      : [...option.getValues()];
  }, [isMultiSelect, option, overrideValues]);

  useEffect(() => {
    setIsHideClear(!values || values.length === 0);
  }, [values]);

  const removeSelected = (newValues) => {
    onChange(field, newValues, option);
  };

  const addSelected = (_, newValues, options) => {
    let newVals = newValues;

    if (!isMultiSelect) {
      onChange(field, newVals !== null ? newVals : undefined, option);
      return;
    }

    const selectAll = newValues.find((val) => val === selectAllTxt);

    if (!isUndefined(selectAll)) {
      const alreadySelected = newValues.filter((val) => val !== selectAllTxt);
      newVals = [...alreadySelected, ...options];
    }

    newVals = [...new Set(newVals)];

    if (option.limit && option.limit <= newVals.length) {
      newVals = newVals.slice(Math.max(newVals.length - option.limit, 0));
    }
    onChange(field, newVals, option);
  };

  const clearValues = useCallback(() => {
    onReset(field, [], option);
  }, [field, onReset, option]);

  const transformData = useCallback(
    (data, selectedValues) => {
      if (option.textSearch) {
        const transformedData = data.map((opt) =>
          !isUndefined(opt.label) ? opt.label : opt
        );

        let selectedData = [...transformedData];

        if (isMultiSelect) {
          selectedData = [...selectedData, ...selectedValues];
        } else {
          selectedData = [...selectedData, selectedValues];
        }

        return [...new Set([...selectedData])];
      }

      return data;
    },
    [isMultiSelect, option.textSearch]
  );

  const getPlaceHolder = () => {
    let placeHolder = '';

    switch (field) {
      case 'jobTitle':
        placeHolder = '+ Add job title';
        break;
      case 'industry':
        placeHolder = '+ Add industry';
        break;
      case 'skills':
        placeHolder = '+ Add skill';
        break;
      case 'languages':
        placeHolder = '+ Add language';
        break;
      case 'admins':
        placeHolder = '+ Add admin';
        break;
      case 'currentLocations':
        placeHolder = '+ Add location';
        break;
      default:
        placeHolder = '+ Add company';
    }

    return placeHolder;
  };

  return (
    <div className='filters__item'>
      {!option.loading ? (
        <>
          <div className='filters__head'>
            <div className='filters__title'>{tCommon(field)} </div>
            {!isHideClear && (
              <ClearFiltersButton
                label={tCommon('labels.reset')}
                clearValues={clearValues}
              />
            )}
          </div>
          {isMultiSelect && (
            <SelectedOptions options={values} onRemove={removeSelected} />
          )}
          <div className='filters__body'>
            <AsynchronousAutoComplete
              label={getPlaceHolder()}
              value={values}
              onChanges={addSelected}
              api={option.url}
              textFieldProps={{
                className: 'grit-input',
              }}
              textFieldLoadingProps={{
                size: 14,
              }}
              transformData={transformData}
              autoCompleteProps={{
                fullWidth: true,
                disableCloseOnSelect: false,
                filterSelectedOptions: true,
                selectOnFocus: true,
                clearOnBlur: true,
                disableClearable: option.enableSelectAll,
                multiple: isMultiSelect,
                style: {
                  width: 'auto',
                },
                getOptionSelected(opt, value) {
                  return option.textSearch
                    ? opt === value
                    : opt.label === value.label;
                },
                getOptionLabel(opt) {
                  if (isMultiSelect) {
                    return option.textSearch ? opt : opt.label;
                  }

                  if (Array.isArray(opt)) {
                    if (opt.length === 0) {
                      return '';
                    }
                    return opt;
                  }

                  return option.textSearch ? opt : opt.label;
                },
                renderTags() {
                  return null;
                },
                noOptionsText: (
                  <Typography variant='body2'>
                    {tCommon('labels.typeToSearch')}
                  </Typography>
                ),
                filterOptions(opt, params) {
                  const filtered = opt;

                  if (option.showCustomText && params.inputValue !== '') {
                    filtered.push(
                      option.textSearch
                        ? params.inputValue
                        : {
                            id: params.inputValue,
                            label: params.inputValue,
                            freeText: true,
                          }
                    );
                  }

                  if (filtered.length > 1 && option.enableSelectAll) {
                    filtered.push(
                      option.textSearch
                        ? selectAllTxt
                        : {
                            id: 'select_all',
                            label: 'select_all',
                            freeText: true,
                          }
                    );
                  }
                  return filtered;
                },
                renderOption(props, opt) {
                  return (
                    <RenderAutoCompleteItem
                      properties={{ ...props }}
                      opt={opt}
                      isTextSearch={option.textSearch}
                      selectAllTxt={selectAllTxt}
                      enableSelectAll={option.enableSelectAll}
                    />
                  );
                },
              }}
            />
          </div>
        </>
      ) : (
        <FilterLoading />
      )}
    </div>
  );
};

export default memo(TypeAndPickFilter);
