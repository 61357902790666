import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#000',
  },
  tooltip: {
    backgroundColor: '#000',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  const { children } = props;

  return (
    <Tooltip arrow classes={classes} {...props} placement='top'>
      {children}
    </Tooltip>
  );
}

const BootstrapToolTip = ({ title, children }) => {
  return <BootstrapTooltip title={title}>{children}</BootstrapTooltip>;
};

export default BootstrapToolTip;
