import amplitude from 'amplitude-js';
import { API_KEY } from '../config/AmplitudeConfig';
import AuthorizationService from '../authorization/AuthorizationService';
import { UserTypes } from '../common/roles';

// eslint-disable-next-line no-unused-vars
const instant = amplitude.getInstance().init(API_KEY, null, {
  includeReferrer: true,
  includeUtm: true,
  includeGclid: true,
  includeFbclid: true,
  saveParamsReferrerOncePerSession: false,
});

/**
 * Sample rest function with hardcoded valus
 */
export const initialLogEventTest = () => {
  const event = 'Page loaidng';
  const eventProperties = {
    try_count: '3',
    user_id: '123',
  };
  amplitude.getInstance().logEvent(event, eventProperties);
};

/**
 * Set user properties
 */
function setUserProperty() {
  const { role, userID, firstName, lastName, email, companyName } =
    AuthorizationService.getAuthorizationData();
  let userProp;
  if (role === UserTypes.Company || UserTypes.RaasCompany) {
    userProp = {
      firstName,
      lastName,
      email,
      companyName,
    };
  } else {
    userProp = {
      firstName: null,
      lastName: null,
      email: null,
      companyName: null,
    };
  }

  amplitude.getInstance().setUserId(userID);
  amplitude.getInstance().setUserProperties(userProp);
}

export const logAmplitudeEvent = (event, eventProperties) => {
  amplitude.getInstance().setUserId(null);
  setUserProperty();
  amplitude.getInstance().logEvent(event, eventProperties);
};
