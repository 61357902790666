// reducers/snackBarReducer.js
import { alert } from './initialState';
import { SNACKBAR_CLEAR, SHOW_SNACKBAR } from '../actionTypes';

const snackBarReducer = (state = alert, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
        severity: action.severity,
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
    default:
      return state;
  }
};

export default snackBarReducer;
