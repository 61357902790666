import React from 'react';
import { Link } from 'react-router-dom';
import CarouselSlider from '../shared/sliders/CarouselSlider';
import Testimonials from '../shared/testimonials/Testimonials';
import Messages from '../shared/snackbar/Messages';
import Logo from '../../assets/logos/grit-logo.svg';
import T1User from '../../assets/images/testimonials/t1-user.jpg';
import T2User from '../../assets/images/testimonials/t2-user.jpg';
import T3User from '../../assets/images/testimonials/t3-user.jpg';
import T1Company from '../../assets/images/testimonials/t1-company.jpg';
import T2Company from '../../assets/images/testimonials/t2-company.jpg';
import T3Company from '../../assets/images/testimonials/t3-company.jpg';
import AuthMessage from '../pages-content/auth/AuthMessage';

const LayoutWithoutNavbarForSignUps = ({ children }) => {
  const testimonialData = [
    {
      id: '1',
      userName: 'Rashmi U.',
      jobTitleAndCompany: 'Business Development Lead - APAC @ Elliptic',
      userLogo: T1User,
      companyLogo: T1Company,
      ratings: 5,
      desc: 'My experience with GRIT has been nothing short of excellent.They are extremely friendly and highly involved throughout the whole process, with feedback from the employer being communicated to me almost immediately. I would thoroughly recommend GRIT.Many thanks for all your work! It is extremely appreciated.',
    },
    {
      id: '2',
      userName: 'Alexander P.',
      jobTitleAndCompany: 'Payments Manager @ Bybit',
      userLogo: T2User,
      companyLogo: T2Company,
      ratings: 5,
      desc: 'The team at Grit Search was fantastic from start to finish. They were incredibly diligent in ensuring the position was the right one for me but also making sure I was a good fit for my employer. When I met the team they were incredibly friendly and very welcoming. I would highly recommend them for anyone either looking for a role or as an employer wanting to recruit.',
    },
    {
      id: '3',
      userName: 'Reto W.',
      jobTitleAndCompany: 'Head of Product @ Ascenda',
      userLogo: T3User,
      companyLogo: T3Company,
      ratings: 5,
      desc: 'GRIT managed to secure me a fantastic Head of Product role in a very short timeframe. I found them responsive, supportive and knowledgeable when it came to the areas they hire in as well as the process itself. They are extremely well networked and represent an impressive pool of attractive companies in the region.',
    },
  ];

  return (
    <div className='sign-up-container'>
      <Messages />
      <AuthMessage />
      <div className='content-wrapper'>
        <Link to='/'>
          <img src={Logo} alt='Grit search' />
        </Link>
        <div className='form-content-wrapper'>{children}</div>
      </div>
      <div className='details-wrapper'>
        <CarouselSlider>
          {testimonialData.map((item) => (
            <Testimonials data={item} key={item.id} />
          ))}
        </CarouselSlider>
        <div className='image-container' />
      </div>
    </div>
  );
};

export default LayoutWithoutNavbarForSignUps;
