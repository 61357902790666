/* eslint-disable */
import axios from 'axios';
import { auth, authCompany } from '../providers/apiFeedProvider';

class AuthorizationApi {
  static sendEmailOtp(data) {
    return axios({
      ...auth.sendEmailOtp,
      data,
    });
  }

  static loginByEmailOtp(data) {
    return axios({
      ...auth.loginByEmailOtp,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      data,
    });
  }

  static checkLoginCookieBased() {
    return axios({
      ...auth.loginCookieBased,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
  }

  static getProfileViaSocialLogin(token) {
    return axios({
      ...auth.getProfileViaSocialLogin(token),
    });
  }

  static verify(data) {
    return axios({
      ...auth.verify,
      data,
    });
  }

  static logout() {
    return axios({
      ...auth.logout,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    });
  }

  static getAccount() {
    return axios({
      ...auth.account,
    });
  }

  static updateAccount(data) {
    return axios({
      ...auth.update,
      data,
    });
  }

  static communitySocialSignUp() {
    return axios({
      ...auth.communitySocialSignUp,
    });
  }

  static communityTraditionalSignUp(data) {
    return axios({
      ...auth.communityTraditionalSignUp,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      data,
    });
  }

  static communityMagicLinkSignUp(data) {
    return axios({
      ...auth.communityMagicLinkSignUp,
      data,
    });
  }

  static markAsInfoVideoWatched() {
    return axios({
      ...auth.markAsWatchedInfoVideo,
    });
  }

  static checkCompanyUserInviteSignUpToken(token) {
    return axios({
      ...authCompany.checkCompanyUserInviteSignUpToken(token),
    });
  }

  static inviteSignUpComplete(data) {
    return axios({
      ...authCompany.inviteSignUpComplete,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      data,
    });
  }

  static selfSignUpStep1(data) {
    return axios({
      ...auth.selfSignUpStep1,
      data,
    });
  }

  static selfSignUpStep2(data) {
    return axios({
      ...auth.selfSignUpStep2,
      data,
    });
  }

  static selfSignUpStep3(data) {
    return axios({
      ...auth.selfSignUpStep3,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      data,
    });
  }
}

export default AuthorizationApi;
