import { LOCATION_CHANGE } from 'connected-react-router';
import {
  GET_PROJECT_LIST,
  UPDATE_CHECKBOX,
  UPDATE_PROJECT_ADD_MODAL_OPEN,
  UPDATE_PROJECT_STATUS,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  SHOW_HIDE_PROJECT_CREATE_INFO,
  GET_PROJECT_STATUS_STATS,
  GET_PROJECT,
  PROJECT_TYPE_MODAL_OPEN,
  UPDATE_PROJECT,
} from '../actionTypes';
import { projects, singleProject } from './initialState';

export const projectReducer = (state = projects, action) => {
  switch (action.type) {
    case GET_PROJECT_STATUS_STATS:
      return { ...state, projectStatusStats: action.payload };
    case GET_PROJECT_LIST:
      return { ...state, data: action.payload };
    case UPDATE_CHECKBOX:
      return { ...state };
    case LOCATION_CHANGE:
      return state;
    case UPDATE_PROJECT_ADD_MODAL_OPEN:
      return { ...state, isProjectAddModalOpen: action.payload };
    case PROJECT_TYPE_MODAL_OPEN:
      return { ...state, isProjectTypeModalOpen: action.payload };
    case UPDATE_PROJECT_STATUS: {
      const proj = state.data.data.map((project) => {
        if (!action.payload.data.projects.includes(project._id)) {
          return project;
        }

        let flag = '';
        if (action.payload.data.status === '0') {
          flag = 'Active';
        } else if (action.payload.data.status === '1') {
          flag = 'Paused';
        } else if (action.payload.data.status === '3') {
          flag = 'Inactive';
        }

        return {
          ...project,
          status: flag,
          selected: false,
        };
      });

      return { ...state, data: { ...state.data, data: proj } };
    }
    case DELETE_PROJECT_SUCCESS: {
      const projectList = state.data.data.filter((project) => {
        return !action.payload.data.projects.includes(project._id);
      });

      return { ...state, data: { ...state.data, data: projectList } };
    }
    case DELETE_PROJECT_ERROR: {
      return { ...state, data: { ...state.data } };
    }
    case SHOW_HIDE_PROJECT_CREATE_INFO: {
      return {
        ...state,
        isShowProjectCreateInfo: action.payload.isShowProjectCreateInfo,
      };
    }
    default:
      return state;
  }
};

export const singleProjectReducer = (state = singleProject, action) => {
  switch (action.type) {
    case GET_PROJECT:
      return { ...state, data: action.payload };

    case UPDATE_PROJECT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.project,
        },
      };
    default:
      return state;
  }
};
