import React from 'react';
import { Avatar, Chip, createFilterOptions } from '@mui/material';
import { FiX } from 'react-icons/fi';
import { getAvatarName } from '../../../../helpers/utilityHelper';
import { getFlagUrlByCountryCode } from '../../../../helpers/locationHelper';

// company
export const renderOptionCompany = (props, option) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <div className='async-option-wrapper'>
        {option.logo ? (
          <img
            src={option.logo}
            alt={option.label || option.name}
            className='async-img'
          />
        ) : (
          <Avatar className='async-img'>
            {getAvatarName(option.label || option.name)}
          </Avatar>
        )}
        {option.label || option.name}
      </div>
    </li>
  );
};

export const filterOptionsCompany = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.label || option.name,
});

// country
export const renderOptionCountry = (props, option) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <div className='async-option-wrapper'>
        {option.code && (
          <img
            src={getFlagUrlByCountryCode(option.code)}
            alt={option.label}
            className='async-img-flag'
          />
        )}
        {option.label}
      </div>
    </li>
  );
};

export const filterOptionsCountry = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.label,
});

// owner
export const renderOptionOwner = (props, option) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <div className='async-option-wrapper'>
        {option.profilePic ? (
          <img
            src={option.profilePic}
            alt={option.name || option.label}
            className='async-img'
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <Avatar className='async-img'>
            {getAvatarName(option.name || option.label)}
          </Avatar>
        )}
        {option.name || option.label}
      </div>
    </li>
  );
};

export const filterOptionsOwner = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.name || option.label,
});

// talent advocate
export const renderOptionAdvocate = (props, option) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <div className='async-option-wrapper'>
        {option.profilePic ? (
          <img
            src={option.profilePic}
            alt={option.label}
            className='async-img'
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <Avatar className='async-img'>{getAvatarName(option.label)}</Avatar>
        )}
        {option.label}
      </div>
    </li>
  );
};

export const filterOptionsAdvocate = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.label,
});

// add to project
export const renderOptionAddToProject = (props, option) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <div className='add-to-project-modal-dropdown-wrapper'>
        <p className='project-name'>{option.project}</p>
        <p className='company'>{`${option.company} ${
          option.owner ? `- ${option.owner}` : ''
        }`}</p>
      </div>
    </li>
  );
};

export const filterOptionsAddToProject = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.label,
});

//  country multi select
export const renderOptionMultiSelectCountry = (props, option) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <Chip
        className='multi-select-chips-in-dropdown'
        avatar={
          <img
            src={getFlagUrlByCountryCode(option.code)}
            alt={option.label}
            className='async-img-flag'
          />
        }
        label={option.label}
        size='medium'
      />
    </li>
  );
};

export const renderTagsMultiSelectCountry = (tagValue, getTagProps) => {
  return tagValue.map((option, index) => (
    <Chip
      avatar={
        <img
          src={getFlagUrlByCountryCode(option.code)}
          alt={option.label}
          className='async-img-flag'
        />
      }
      label={option.label}
      {...getTagProps({ index })}
      deleteIcon={<FiX className='async-close-btn' />}
    />
  ));
};

// languages multi select
export const renderOptionMultiSelectLanguages = (props, option) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <Chip
        className='multi-select-chips-in-dropdown'
        label={option.label}
        size='medium'
      />
    </li>
  );
};

export const renderTagsMultiSelectLanguages = (tagValue, getTagProps) => {
  return tagValue.map((option, index) => (
    <Chip
      label={option.label}
      {...getTagProps({ index })}
      deleteIcon={<FiX className='async-close-btn' />}
    />
  ));
};

// Advocate multi select
export const renderOptionMultiSelectAdvocate = (props, option) => {
  return (
    <li {...props} style={{ padding: 0 }}>
      <Chip
        className='multi-select-chips-in-dropdown'
        avatar={
          option.profilePic ? (
            <Avatar alt={option.label} src={option.profilePic} />
          ) : (
            <Avatar>{getAvatarName(option.label)} </Avatar>
          )
        }
        label={option.label}
        size='medium'
      />
    </li>
  );
};

export const renderTagsMultiSelectAdvocate = (tagValue, getTagProps) => {
  return tagValue.map((option, index) => (
    <Chip
      avatar={
        option.profilePic ? (
          <Avatar alt={option.label} src={option.profilePic} />
        ) : (
          <Avatar>{getAvatarName(option.label)} </Avatar>
        )
      }
      label={option.label}
      {...getTagProps({ index })}
      deleteIcon={<FiX className='async-close-btn' />}
    />
  ));
};
