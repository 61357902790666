import React, { useState, useMemo, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CommonModal from '../../../shared/modals/CommonModal';
import IconComp from '../../../shared/icons/IconComp';
import BtnComp from '../../../shared/buttons/BtnComp';
import FigUsersCheck from '../../../../assets/icons/figma-pro-2-8/users/FigUsersCheck/FigUsersCheck';
import {
  CREDITS_DISCOUNT_RANGE_ARR,
  CREDIT_RATE,
  GUID_TITLES,
} from '../../../../common/constant';
import { currencyFormatter } from '../../../../helpers/currencyHelper';
import { UserTypes } from '../../../../common/roles';
import AsyncAutoCompleteWithIconAndLabel from '../../../shared/inputs/AsyncAutoCompleteWithIconAndLabel';
import {
  GET_USER_LIST_BELONGS_TO_COMPANY,
  RAAS_COMPANY_LIST_SEARCH_API,
} from '../../../../helpers/urlHelper';
import FigSearchSm from '../../../../assets/icons/figma-pro-2-8/general/FigSearchSm/FigSearchSm';
import {
  filterOptionsCompany,
  filterOptionsOwner,
  renderOptionCompany,
  renderOptionOwner,
} from '../../../shared/inputs/async-auto-complete/AsyncAutoOptions';
import { showSuccessSnackbar } from '../../../../redux/actions/snackbarActions';
import CreditApi from '../../../../api/CreditApi';
import { REACT_APP_REACH_US_MAIL } from '../../../../config/envConfig';

const PurchaseCreditModal = ({
  role,
  showPurchaseCreditModal,
  closeModal,
  showMessage,
}) => {
  const [companyData, setCompanyData] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [credit, setCredit] = useState(1);
  const [form, setForm] = useState({
    companyGuid: '',
    member: '',
    companyGuidErr: '',
    memberErr: '',
  });
  const [loading, setLoading] = useState(false);
  const [secoundaryBtnLoading, setSecoundaryBtnLoading] = useState(false);
  const [tCommon] = useTranslation('common');
  const [tInput] = useTranslation('input');
  const [tCredit] = useTranslation('credit');
  const maxDiscountLimit =
    CREDITS_DISCOUNT_RANGE_ARR[CREDITS_DISCOUNT_RANGE_ARR.length - 1].MAX;

  const increaseCredit = useCallback(() => {
    setCredit((prev) => prev + 1);
  }, []);

  const decreaseCredit = useCallback(() => {
    if (credit > 1) {
      setCredit((prev) => prev - 1);
    }
  }, [credit]);

  const onChangeAsync = useCallback(
    (name, value) => {
      if (name === 'companyGuid') {
        setForm({
          ...form,
          companyGuid: value?.id,
          member: '',
          companyGuidErr: '',
          memberErr: '',
        });

        setCompanyData(value);
        setMemberData(null);
      }

      if (name === 'member') {
        setForm({
          ...form,
          member: value?.id,
          memberErr: '',
        });

        setMemberData(value);
      }
    },
    [form]
  );

  const discountPercentage = useMemo(
    () =>
      CREDITS_DISCOUNT_RANGE_ARR.find(
        (el) => el.MIN <= credit && el.MAX >= credit
      )?.DISCOUNT,
    [credit]
  );

  const validate = useCallback(() => {
    let companyGuidErr = '';
    let memberErr = '';

    if (role === UserTypes.Admin && !form.companyGuid) {
      companyGuidErr = tInput('company.errors.required');
    }

    if (role === UserTypes.Admin && !form.member) {
      memberErr = tInput('member.errors.required');
    }

    if (companyGuidErr || memberErr) {
      setForm({
        ...form,
        companyGuidErr,
        memberErr,
      });

      return false;
    }

    return true;
  }, [form, tInput, role]);

  const handleGenerateOrderForm = () => {
    if (credit > maxDiscountLimit) {
      window.location = `mailto:${REACT_APP_REACH_US_MAIL}`;
      return;
    }

    if (!validate()) return;

    setLoading(true);

    CreditApi.generateOrderForm({
      companyId: form.companyGuid.split(GUID_TITLES.COMPANY)[1],
      userId: form.member,
      noOfCredits: credit,
      discountAmount: Number(
        (CREDIT_RATE.value * credit * (1 - discountPercentage / 100)).toFixed(2)
      ),
    })
      .then(() => {
        closeModal();
        showMessage(
          tCredit('modals.purchaseCredits.successMessage'),
          'success'
        );
      })
      .catch(() => {
        setLoading(false);
        showMessage(tCredit('modals.purchaseCredits.errorMessage'), 'error');
      });
  };

  const secondaryBtnOnConfirm = () => {
    if (credit <= maxDiscountLimit) {
      setSecoundaryBtnLoading(true);

      CreditApi.instantPurchaseCredit({
        companyId: form.companyGuid.split(GUID_TITLES.COMPANY)[1],
        userId: form.member,
        noOfCredits: credit,
        discountAmount: Number(
          (CREDIT_RATE.value * credit * (1 - discountPercentage / 100)).toFixed(
            2
          )
        ),
      })
        .then((data) => {
          if (data.data.url) {
            window.location.href = data.data.url;
          } else {
            setSecoundaryBtnLoading(false);
            showMessage(
              tCredit('modals.purchaseCredits.instantPurchaseErrorMessage'),
              'error'
            );
          }
        })
        .catch((err) => {
          setSecoundaryBtnLoading(false);
          showMessage(
            err?.response?.data?.msg ||
              tCredit('modals.purchaseCredits.instantPurchaseErrorMessage'),
            'error'
          );
        });
    }
  };

  return (
    <CommonModal
      title={tCredit('modals.purchaseCredits.title')}
      desc={tCredit('modals.purchaseCredits.description')}
      open={showPurchaseCreditModal}
      onConfirm={handleGenerateOrderForm}
      onCancel={closeModal}
      confirmBtnLabel={
        credit > maxDiscountLimit
          ? tCommon('labels.contactUsForEnterprisePricing')
          : tCommon('labels.generateOrderForm')
      }
      icon={<IconComp icon={<FigUsersCheck />} />}
      loading={loading}
      customClass='max-width-480 purchase-credit-modal'
      id='purchase-credit-modal'
      secondaryConfirmBtnLabel={
        maxDiscountLimit >= credit && role === UserTypes.RaasCompany
          ? tCommon('labels.instancePurchase')
          : null
      }
      secondaryConfirmBtnId='credit-purchase-btn'
      secondaryConfirmBtnType='button'
      secondaryBtnOnConfirm={secondaryBtnOnConfirm}
      secondaryConfirmBtnLoading={secoundaryBtnLoading}
      secondaryConfirmBtnClass='modal-secondary-btn save-btn'
    >
      {role === UserTypes.Admin && (
        <>
          <AsyncAutoCompleteWithIconAndLabel
            autoFocus
            queryName='label'
            fieldName='companyGuid'
            val={companyData}
            label={tInput('company.label')}
            placeholder={tInput('company.placeholder')}
            previewImg={{
              img: {
                url: companyData?.logo || null,
                objFit: 'contain',
              },
              avatarLabel: companyData?.label || '',
            }}
            icon={<IconComp icon={<FigSearchSm />} />}
            api={RAAS_COMPANY_LIST_SEARCH_API}
            searchPreventOnEmpty
            onChangeAsync={onChangeAsync}
            filterOptions={filterOptionsCompany}
            renderOption={renderOptionCompany}
            error={form.companyGuidErr}
            isRequired
          />
          <AsyncAutoCompleteWithIconAndLabel
            queryName='name'
            fieldName='member'
            searchQuery='ts'
            val={memberData}
            previewImg={{
              img: {
                url: memberData?.profilePic || null,
                objFit: 'cover',
              },
              avatarLabel: memberData?.name || '',
            }}
            label={tInput('member.label')}
            placeholder={tInput('member.placeholder')}
            icon={<IconComp icon={<FigSearchSm />} />}
            api={`${GET_USER_LIST_BELONGS_TO_COMPANY}?company_id=${form.companyGuid}&s[]=1`}
            searchPreventOnEmpty
            onChangeAsync={onChangeAsync}
            filterOptions={filterOptionsOwner}
            renderOption={renderOptionOwner}
            error={form.memberErr}
            isDisabled={!form.companyGuid}
            isRequired
            showDropdownWhenFocus
          />
        </>
      )}

      <div className='credit-counter-wrapper'>
        <BtnComp
          id='purchase-credit-modal-credit-decrease-btn'
          className='cancel-btn inc-dec-btn'
          label='-'
          handleBtnClick={decreaseCredit}
        />
        <span className='count'>{credit}</span>
        <BtnComp
          id='purchase-credit-modal-credit-increase-btn'
          className='cancel-btn inc-dec-btn'
          label='+'
          handleBtnClick={increaseCredit}
        />
      </div>

      {maxDiscountLimit >= credit && (
        <>
          <hr className='divider' />
          <table className='payment-info-table'>
            <tr>
              <td>{tCommon('labels.discount')}</td>
              <td>{discountPercentage}%</td>
            </tr>
            <tr>
              <td>{tCommon('labels.pricePerCredit')}</td>
              <td>
                {CREDIT_RATE.currency} {CREDIT_RATE.value}
              </td>
            </tr>
            <tr>
              <td>{tCommon('labels.total')}</td>
              <td>
                {CREDIT_RATE.currency}
                {currencyFormatter
                  .format(
                    CREDIT_RATE.value * credit * (1 - discountPercentage / 100)
                  )
                  .noDecimal()
                  .shorten()
                  .get()}
              </td>
            </tr>
          </table>
        </>
      )}
    </CommonModal>
  );
};

const mapStateToProps = (state) => {
  const {
    creditLogList,
    authorizationReducer: { role },
  } = state;

  return {
    creditLogList,
    role,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showMessage: (text, severity) =>
    dispatch(showSuccessSnackbar(text, severity)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(PurchaseCreditModal));
