import React, { memo } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      paddingRight: '35px !important',
      backgroundColor: '#fff !important',
      height: '44px',
      borderRadius: '8px',
      // border: '1px solid #D0D5DD',
    },
  },
}));

const TextBox = ({ className, placeHolder, autoFocus, ...rest }) => {
  const classes = useStyles();

  return (
    <TextField
      placeholder={placeHolder}
      className={`${classes.root} ${className}`}
      {...rest}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      autoFocus={autoFocus}
    />
  );
};

export default memo(TextBox);
