import queryString from 'query-string';
import { matchPath, generatePath } from 'react-router-dom';
import { isUndefined } from 'lodash';
import {
  COMPANY_STATUS_CONST,
  COMPANY_TYPES_CONST,
  URL_ARRAY_SEPARATOR,
} from '../common/constant';
import {
  aiMatch,
  allTalent,
  previewTalent,
  projectDetail,
  search,
  tags,
} from '../common/paths';
import { REACT_APP_SERVER_URL } from '../config/envConfig';

export const LOCATIONS_API = `${REACT_APP_SERVER_URL}/api/v1/locations/search`;
export const INDUSTRIES_API = `${REACT_APP_SERVER_URL}/api/v1/industries/search`;
export const LANGUAGES_API = `${REACT_APP_SERVER_URL}/api/v1/languages/search`;
export const SKILLS_API = `${REACT_APP_SERVER_URL}/api/v1/skills/search`;
export const COMPANY_LIST_API = `${REACT_APP_SERVER_URL}/api/v1/company/list/user-invite`;
export const COMPANY_LIST_SEARCH_API = `${REACT_APP_SERVER_URL}/api/v1/company/search`;
export const RAAS_COMPANY_LIST_SEARCH_API = `${REACT_APP_SERVER_URL}/api/v1/company/search?type=${COMPANY_TYPES_CONST.RAAS}&status=${COMPANY_STATUS_CONST.ACTIVE}`;
export const PROJECT_USER_LIST_API = `${REACT_APP_SERVER_URL}/api/v1/project-share/access/user/list/to/add`;
export const GET_USER_LIST_TO_TRANSFER_API = `${REACT_APP_SERVER_URL}/api/v1/company-user/user-list-to-transfer`;
export const GET_USER_LIST_BELONGS_TO_COMPANY = `${REACT_APP_SERVER_URL}/api/v1/company-user/user-list-belongs-to-company`;
export const ADMIN_API = `${REACT_APP_SERVER_URL}/api/v1/admin/admin-list`;

const uriParamSeparator = '_';

const breakWords = (str) => {
  return str
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )
    .map((x) => x.toLowerCase());
};

export const getUrlParam = (str) => {
  const words = breakWords(str);
  return words.join(uriParamSeparator);
};

export const toUrlParam = (str) => {
  return getUrlParam(str);
};

export const formatUrl = (url) => {
  // Replace + as url encoded value(%20) if not + will convert as space
  return url.replaceAll('+', '%2B');
};

export const urlParseOptions = {
  arrayFormat: URL_ARRAY_SEPARATOR,
  encode: false,
  parseNumbers: true,
};

export const isSearchPageActive = () => {
  return (
    window.location.pathname === search || window.location.pathname === aiMatch
  );
};

export const isCandidateLisAdminPageActive = () => {
  return window.location.pathname === allTalent;
};

export const isTagListPageActive = () => {
  return window.location.pathname === tags;
};

export const isViewProjectPageActive = () => {
  const match = matchPath(window.location.pathname, {
    path: projectDetail,
  });
  const params = match?.params;

  return isUndefined(params) || isUndefined(params.id) ? null : params.id;
};

export const searchParams = {
  nameSearch: 'ns',
  textSearch: 'ts',
  companyName: 'cn',
  jobTitle: 'jt',
  salary: {
    min: 'sf',
    max: 'st',
    currency: 'cur',
  },
  noticePeriod: 'np',
  experience: { min: 'ef', max: 'et' },
  locations: 'l',
  currentLocations: 'cl',
  skills: 's',
  languages: 'lang',
  industry: 'i',
  pagination: {
    page: toUrlParam('p'),
    perPage: toUrlParam('pp'),
  },
  typeOfEmployment: 'te',
  badge: 'bd',
  admins: 'ad',
  candidateStatus: 'cs',
  workingRemotely: 'r',
};

const cleanUrl = (params, defaults, queryObj = {}) => {
  const removeParams = [...defaults, ...params];

  let query = queryObj;
  if (typeof queryObj === 'string') {
    query = queryString.parse(queryObj, urlParseOptions);
  }

  const cleanUrlParams = Object.keys(query).filter((param) => {
    return !removeParams.includes(param);
  });

  const newQuery = {};
  cleanUrlParams.forEach((param) => {
    newQuery[param] = query[param];
  });

  return newQuery;
};

export const cleanSearchUrl = (queryObj = {}, params = []) => {
  const defaults = [
    toUrlParam(searchParams.pagination.page),
    toUrlParam(searchParams.pagination.perPage),
    toUrlParam('sorting'),
    toUrlParam('tab'),
  ];

  return cleanUrl(params, defaults, queryObj);
};

export const projectSaveUrl = (queryObj = {}, params = []) => {
  const defaults = [
    toUrlParam(searchParams.companyName),
    toUrlParam(searchParams.pagination.page),
    toUrlParam(searchParams.pagination.perPage),
    toUrlParam('sorting'),
    toUrlParam('tab'),
  ];

  return cleanUrl(params, defaults, queryObj);
};

export const cleanSearchUrlAsString = (query) => {
  const queryObj = cleanSearchUrl(query);
  return queryString.stringify(queryObj, urlParseOptions);
};

export const linkToProfile = (profileId = '') => {
  return generatePath(previewTalent, {
    id: profileId,
  });
};

export const redirectPathToLogin = (redirectUrl) => {
  const redirectTo =
    redirectUrl || `${window.location.pathname}${window.location.search}`;
  return generatePath('/auth/login/:redirectUrl?', {
    redirectUrl: redirectTo === '/' ? null : redirectTo,
  });
};
