import React, { memo } from 'react';
import { Pagination } from '@mui/material';

const TablePagination = ({
  totalRecords,
  perPage,
  field,
  onChange,
  currentPage,
  option,
}) => {
  const pageCount = Math.ceil(totalRecords / perPage);

  const onPageChange = (_, value) => {
    onChange(field, value, option);
  };

  return (
    <Pagination
      count={pageCount}
      page={currentPage}
      onChange={onPageChange}
      className='pagination-wrapper'
    />
  );
};

export default memo(TablePagination);
