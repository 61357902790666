import {
  GET_COMPANY_USER,
  GET_COMPANY_USER_ERROR,
  GET_COMPANY_USER_ROLE_STATS,
  GET_COMPANY_USER_LIST,
  UPDATE_FIRST_LEVEL_COMPANY_USER_VALUES,
  UPDATE_CHECKBOX,
  CLEAR_COMPANY_USER,
  UPDATE_COMPANY_USER_STATUS,
  UPDATE_COMPANY_USER_ROLE,
  UPDATE_COMPANY_USER_FILTER_STATUS,
  DELETE_COMPANY_USER,
  UPDATE_COMPANY_USER_ADD_MODAL_OPEN,
} from '../actionTypes';
import { companyUsers, singleCompanyUser } from './initialState';

export const companyUsersReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return companyUsers;
  }
  switch (action.type) {
    case GET_COMPANY_USER_ROLE_STATS:
      return { ...state, userRoleStats: action.payload };
    case GET_COMPANY_USER_LIST:
      return { ...state, data: action.payload };
    case UPDATE_CHECKBOX:
      return { ...state };
    case UPDATE_COMPANY_USER_STATUS: {
      const companiesData = state.data.data.map((companyUser) => {
        if (!action.payload.data.companyUsers.includes(companyUser.id)) {
          return companyUser;
        }

        let flag = '';
        if (action.payload.data.status === 0) {
          flag = 'Pending';
        } else if (action.payload.data.status === 1) {
          flag = 'Active';
        } else if (action.payload.data.status === 2) {
          flag = 'Inactive';
        }

        return {
          ...companyUser,
          status: flag,
          selected: false,
        };
      });

      return { ...state, data: { ...state.data, data: companiesData } };
    }
    case UPDATE_COMPANY_USER_ROLE: {
      const companiesData = state.data.data.map((companyUser) => {
        if (!action.payload.data.companyUsers.includes(companyUser.id)) {
          return companyUser;
        }

        let role = '';
        if (action.payload.data.role === 1) {
          role = 'Admin';
        } else if (action.payload.data.role === 2) {
          role = 'Member';
        }

        return {
          ...companyUser,
          role,
          selected: false,
        };
      });

      return { ...state, data: { ...state.data, data: companiesData } };
    }
    case UPDATE_COMPANY_USER_ADD_MODAL_OPEN: {
      return { ...state, isCompanyUserAddModalOpen: action.payload };
    }
    case DELETE_COMPANY_USER: {
      const companyList = state.data.data.filter((user) => {
        return !action.payload.data.companyUsers.includes(user._id);
      });

      return { ...state, data: { ...state.data, data: companyList } };
    }
    case UPDATE_COMPANY_USER_FILTER_STATUS: {
      return { ...state, data: { ...state.data, filters: action.payload } };
    }
    default:
      return state;
  }
};

export const singleCompanyUserReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return singleCompanyUser;
  }
  switch (action.type) {
    case GET_COMPANY_USER:
      return { ...state, data: { ...state.data, ...action.payload } };
    case GET_COMPANY_USER_ERROR:
      return { ...state, data: { ...state.data }, error: action.payload };
    case UPDATE_FIRST_LEVEL_COMPANY_USER_VALUES:
      return { ...state, data: { ...state.data, ...action.payload } };
    case CLEAR_COMPANY_USER:
      return singleCompanyUser;
    default:
      return state;
  }
};
