import React, { memo } from 'react';
import LoadingPlaceholder from './LoadingPlaceholder';

const FilterLoading = ({ ...props }) => {
  return (
    <>
      <LoadingPlaceholder
        variant='text'
        height={22}
        className='filter-loading-placeholder filter-loading-placeholder__head'
      />

      <LoadingPlaceholder
        variant='text'
        height={200}
        className='filter-loading-placeholder'
        {...props}
      />
    </>
  );
};

export default memo(FilterLoading);
