import React, { memo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect, useDispatch } from 'react-redux';
import { toNumber, isNaN } from 'lodash';
import FilterLoading from '../loading/FilterLoading';
import RangeSelect from '../slider/RangeSelect';
import ClearFiltersButton from './ClearFiltersButton';
import { changeSliderMinMax } from '../../../redux/actions/searchFilterActions';
import StaticApi from '../../../api/staticApi';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInput-root': {
      padding: '0 !important',
    },
    '& input': {
      padding: '11px 16px !important',
      textAlign: 'center',
    },
  },
}));

const RangeSelectFilter = ({
  onChange,
  option,
  field,
  overrideValues,
  onReset,
  salaryCurrency,
}) => {
  const [t] = useTranslation('commonV1');
  const [tCommon] = useTranslation('common');
  const dispatch = useDispatch();
  const [limit, setLimit] = useState({ min: 0, max: 0 });
  const classes = useStyles();
  const value = overrideValues?.value || option.getValues();
  const [isHideClear, setIsHideClear] = useState(false);
  const [locationsData, setLocationsData] = useState(null);
  const [exchCurrency, setExchCurrency] = useState('SGD');

  useEffect(() => {
    setLimit({ min: value[0], max: value[1] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    StaticApi.getLocationsData().then((res) => {
      setLocationsData(res.data);
    });

    if (salaryCurrency) {
      setExchCurrency(salaryCurrency);
    }
  }, [salaryCurrency]);

  useEffect(() => {
    setIsHideClear(
      value[0] === option.defaultVal[0] && value[1] === option.defaultVal[1]
    );
  }, [option.defaultVal, option.defaultVal.max, option.defaultVal.min, value]);

  const changeSliderRange = useCallback(
    (newValues) => {
      const changedLimits = [...newValues];
      const [defaultMin, defaultMax] = option.defaultVal;
      if (newValues[0] >= defaultMin) {
        changedLimits[0] = defaultMin;
      }

      if (newValues[1] <= defaultMax) {
        changedLimits[1] = defaultMax;
      }

      dispatch(changeSliderMinMax(option, field, changedLimits));
    },
    [dispatch, field, option]
  );

  const onChangeFilter = (_, newValues) => {
    changeSliderRange(newValues);
    onChange(field, newValues, option);
  };

  const clearValues = useCallback(() => {
    changeSliderRange(option.defaultVal);
    onReset(field, option.defaultVal, option);
  }, [changeSliderRange, field, onReset, option]);

  const onLimitChange = (e, minOrMax) => {
    const newVal =
      e.target.value.replace(/\D/g, '').trim() === ''
        ? 0
        : toNumber(e.target.value);

    if (newVal !== '' && isNaN(newVal)) {
      setLimit({
        ...limit,
        [minOrMax]: minOrMax === 'min' ? limit.min : limit.max,
      });
    } else {
      const newLimit = {
        ...limit,
        [minOrMax]: newVal,
      };
      setLimit(newLimit);

      const newValues = [toNumber(newLimit.min), toNumber(newLimit.max)];
      changeSliderRange(newValues);

      const filterVals = [...option.getValues()];
      const [min, max] = newValues;
      filterVals[0] = min;
      filterVals[1] = max;

      if (field === 'salary') filterVals[2] = exchCurrency;

      onChange(field, filterVals, option);
    }
  };

  const onDrag = useCallback((newValues) => {
    setLimit({
      min: newValues[0],
      max: newValues[1],
    });
  }, []);

  const handleExchangeCurrency = (e) => {
    setExchCurrency(e.target.value);
    onChange('salary', [limit.max, limit.min, e.target.value], option);
  };

  return (
    <div className='filters__item'>
      {!option.loading ? (
        <>
          <div className='filters__head'>
            <div className='filters__title'>{tCommon(field)}</div>
            {!isHideClear && (
              <ClearFiltersButton
                label={tCommon('labels.reset')}
                clearValues={clearValues}
              />
            )}
          </div>
          <div className='filters__body salary-filter'>
            {field === 'salary' && (
              <select
                className='currency-dropdown'
                value={exchCurrency}
                onChange={handleExchangeCurrency}
              >
                {locationsData?.uniqueCurrencies?.length > 0 &&
                  locationsData.uniqueCurrencies.map((currency) => (
                    <option value={currency.currency} key={currency.key}>
                      {currency.currency}
                    </option>
                  ))}
              </select>
            )}
            <div className='range-slider'>
              <div className='range-slider__details'>
                <div className='range-slider__left'>
                  <TextField
                    classes={{ root: classes.textField }}
                    className='range-slider__input'
                    value={limit.min}
                    name={`${field}-0`}
                    id={`${field}-0`}
                    onChange={(e) => onLimitChange(e, 'min')}
                    InputProps={{
                      inputComponent: option.inputProps
                        ? option.inputProps
                        : null,
                      inputProps: { t, option },
                    }}
                  />
                </div>
                <div className='range-slider__to'>{tCommon('labels.to')}</div>
                <div className='range-slider__right'>
                  <TextField
                    classes={{ root: classes.textField }}
                    className='range-slider__input'
                    value={limit.max}
                    name={`${field}-1`}
                    id={`${field}-1`}
                    onChange={(e) => onLimitChange(e, 'max')}
                    InputProps={{
                      inputComponent: option.inputProps
                        ? option.inputProps
                        : null,
                      inputProps: { t, option },
                    }}
                  />
                </div>
              </div>

              {['experience', 'experienceForAiMatch'].includes(field) && (
                <RangeSelect
                  max={option.max}
                  min={option.min}
                  step={option.step}
                  value={value.slice(0, 2)}
                  onChange={onChangeFilter}
                  onDrag={onDrag}
                  valueLabelDisplay='off'
                  aria-labelledby='range-slider'
                  field={field}
                  valueLabelFormat={option.getSliderLabel}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <FilterLoading height={option.loadingHeight} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    salaryCurrency: state?.searchFilter?.filters?.salary?.value[2] || null,
  };
};

export default connect(mapStateToProps, null)(memo(RangeSelectFilter));
