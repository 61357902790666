import { Avatar } from '@mui/material';
import React from 'react';
import { getAvatarName } from '../../../helpers/utilityHelper';
import AsynchronousAutoComplete from './async-auto-complete/AsynchronousAutoComplete';

const AsyncAutoCompleteWithIconAndLabel = ({
  id = '',
  autoFocus,
  isShowOptionOnLoad = false,
  fieldName,
  queryName,
  val,
  label,
  placeholder,
  searchQuery = 'title',
  icon,
  previewImg,
  isRequired = false,
  api,
  onChangeAsync,
  renderOption,
  filterOptions,
  error,
  appendToSearchQuery,
  isDisabled,
  info,
  cusStyle = {},
  noResultsLink,
  showDropdownWhenFocus,
}) => {
  return (
    <div className='async-autocomplete-wrapper' style={cusStyle}>
      {label && (
        <label htmlFor={id}>
          {label} {isRequired && '*'}
        </label>
      )}
      <div
        className={`input-wrapper 
           ${error && 'cus-error-border'} ${isDisabled && 'disabled'}
        `}
      >
        {!previewImg?.img?.url && !previewImg?.avatarLabel && icon && (
          <span className='icon'>{icon}</span>
        )}

        {previewImg?.img?.url && (
          <img
            src={previewImg?.img?.url}
            alt={previewImg?.avatarLabel}
            className='preview-selected-item-img'
            style={{ objectFit: previewImg?.img?.objFit }}
          />
        )}

        {!previewImg?.img?.url && previewImg?.avatarLabel && (
          <Avatar className='preview-selected-item-img'>
            {getAvatarName(previewImg?.avatarLabel)}
          </Avatar>
        )}

        <AsynchronousAutoComplete
          id={id}
          autoFocus={autoFocus}
          isShowOptionOnLoad={isShowOptionOnLoad}
          name={fieldName}
          placeholder={placeholder}
          searchQuery={searchQuery}
          value={val}
          onChanges={onChangeAsync}
          api={api}
          textFieldProps={{
            variant: 'outlined',
          }}
          autoCompleteProps={{
            fullWidth: true,
            style: {
              width: 'auto',
            },
            getOptionSelected(option, value) {
              return option.id === value.id;
            },
            getOptionLabel(option) {
              return option ? option[queryName] : '';
            },
          }}
          renderOption={renderOption}
          filterOptions={filterOptions}
          appendToSearchQuery={appendToSearchQuery}
          isDisabled={isDisabled}
          noResultsLink={noResultsLink}
          showDropdownWhenFocus={showDropdownWhenFocus}
        />
      </div>
      {info && <p className='cus-information'>{info}</p>}
      {error && <p className='cus-error'>{error}</p>}
    </div>
  );
};

export default AsyncAutoCompleteWithIconAndLabel;
