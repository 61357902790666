import { push } from 'connected-react-router';
import queryString from 'query-string';
import {
  formatUrl,
  toUrlParam,
  urlParseOptions,
} from '../../helpers/urlHelper';
import { URL_DISABLED_FILTER_FLAG } from '../../common/constant';

const urlSyncMiddleware = (store) => (next) => (action) => {
  if (action.pushToUrl) {
    const { search } = store.getState().router.location;
    const { pagination } = store.getState().searchFilter.filters;

    let changesObj = null;
    if (action.payload.searchType === 'multiple') {
      changesObj = action.payload.changesObj;
    } else {
      changesObj = {
        [action.payload.field]: action.payload,
      };
    }

    let newQueryObj = queryString.parse(formatUrl(search), urlParseOptions);
    const changedKeys = Object.keys(changesObj);
    changedKeys.forEach((key) => {
      const obj = changesObj[key];
      let val = obj.value;

      if (val && val.length === 0) {
        val = URL_DISABLED_FILTER_FLAG;
      }

      const queryName = toUrlParam(obj.field);
      newQueryObj = {
        ...newQueryObj,
        ...obj.option.getAsUrl(queryName, val, newQueryObj),
      };
    });

    if (
      action.payload.field !== 'pagination' &&
      newQueryObj[pagination.paramPage]
    ) {
      newQueryObj = {
        ...newQueryObj,
        [pagination.paramPage]: undefined,
      };
    }

    const searchQuery = queryString.stringify(newQueryObj, urlParseOptions);
    store.dispatch(
      push({
        query: newQueryObj,
        search: searchQuery,
        lastSync: action.payload.field,
      })
    );

    return null;
  }

  return next(action);
};

export default urlSyncMiddleware;
