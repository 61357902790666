import React from 'react';
import { Chip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FilterSwitchV2 from './FilterSwitchV2';
import FigXClose from '../../../../assets/icons/figma-pro-2-8/general/FigXClose/FigXClose';
import IconComp from '../../../../components/shared/icons/IconComp';

export const useFilterChipsStyles = makeStyles(() => ({
  btnGrp: {
    display: 'flex',

    '& .MuiList-padding': {
      padding: 0,
    },

    '&:hover': {
      '& .MuiButtonBase-root': {
        visibility: 'visible !important',
      },
    },
  },
  paddingZero: {
    padding: 0,
  },
  closeBtnStyle: {
    backgroundColor: 'inherit !important',
    marginBottom: 10,
    visibility: 'hidden',
  },
}));

export const filterChipsConfig = {
  color: 'default',
  size: 'small',
};

const SelectedOptionsV2 = ({ options, onChange, name }) => {
  const classes = useFilterChipsStyles();

  const onSwitch = (index, option) => {
    const updated = options.map((opt, idx) => {
      if (idx === index) {
        return {
          ...opt,
          switch: option,
        };
      }

      return { ...opt };
    });
    onChange(null, updated);
  };

  const handleDelete = (index) => {
    options.splice(index, 1);
    onChange(null, options);
  };

  return (
    <div className='filter-chip-wrp'>
      {options.map((opt, index) => {
        const checked = typeof opt.switch === 'undefined' ? 0 : opt.switch;
        return (
          <div className={`${classes.btnGrp}`} key={opt.id}>
            <Chip
              label={
                <FilterSwitchV2
                  option={opt}
                  checked={checked}
                  onSwitch={onSwitch}
                  index={index}
                  name={name}
                />
              }
              {...filterChipsConfig}
              size='small'
              className='grit-chip grit-chip--filter'
            />
            <IconButton
              className={`${classes.paddingZero} ${classes.closeBtnStyle}`}
            >
              <IconComp
                icon={
                  <FigXClose
                    fontSize='small'
                    onClick={() => handleDelete(index)}
                  />
                }
                classes='filter-selected-item-close-btn'
              />
            </IconButton>
          </div>
        );
      })}
    </div>
  );
};

export default SelectedOptionsV2;
