import React, { memo, useState, useEffect } from 'react';
import { Slider } from '@mui/material';

const RangeSelect = ({ onChange, onDrag, value, field, ...sliderProps }) => {
  const [sliderValue, setSliderValue] = useState(value);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const localChanges = (e, newValues) => {
    setSliderValue(newValues);
    onDrag(newValues);
  };

  const processSearch = (e, newValues) => {
    onChange(field, newValues);
  };

  return (
    <Slider
      {...sliderProps}
      value={sliderValue}
      onChangeCommitted={processSearch}
      onChange={localChanges}
      disableRipple
    />
  );
};

export default memo(RangeSelect);
