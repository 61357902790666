import i18next from 'i18next';
import i18n from '../app/i18n';

export const getAppLocale = () => {
  const locale = i18n.language || window.localStorage.i18nextLng || '';
  return locale;
};

export const getLocale = (locale) => {
  const currentLocale = locale || getAppLocale();
  if (currentLocale.indexOf('en-') >= 0) {
    return 'en';
  }

  return currentLocale;
};

export const langLevels = {
  doesNotMatter: 0,
  beginner: 1,
  intermediate: 2,
  expert: 3,
};

export const translate = async (namespace, key, other = {}) => {
  return i18next.loadNamespaces([namespace]).then(() => {
    return i18next.t(`${namespace}.${key}`, other);
  });
};
