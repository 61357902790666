import {
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  GET_PROJECT_LIST,
  UPDATE_CHECKBOX,
  UPDATE_PROJECT_ADD_MODAL_OPEN,
  PROJECT_TYPE_MODAL_OPEN,
  UPDATE_PROJECT_STATUS,
  SHOW_HIDE_PROJECT_CREATE_INFO,
  GET_PROJECT_STATUS_STATS,
  GET_PROJECT,
  SUCCESS_FETCH_PROJECT,
  UPDATE_PROJECT,
} from '../actionTypes';
import ProjectApi from '../../api/ProjectApi';
import { numberWithCommas } from '../../helpers/currencyHelper';
import { UserTypes } from '../../common/roles';

export const getProjectStatusStats = (data, trans) => async (dispatch) => {
  const res = await ProjectApi.projectStatusStats(data);
  const { Active, Paused, Inactive } = res.data;

  const statData = [
    {
      id: trans('title'),
      value: Active + Paused + Inactive,
    },
    {
      id: trans('labels.statuses.status_0'),
      value: Active,
    },
    {
      id: trans('labels.statuses.status_1'),
      value: Paused,
    },
    {
      id: trans('labels.statuses.status_3'),
      value: Inactive,
    },
  ];

  dispatch({
    type: GET_PROJECT_STATUS_STATS,
    payload: statData,
  });
};

const getProjects = (payload, trans, role) => async (dispatch) => {
  const projects = await ProjectApi.getProjectsList(payload);
  const projectDataObject = projects.data.data;

  // formatting the payload to frontend standards
  if (
    payload &&
    !payload.s &&
    !payload.ts &&
    !payload.company_id &&
    projectDataObject &&
    projectDataObject.length === 0 &&
    role !== UserTypes.Admin
  ) {
    dispatch({
      type: SHOW_HIDE_PROJECT_CREATE_INFO,
      payload: { isShowProjectCreateInfo: true },
    });
  } else {
    dispatch({
      type: SHOW_HIDE_PROJECT_CREATE_INFO,
      payload: { isShowProjectCreateInfo: false },
    });

    if (projectDataObject) {
      Object.keys(projectDataObject).forEach((key) => {
        const stats = projectDataObject[key]?.stats;
        projectDataObject[key].shortlist = numberWithCommas(stats?.shortlist);
        projectDataObject[key].qualify = numberWithCommas(stats?.qualify);
        projectDataObject[key].interviewing = numberWithCommas(
          stats?.interviewing
        );
        projectDataObject[key].interviewing2 = numberWithCommas(
          stats?.interviewing2
        );
        projectDataObject[key].offered = numberWithCommas(stats?.offered);
        projectDataObject[key].placed = numberWithCommas(stats?.placed);
        projectDataObject[key].checked = false;
        projectDataObject[key].status = trans(
          `labels.statuses.status_${projectDataObject[key].status}`
        );
      });
    }

    projects.data.data = projectDataObject;

    dispatch({
      type: GET_PROJECT_LIST,
      payload: projects.data,
    });
  }
};

const updateCheckBoxes =
  (object, value = null, id = null, deselectAll = false) =>
  (dispatch) => {
    const projectDataObject = object.data.data;

    Object.keys(projectDataObject).forEach((key) => {
      if (deselectAll) {
        projectDataObject[key].checked = false;
      } else if (projectDataObject[key]._id === id) {
        projectDataObject[key].checked = value;
      }
    });

    dispatch({
      type: UPDATE_CHECKBOX,
      payload: object.data,
    });
  };

const updateCheckBoxesOnPagination =
  (object, selected = []) =>
  (dispatch) => {
    const projectDataObject = object.data.data;

    Object.keys(projectDataObject).forEach((key) => {
      if (selected.includes(projectDataObject[key]._id)) {
        projectDataObject[key].checked = true;
      }
    });

    dispatch({
      type: UPDATE_CHECKBOX,
      payload: object.data,
    });
  };

const updateProjectAddModalOpenAction = (open) => async (dispatch) => {
  dispatch({
    type: UPDATE_PROJECT_ADD_MODAL_OPEN,
    payload: open,
  });
};

const projectTypeModalOpenAction = (open) => async (dispatch) => {
  dispatch({
    type: PROJECT_TYPE_MODAL_OPEN,
    payload: open,
  });
};

const updateProjectStatus = (ids, status) => async (dispatch) => {
  const resp = await ProjectApi.updateProjects(ids, status);
  dispatch({
    type: UPDATE_PROJECT_STATUS,
    payload: resp.data,
  });
  return resp;
};

const deleteProject = (ids) => async (dispatch) => {
  const resp = await ProjectApi.deleteProjects(ids);
  if (resp?.data?.success) {
    dispatch({
      type: DELETE_PROJECT_SUCCESS,
      payload: resp.data,
    });
  } else {
    dispatch({
      type: DELETE_PROJECT_ERROR,
      payload: resp,
    });
  }
  return resp;
};

const getProject = (projectGuid) => async (dispatch) => {
  dispatch({
    type: GET_PROJECT,
    payload: {},
  });

  if (projectGuid) {
    try {
      const project = await ProjectApi.getProject(projectGuid);

      dispatch({
        type: GET_PROJECT,
        payload: project.data,
      });

      dispatch({
        type: SUCCESS_FETCH_PROJECT,
        payload: {
          project: project.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PROJECT,
        payload: {},
      });
    }
  }
};

export const updateProjectObj = (project) => async (dispatch) => {
  dispatch({
    type: UPDATE_PROJECT,
    payload: {
      project,
    },
  });
};

export {
  updateCheckBoxes,
  updateCheckBoxesOnPagination,
  updateProjectAddModalOpenAction,
  projectTypeModalOpenAction,
  updateProjectStatus,
  deleteProject,
  getProject,
};
export default getProjects;
