import loadable from '@loadable/component';
import queryString from 'query-string';
import { urlParams } from '../common/constant';
import { urlParseOptions } from '../helpers/urlHelper';

const params = queryString.parse(window.location.search, {
  ...urlParseOptions,
  parseBooleans: true,
});

const urlVariable = [urlParams.interviewRequestToken, urlParams.accessValue];

if (params?.m) {
  urlVariable.push(urlParams.responseMethod);
}

// eslint-disable-next-line import/prefer-default-export
export const onPageLoadProvide = {
  candidateInterviewRequest: {
    private: true,
    urlVariable,
    component: loadable(() =>
      // eslint-disable-next-line import/no-cycle
      import(
        '../components/pages-content/projects/interviewRequest/InterviewRequestComponent'
      )
    ),
  },
};
