import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import IsAnonymousProfileIndicator from '../../tooltips/IsAnonymousProfileIndicator';
import DatePickerRenderInput from './DatePickerRenderInput';

const YearMonthPickerWithLabel = ({
  name,
  id = '',
  label,
  value,
  handleChanges,
  isDisabled = false,
  isRequired = false,
  isHidden = false,
  minDate = '1950-01-01',
  maxDate,
  views,
  format,
  error,
  icon,
}) => {
  return (
    <div className='input-field-with-icon-label-wrapper'>
      <label htmlFor='title'>
        {label} {isRequired && '*'}
      </label>
      {isHidden && (
        <IsAnonymousProfileIndicator id={`${id}-hidden-indicator`} />
      )}
      <div className='input-wrapper'>
        {icon && <div className='icon-div'>{icon}</div>}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className={`field date-picker ${error ? 'cus-error-border' : ''}`}
            id={id}
            name={name}
            views={views}
            format={format}
            value={parseISO(new Date(value).toISOString())}
            minDate={parseISO(new Date(minDate).toISOString())}
            maxDate={parseISO(new Date(maxDate || new Date()).toISOString())}
            disabled={isDisabled}
            onChange={(e) => {
              handleChanges(name, e);
            }}
            slots={{
              textField: DatePickerRenderInput,
            }}
          />
        </LocalizationProvider>
      </div>
      <p className='cus-error'>{error}</p>
    </div>
  );
};

export default YearMonthPickerWithLabel;
