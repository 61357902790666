import React, { useEffect, useMemo, useRef } from 'react';
import IconComp from '../icons/IconComp';
import IsAnonymousProfileIndicator from '../tooltips/IsAnonymousProfileIndicator';
import FigAlertTriangle from '../../../assets/icons/figma-pro-2-8/alertsAndFeedback/FigAlertTriangle/FigAlertTriangle';

const InputFieldWithIconLabel = ({
  type,
  id = '',
  name,
  value,
  minValue,
  maxValue,
  handleChanges,
  label,
  placeholder = '',
  isHidden = false,
  isRequired = false,
  isDisabled = false,
  isNotVerified = false,
  isNotVerifiedText = '',
  info,
  error,
  hasErrorBorder,
  icon,
  cusStyle = {},
  readOnly = false,
  hasIconBg = false,
  isFocused = false,
  cusClassName = '',
  handleOnKeyDown,
}) => {
  const textInputRef = useRef(null);

  const handleInvalidNumericChar = (evt) => {
    if (type === 'number' && ['e', 'E', '+', '-'].includes(evt.key)) {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    if (isFocused) {
      textInputRef.current.focus();
    }
  }, [isFocused]);

  const paddingLeftValue = useMemo(() => {
    if (hasIconBg) {
      return 53;
    }

    if (icon) {
      return 40;
    }

    return 15;
  }, [hasIconBg, icon]);

  return (
    <div
      className={`input-field-with-icon-label-wrapper ${cusClassName}`}
      style={cusStyle}
    >
      {label && (
        <label htmlFor={name}>
          {label} {isRequired && '*'}
        </label>
      )}
      {isHidden && (
        <IsAnonymousProfileIndicator id={`${id}-hidden-indicator`} />
      )}
      <div className='input-wrapper'>
        <span className={hasIconBg ? 'icon active' : 'icon'}>{icon}</span>
        <input
          className={`field  
           ${error || hasErrorBorder ? 'cus-error-border' : ''} ${
            isDisabled && 'disabled'
          }
        `}
          type={type}
          id={id}
          name={name}
          value={value}
          min={minValue}
          max={maxValue}
          onChange={(e) => handleChanges(name, e.target.value)}
          onWheel={(e) => e.currentTarget.blur()}
          placeholder={placeholder}
          disabled={isDisabled}
          style={{ paddingLeft: paddingLeftValue }}
          readOnly={readOnly}
          ref={textInputRef}
          onKeyDown={handleOnKeyDown ?? handleInvalidNumericChar}
        />
      </div>
      {info && <p className='cus-information'>{info}</p>}
      {isNotVerified && (
        <p className='not-verified-warning'>
          <IconComp icon={<FigAlertTriangle />} /> {isNotVerifiedText}
        </p>
      )}
      {error && <p className='cus-error'>{error}</p>}
    </div>
  );
};

export default InputFieldWithIconLabel;
