import queryString from 'query-string';
import _ from 'lodash';
import { urlParseOptions } from '../../helpers/urlHelper';
// eslint-disable-next-line import/no-cycle
import { onPageLoadProvide } from '../../providers/pageLoadProvider';
import {
  getFromSessionStorage,
  setToSessionStorage,
} from '../../helpers/storageHelper';
import {
  CHANGE_SHOW_STATE,
  HIDE_ALL_PAGE_LOAD_COMPONENT,
  PAGE_LOAD_CHECK_COMPLETE,
  SET_GLOBAL_URL_DATA,
  SET_SHOW_HIDE_INTERVIEW_REQUEST,
} from '../actionTypes';
import ProjectApi from '../../api/ProjectApi';

const loadItemShowSuccess = (ind) => {
  return {
    type: CHANGE_SHOW_STATE,
    pageLoadItem: ind,
    payload: { isShow: true },
  };
};

// Check page on loading parameters exist or not on page loading and show the relevant component
export function pageOnloadChecker() {
  return async (dispatch) => {
    const urlParamsInput = queryString.parse(window.location.search, {
      ...urlParseOptions,
      parseBooleans: true,
    });

    // eslint-disable-next-line consistent-return
    _.each(onPageLoadProvide, (val, ind) => {
      let isAllUrlVariableExist = true;
      const existSessionData = getFromSessionStorage(ind);
      if (existSessionData) {
        // Update state as load item should show
        dispatch(loadItemShowSuccess(ind));
        return false;
      }

      const urlQueryInput = {};
      // eslint-disable-next-line consistent-return
      _.each(val.urlVariable, (eachVar) => {
        urlQueryInput[eachVar] = urlParamsInput[eachVar];
        if (_.isUndefined(urlParamsInput[eachVar])) {
          isAllUrlVariableExist = false;
          return false;
        }
      });

      if (isAllUrlVariableExist) {
        setToSessionStorage(ind, urlQueryInput);
        dispatch(loadItemShowSuccess(ind));
        return false;
      }
    });

    dispatch({ type: PAGE_LOAD_CHECK_COMPLETE });
  };
}

export function setGlobalUrlData(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_GLOBAL_URL_DATA,
      payload: data,
    });
  };
}

// Hide page load component once the process done
export function handleHidePageLoadComponent(index) {
  return async (dispatch) => {
    dispatch({
      type: HIDE_ALL_PAGE_LOAD_COMPONENT,
      pageLoadItem: index,
    });
  };
}

// Show hide candidate interview request response components by each action
export const setInterviewRequestShowHide =
  (payload, type) => async (dispatch) => {
    dispatch({
      type,
      payload,
    });
  };

// Check token valid or not and approve if accessValue = true
export const checkTokenAndApprove =
  (ind, interviewRequestToken, accessValue, responseMethod) =>
  async (dispatch) => {
    const checkData = await ProjectApi.checkAndApproveInterviewRequest({
      interviewRequestToken,
      accessValue,
      responseMethod,
    });
    let stateData = {};

    if (checkData?.data?.success) {
      if (checkData.data.isTokenUsed) {
        // Check already submitted or not
        stateData = {
          isLoading: false,
          isApproveAction: false,
          isRejectAction: false,
          isAlreadySubmittedAction: true,
          isError: false,
          data: checkData.data.projectData,
        };
      } else if (!checkData.data.isTokenUsed && accessValue) {
        // Check if approved
        stateData = {
          isLoading: false,
          isApproveAction: true,
          isRejectAction: false,
          isAlreadySubmittedAction: false,
          isError: false,
          data: checkData.data.projectData,
        };
      } else if (!checkData.data.isTokenUsed && !accessValue) {
        // if going to reject
        stateData = {
          isLoading: false,
          isApproveAction: false,
          isRejectAction: true,
          isAlreadySubmittedAction: false,
          isError: false,
          data: checkData.data.projectData,
        };
      }
    } else {
      // If there is any error
      stateData = {
        isLoading: false,
        isApproveAction: false,
        isRejectAction: false,
        isAlreadySubmittedAction: false,
        isError: true,
      };
    }

    dispatch(
      setInterviewRequestShowHide(stateData, SET_SHOW_HIDE_INTERVIEW_REQUEST)
    );
  };

export const submitInterviewRequestAsReject =
  (interviewRequestToken, formData, responseMethod) => async (dispatch) => {
    const rejectData = await ProjectApi.submitInterviewRequestAsReject({
      interviewRequestToken,
      userResponse: formData,
      responseMethod,
    });

    let stateData = {};
    if (rejectData?.data?.success) {
      if (rejectData.data.isTokenUsed) {
        // Check already submitted or not
        stateData = {
          isLoading: false,
          isApproveAction: false,
          isRejectAction: false,
          isAlreadySubmittedAction: true,
          isError: false,
          data: rejectData.data.projectData,
        };
      } else {
        // Show rejection success
        stateData = {
          isLoading: false,
          isApproveAction: false,
          isRejectAction: false,
          isRejectionSuccess: true,
          isAlreadySubmittedAction: false,
          isError: false,
          data: rejectData.data.projectData,
        };
      }
    } else {
      // If there is any error
      stateData = {
        isLoading: false,
        isApproveAction: false,
        isRejectAction: false,
        isAlreadySubmittedAction: false,
        isError: true,
      };
    }

    dispatch(
      setInterviewRequestShowHide(stateData, SET_SHOW_HIDE_INTERVIEW_REQUEST)
    );
  };
