import _, { isEmpty, isNumber, throttle } from 'lodash';
import {
  CLEAR_COMPANY,
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANY_LIST,
  UPDATE_FIRST_LEVEL_COMPANY_VALUES,
  UPDATE_COMPANY_STATUS,
  DELETE_COMPANY,
  UPDATE_FILTER_STATUS,
  UPDATE_COMPANY_ADD_MODAL_OPEN,
} from '../actionTypes';
import CompanyApi from '../../api/CompanyApi';
import { singleCompany } from '../reducers/initialState';
import { getCompanyStatus } from '../../helpers/utilityHelper';
import StaticApi from '../../api/staticApi';
import { THROTTLE_SEARCH_HANDLER_WAITING_TIME } from '../../common/constant';

const throttleGetCompaniesList = throttle(
  async (payload) => {
    return CompanyApi.getCompaniesList(payload);
  },
  THROTTLE_SEARCH_HANDLER_WAITING_TIME,
  { leading: true }
);

const getCompanies = (payload, trans) => async (dispatch) => {
  const companies = await throttleGetCompaniesList(payload);
  if (companies?.data?.success) {
    const companyDataObject = companies.data.data;
    // formatting the payload to frontend standards
    if (companyDataObject !== null && companyDataObject !== undefined) {
      Object.keys(companyDataObject).forEach((item, key) => {
        if (isNumber(companyDataObject[key].status)) {
          companyDataObject[key].status = trans(
            `labels.statuses.status_${companyDataObject[key].status}`
          );
        }
        companyDataObject[key].checked = false;
      });
    }

    companies.data.data = companyDataObject;

    dispatch({
      type: GET_COMPANY_LIST,
      payload: companies.data,
    });
  }
};

const getCompany = (payload) => async (dispatch) => {
  if (isEmpty(payload.id)) {
    dispatch({
      type: GET_COMPANY,
      payload: singleCompany.data,
    });
  } else {
    try {
      const companies = await CompanyApi.getCompany({ id: payload.id });
      const data = companies?.data?.data;
      let countryArr = [];

      if (data?.country?.length > 0) {
        await Promise.all(
          data.country.map(async (item) => {
            const country = await StaticApi.getLocationById({
              id: item,
            });

            if (country?.data) {
              countryArr = [...countryArr, country.data];
            }
          })
        );
      }

      data.country = countryArr;

      dispatch({
        type: GET_COMPANY,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_ERROR,
        payload: error?.response?.data || {},
      });
    }
  }
};

const clearCompanyObject = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPANY,
  });
};

const updateFirstLevelCompanyValues = (name, value) => async (dispatch) => {
  dispatch({
    type: UPDATE_FIRST_LEVEL_COMPANY_VALUES,
    payload: { [name]: value },
  });
};

const createCompany = (data) => async (dispatch, getState) => {
  const { companies } = getState();

  if (companies?.data?.data && !_.isEmpty(data)) {
    const newObj = {
      ...data,
      status: getCompanyStatus(data.companyStatus).name,
      checked: false,
    };

    if (companies.data.pagination.currentPage === 1) {
      companies.data.data.unshift(newObj);

      if (companies.data.data.length > companies.data.pagination.pageSize) {
        companies.data.data.pop();
      }
    }

    dispatch({
      type: GET_COMPANY_LIST,
      payload: companies.data,
    });
  }
};

const updateCompany = (data) => async (dispatch, getState) => {
  const { companies } = getState();

  if (companies?.data?.data && !_.isEmpty(data)) {
    const newData = companies.data.data.map((el) => {
      if (el.id === data.id) {
        return {
          ...el,
          companyLogo: data?.companyLogo || el.companyLogo,
          companyName: data.companyName,
          status: getCompanyStatus(data.companyStatus).name,
          companyType: data?.companyType || el.companyType,
        };
      }

      return el;
    });

    companies.data.data = newData;

    dispatch({
      type: GET_COMPANY_LIST,
      payload: companies.data,
    });
  }
};

const updateCompanyStatus = (ids, status) => async (dispatch) => {
  const resp = await CompanyApi.updateCompaniesStatus(ids, status);
  dispatch({
    type: UPDATE_COMPANY_STATUS,
    payload: resp.data,
  });
  return resp;
};

const updateCompanyFilterStatuses = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_FILTER_STATUS,
    payload: data,
  });
};

const deleteCompany = (ids) => async (dispatch) => {
  const resp = await CompanyApi.deleteCompanies(ids);
  dispatch({
    type: DELETE_COMPANY,
    payload: resp.data,
  });
  return resp;
};

const increaseCompanyListCountBy = (count) => async (dispatch, getState) => {
  const { companies } = getState();

  if (
    count > 0 &&
    companies?.data?.pagination?.total &&
    companies?.data?.pagination?.dataCount
  ) {
    companies.data.pagination.total += count;
    companies.data.pagination.dataCount += count;

    dispatch({
      type: GET_COMPANY_LIST,
      payload: companies.data,
    });
  }
};

export const updateCompanyAddModalOpenAction = (open) => async (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_ADD_MODAL_OPEN,
    payload: open,
  });
};

export {
  getCompany,
  updateFirstLevelCompanyValues,
  clearCompanyObject,
  updateCompanyStatus,
  createCompany,
  updateCompany,
  deleteCompany,
  updateCompanyFilterStatuses,
  increaseCompanyListCountBy,
};
export default getCompanies;
