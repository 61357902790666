import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Chip, TextField, Avatar, Autocomplete } from '@mui/material';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import closeIcon from '../../images/close-grey.svg';

const useStyle = makeStyles({
  input: {
    '& .MuiInput-root': {
      minHeight: '44px',
      backgroundColor: '#fff !important',
      borderRadius: '8px',
      padding: '9px !important',
      border: '1px solid #D0D5DD',
    },
  },
  chip: {
    display: 'flex',
    justifyContent: 'left',
    height: 40,
    padding: '5px 10px',
    transition: 'unset',
    backgroundColor: 'inherit',
    borderRadius: 0,

    '&:hover': {
      backgroundColor: '#F9FAFB',
      cursor: 'pointer !important',
    },
  },
});

export default function MultiSelectAsynchronousAutoComplete({
  name,
  // label,
  variant,
  value,
  onChanges,
  api,
  clearableOnBlur = false,
  transformData,
  appendToSearchQuery = {},
  searchQuery = 'search',
  searchPreventOnEmpty = false,
  placeHolder,
  isSpecialCandidate,
}) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(value);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const clearAllOptionData = () => {
    setSearchOptions([]);
    setOptions([]);
  };

  const getOptions = useCallback(
    async (params) => {
      const newParams = {
        ...params,
        ...appendToSearchQuery,
      };

      if (searchPreventOnEmpty && newParams[searchQuery].trim() === '') {
        setOptions([]);
        return;
      }

      if (searchPreventOnEmpty && newParams[searchQuery].length < 2) {
        setOptions([]);
        return;
      }

      const response = await axios({
        ...api,
        params: { ...newParams },
      });

      if (response.data) {
        const newOptionAfterByRemovingAlreadySelected = _.differenceBy(
          response.data,
          selectedValue,
          'id'
        );
        setOptions(newOptionAfterByRemovingAlreadySelected);
        setSearchOptions(response.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, appendToSearchQuery]
  );

  const newOptions = useMemo(() => {
    if (transformData) {
      return transformData(options, value);
    }

    return options;
  }, [transformData, options, value]);

  const typingTimeout = useRef(null);
  const onSearchChange = (e) => {
    clearTimeout(typingTimeout.current);
    const searchText = e.target.value;
    typingTimeout.current = setTimeout(() => {
      getOptions({
        [searchQuery]: searchText,
      });
    }, 200);
  };

  const onInputFocus = () => {
    if (!touched) {
      setTouched(true);
    }

    if (!open) {
      setOpen(true);
    }

    clearAllOptionData();
  };

  const onBlur = () => {
    if (clearableOnBlur === true) {
      setOptions([]);
    }

    clearAllOptionData();
  };

  const onOptionClick = (event, newValue) => {
    const newOptionAfterTheSelection = _.differenceBy(
      searchOptions,
      newValue,
      'id'
    );
    setSelectedValue([...newValue]);
    setOptions(newOptionAfterTheSelection);
    onChanges(name, [...newValue]);

    clearAllOptionData();
  };

  return (
    <Autocomplete
      // classes={{
      //   popper: overrideClasses.autocompletePopper,
      // }}
      className='tags-autocomplete-input'
      open={newOptions.length > 0}
      multiple
      id='add-edit-candidate-tag-input'
      value={selectedValue}
      onChange={onOptionClick}
      options={options}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      // getOptionSelected={(option, value) => option.id === value.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            avatar={
              option.src ? <Avatar alt={option.label} src={option.src} /> : null
            }
            label={option.label}
            {...getTagProps({ index })}
            disabled={
              isSpecialCandidate && option.isSpecialCandidate
                ? true
                : option.disabled
            }
            deleteIcon={<img src={closeIcon} alt='close' />}
          />
        ))
      }
      renderOption={(props, option) => (
        <Chip
          {...props}
          className={classes.chip}
          avatar={
            option.src ? <Avatar alt={option.label} src={option.src} /> : null
          }
          label={option.label}
          size='medium'
        />
      )}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.input}
          variant={variant || 'standard'}
          onChange={onSearchChange}
          onFocus={onInputFocus}
          onBlur={onBlur}
          placeholder={placeHolder}
        />
      )}
    />
  );
}
