import _ from 'lodash';
import * as types from '../actionTypes';
import { integration } from './initialState';

const integrationReducer = (state = integration, action) => {
  switch (action.type) {
    case types.GET_COMPANY_INTEGRATION_LIST_START:
      return {
        ...state,
        companyIntegration: {
          ...state.companyIntegration,
          isLoading: true,
        },
      };
    case types.GET_COMPANY_INTEGRATION_LIST_COMPLETE:
      return {
        ...state,
        companyIntegration: {
          ...state.companyIntegration,
          isLoading: false,
          activeIntegration: action.payload.activeCompanyIntegration,
          availableCompanyIntegration:
            action.payload.availableCompanyIntegration,
        },
      };
    case types.COMPANY_INTEGRATION_MANUAL_SYNC_PROCESS:
      return {
        ...state,
        companyIntegration: {
          ...state.companyIntegration,
          isLoading: false,
          activeIntegration: _.map(
            state.companyIntegration.activeIntegration,
            (eachIntegration) => {
              if (eachIntegration.id === action.payload.id) {
                return {
                  ...eachIntegration,
                  isManualProcessing: action.payload.process,
                };
              }

              return eachIntegration;
            }
          ),
        },
      };

    default:
      return state;
  }
};

export default integrationReducer;
