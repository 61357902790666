import {
  AUTH_USER_SIGNUP_REQUEST,
  AUTH_USER_SIGNUP_SUCCESS,
  AUTH_USER_SIGNUP_ERROR,
} from '../actionTypes';

const initState = {
  isPendingRegister: false,
  isUserRegistered: false,
  registeredUser: {},
  validationError: null,
  message: '',
};

const userRegistrationReducer = (state = initState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case AUTH_USER_SIGNUP_REQUEST: {
      newState.isPendingRegister = true;
      newState.isUserRegistered = false;
      break;
    }
    case AUTH_USER_SIGNUP_SUCCESS: {
      newState.isPendingRegister = false;
      newState.isUserRegistered = action.payload.success;

      if (action.payload.success) {
        newState.registeredUser = action.payload;
      } else {
        newState.message = action.payload.message;
      }
      break;
    }
    case AUTH_USER_SIGNUP_ERROR: {
      newState.isPendingRegister = false;
      newState.validationError = action.error;
      break;
    }
    default:
      break;
  }

  return newState;
};

export default userRegistrationReducer;
