import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from '../redux/store';
import i18n from './i18n';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import { loginFromToken } from '../redux/actions/authorizationActions';
import { registerInterceptors } from '../api/Interceptors';
import { pageOnloadChecker } from '../redux/actions/pageOnLoadingAction';
import CrispHandler from '../components/shared/crisp/CrispHandler';

registerInterceptors();
store.dispatch(loginFromToken(history));
store.dispatch(pageOnloadChecker(history));

const AppContainer = () => {
  return (
    <Suspense fallback=''>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <CrispHandler />
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </ConnectedRouter>
        </Provider>
      </I18nextProvider>
    </Suspense>
  );
};

export default AppContainer;
