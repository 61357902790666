import axios from 'axios';
import { tag } from '../providers/apiFeedProvider';

class TagApi {
  static getTag(params) {
    return axios({
      ...tag.get,
      params,
    });
  }

  static getTagByIds(params) {
    return axios({
      ...tag.getIds,
      params,
    });
  }

  static checkTagIdentifierExist(params) {
    return axios({
      ...tag.checkTagIdentifierExist,
      params,
    });
  }

  static getTagList(params) {
    return axios({
      ...tag.filter,
      params,
    });
  }

  static getTagSearch(params) {
    return axios({
      ...tag.search,
      params,
    });
  }

  static create(data) {
    return axios({
      ...tag.create,
      data,
    });
  }

  static updateTag(guid, data) {
    return axios({
      ...tag.update(guid),
      data,
    });
  }

  static updateTagListStatus(data) {
    return axios({
      ...tag.bulkStatusUpdate,
      data,
    });
  }

  static getSemiActiveTag() {
    return axios({
      ...tag.semiActiveTag,
    });
  }
}

export default TagApi;
