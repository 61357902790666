import { pageOnLoading } from './initialState';
import {
  CHANGE_SHOW_STATE,
  HIDE_ALL_PAGE_LOAD_COMPONENT,
  PAGE_LOAD_CHECK_COMPLETE,
  SET_GLOBAL_URL_DATA,
  SET_SHOW_HIDE_INTERVIEW_REQUEST,
} from '../actionTypes';

const pageOnLoadingReducer = (state = pageOnLoading, action) => {
  switch (action.type) {
    case PAGE_LOAD_CHECK_COMPLETE:
      return {
        ...state,
        isInitialCheckDone: true,
      };
    case SET_GLOBAL_URL_DATA:
      // eslint-disable-next-line no-param-reassign
      state.urlData = action.payload;
      return state;
    case CHANGE_SHOW_STATE:
      return {
        ...state,
        data: {
          [action.pageLoadItem]: {
            ...state.data[action.pageLoadItem],
            ...action.payload,
          },
        },
      };
    case HIDE_ALL_PAGE_LOAD_COMPONENT:
      return {
        ...state,
        data: {
          ...pageOnLoading.data,
        },
      };
    case SET_SHOW_HIDE_INTERVIEW_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          candidateInterviewRequest: {
            ...state.data.candidateInterviewRequest,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default pageOnLoadingReducer;
