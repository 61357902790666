import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOGOUT_SUCCESS,
  SET_LOGIN_REDIRECT,
  USER_AUTHORIZED_CHECKED_SUCCESS,
} from '../actionTypes';

const initState = {
  isPending: false,
  isUserLoggedIn: false,
  loggedUserRole: 'public',
  loggedUserEmail: '',
  loggedUserLandingPage: '',
  accessToken: '',
  refreshToken: '',
  rememberMe: true,
  validationError: null,
  message: '',
};

const userAuthorizationReducer = (state = initState, action) => {
  let newState = { ...state };

  switch (action.type) {
    case LOG_IN_REQUEST: {
      newState.isPending = true;
      newState.isUserLoggedin = false;
      break;
    }
    case LOG_IN_SUCCESS: {
      newState.isPending = false;
      newState.isUserLoggedin = action.payload.success;
      newState.message = action.payload.message;

      if (action.payload.success) {
        newState.accessToken = action.payload.accessToken;
        newState.refreshToken = action.payload.refreshToken;
        newState.loggedUserRole = action.payload.role.name;
        newState.loggedUserEmail = action.payload.email;
        newState.loggedUserLandingPage =
          action.payload.landing_page === undefined
            ? '/'
            : action.payload.landing_page;
      }
      break;
    }
    case LOG_IN_FAILURE: {
      newState.isPending = false;
      newState.rememberMe = action.rememberMe;
      newState.validationError = action.error.response.data.error;
      break;
    }
    case LOGOUT_SUCCESS: {
      newState = {
        isUserLoggedin: false,
        accessToken: '',
        refreshToken: '',
        agencyId: '',
        rememberMe: true,
        loginRedirect: '/',
      };
      break;
    }
    case SET_LOGIN_REDIRECT: {
      newState = {
        loginRedirect: action.payload.redirect,
      };
      break;
    }
    case USER_AUTHORIZED_CHECKED_SUCCESS: {
      newState.isUserLoggedin = action.payload.logged;
      newState.loggedUserLandingPage =
        action.payload.landing_page === undefined
          ? '/'
          : action.payload.landing_page;
      newState.loggedUserRole = action.payload.logged_user_role_name;
      break;
    }
    default:
      break;
  }

  return newState;
};

export default userAuthorizationReducer;
