import dotenv from 'dotenv';

dotenv.config();

const {
  NODE_ENV,
  REACT_APP_ENV,
  REACT_APP_SERVER_URL,
  REACT_APP_SITE_URL,
  PUBLIC_URL,
  REACT_APP_IS_SANDBOX_ACCOUNT,
  REACT_APP_SHOW_PROFILE_INSIGHTS,
  REACT_APP_AMPLITUDE_API_KEY,
  REACT_APP_REACH_US_MAIL,
  REACT_APP_API_CRISP_WEBSITE_ID,
} = process.env;

export {
  NODE_ENV,
  REACT_APP_ENV,
  REACT_APP_SERVER_URL,
  REACT_APP_SITE_URL,
  PUBLIC_URL,
  REACT_APP_IS_SANDBOX_ACCOUNT,
  REACT_APP_SHOW_PROFILE_INSIGHTS,
  REACT_APP_AMPLITUDE_API_KEY,
  REACT_APP_REACH_US_MAIL,
  REACT_APP_API_CRISP_WEBSITE_ID,
};
