import React, { memo } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BootstrapToolTip from '../../../components/shared/tooltips/BootstrapToolTip';
import FigXClose from '../../../assets/icons/figma-pro-2-8/general/FigXClose/FigXClose';
import IconComp from '../../../components/shared/icons/IconComp';

const useStyle = makeStyles(() => {
  return {
    buttonRoot: {
      textTransform: 'capitalize !important',
      color: '#AAAAAA',
      fontWeight: 400,
      fontSize: '14px',

      '& svg': {
        padding: '8px 4px',
        width: '16px',
        height: '16px',
        '&:hover': {
          background: '#fff',
          borderRadius: '4px',
        },
      },
    },
  };
});

const ClearFiltersButton = ({ clearValues }) => {
  const classes = useStyle();

  return (
    <Button
      size='small'
      type='button'
      onClick={clearValues}
      className={classes.buttonRoot}
      disableRipple
    >
      <BootstrapToolTip title='Clear filter'>
        <span>
          {' '}
          <IconComp icon={<FigXClose />} />
        </span>
      </BootstrapToolTip>
    </Button>
  );
};

export default memo(ClearFiltersButton);
