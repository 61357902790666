class AuthorizationData {
  constructor(
    token,
    expiration,
    userName,
    role,
    accountStatus,
    flowStep,
    firstName,
    lastName,
    email,
    companyName,
    isWatchedInfoVideo,
    userID = '',
    authorizedModules = {},
    adminId = undefined,
    lastScheduleData = {}
  ) {
    this.token = token;
    this.expiration = expiration;
    this.userName = userName;
    this.role = role;
    this.accountStatus = accountStatus;
    this.userID = userID;
    this.authorizedModules = authorizedModules;
    this.adminId = adminId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.companyName = companyName;
    this.isWatchedInfoVideo = isWatchedInfoVideo;
    this.lastScheduleData = lastScheduleData;

    if (flowStep) {
      this.flowStep = flowStep;
    }
  }
}

export default AuthorizationData;
