import React from 'react';
import Carousel from 'react-material-ui-carousel';

const CarouselSlider = ({ children }) => {
  return (
    <Carousel
      indicators={false}
      //   animation='slide'
      interval={5000}
      duration={0}
      //   cycleNavigation={false}
      navButtonsAlwaysInvisible
    >
      {children}
    </Carousel>
  );
};

export default CarouselSlider;
