import queryString from 'query-string';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';
import {
  CHANGE_FILTER_VALUE,
  SEARCH_START,
  INITIATE_FILTERS,
  SEARCH_SUCCESS,
  OPEN_ADD_PROJECT_POPUP,
  CLOSE_ADD_PROJECT_POPUP,
  CLOSE_ADD_PROJECT_POPUP_SUCCESS,
  START_PROJECT_ADD,
  SUCCESS_PROJECT_ADD,
  START_RECRUITMENT_STATUS_CHANGE,
  RECRUITMENT_STATUS_CHANGE_SUCCESS,
  CLOSE_ADD_TO_ANOTHER_PROJECT,
  START_ADD_TO_ANOTHER_PROJECT,
  SUCCESS_ADD_TO_ANOTHER_PROJECT,
  CLOSE_HIDE_PROFILE_CONFIRMATION,
  OPEN_JOB_DESCRIPTION_POPUP,
  CLOSE_JOB_DESCRIPTION_POPUP,
  START_SEND_JOB_DESCRIPTION,
  SUCCESS_SEND_JOB_DESCRIPTION,
  SEARCH_BLOCKED,
  START_PROFILE_PROJECT_LIST_FETCH,
  CLOSE_PROFILE_PROJECT_LIST_FETCH,
  SUCCESS_PROFILE_PROJECT_LIST_FETCH,
  SLIDING_FILTER_CHANGE_MIN_MAX,
  REMOVE_CANDIDATE_PROFILE_FROM_SEARCH,
  BULK_SELECT_PROFILE,
  START_RECRUITMENT_STATUS_BULK_CHANGE,
  RECRUITMENT_STATUS_BULK_CHANGE_SUCCESS,
  START_SEND_INTERVIEW_REQUEST,
  SUCCESS_SEND_INTERVIEW_REQUEST,
  PROJECT_CANDIDATE_SEARCH_START,
  PROJECT_CANDIDATE_SEARCH_STOP,
  SHOW_SINGLE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  CLOSE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  SHOW_SKIP_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  SHOW_BULK_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  REMOVE_CANDIDATE_PROFILE,
  SUCCESS_FETCH_PROJECT_SHARED_USER_INFO,
  SUCCESS_FETCH_PROJECT_SHARED_USER_LIST,
  START_FETCH_PROJECT_SHARED_USER_LIST,
  SET_PROJECT_TAB,
  UPDATE_LAST_SCHEDULE_DATE,
} from '../actionTypes';
import {
  urlParseOptions,
  toUrlParam,
  cleanSearchUrl,
  getUrlParam,
  projectSaveUrl,
  formatUrl,
} from '../../helpers/urlHelper';
import SearchApi from '../../api/SearchApi';
import ProjectApi from '../../api/ProjectApi';
import { recruitmentStages } from '../../helpers/utilityHelper';
import CandidateApi from '../../api/CandidateApi';
import {
  showSuccessSnackbar,
  showInfoSnackbar,
  showErrorSnackbar,
} from './snackbarActions';
import {
  URL_DISABLED_FILTER_FLAG,
  PROJECT_ITEM_STATE_CONST,
  projectCandidateStatus,
} from '../../common/constant';
import { logAmplitudeEvent } from '../../helpers/amplitudeHelper';
import { AMPLITUDE_EVENT } from '../../config/AmplitudeConfig';
import AuthorizationService from '../../authorization/AuthorizationService';
import { UserTypes } from '../../common/roles';
import { successCandidateFetch } from './candidateFlowActions';
import { projectDetail } from '../../common/paths';
import { REACT_APP_IS_SANDBOX_ACCOUNT } from '../../config/envConfig';

const searchFilterChange = (payload) => {
  return {
    type: CHANGE_FILTER_VALUE,
    payload,
    pushToUrl: true,
  };
};

const onProjectCandidateSearchStart = () => {
  return {
    type: PROJECT_CANDIDATE_SEARCH_START,
  };
};

const onProjectCandidateSearchStop = () => {
  return {
    type: PROJECT_CANDIDATE_SEARCH_STOP,
  };
};

const searchStarts = (payload) => ({
  type: SEARCH_START,
  payload,
});

const setProjectTab = (payload) => ({
  type: SET_PROJECT_TAB,
  payload,
});

const searchBlocked = (payload) => ({
  type: SEARCH_BLOCKED,
  payload,
});

const searchSuccess = (payload) => ({
  type: SEARCH_SUCCESS,
  payload,
});

const logAmplitudeEventData = (
  json,
  projectId,
  locationParams,
  languagesParams,
  badgeParams
) => {
  const { role } = AuthorizationService.getAuthorizationData();

  if (role === UserTypes.Company || role === UserTypes.RaasCompany) {
    if (REACT_APP_IS_SANDBOX_ACCOUNT === 'false') {
      logAmplitudeEvent(AMPLITUDE_EVENT.COMPANY_SEARCH_EXECUTED, {
        keyWord: json.ts,
        companyName: json.cn,
        jobTitle: json.jt,
        salary: {
          min: json.sf,
          max: json.st,
        },
        noticePeriod: json.np,
        experience: { min: json.ef, max: json.et },
        locations: locationParams,
        languages: languagesParams,
        skills: json.s,
        industry: json.i,
        pagination: {
          page: json.p,
          perPage: json.pp,
        },
        projectId,
        isAlreadyShortListed: json.ias,
        isPopular: json.isp,
        isHidden: json.ih,
        isFavorite: json.isf,
        typeOfEmployment: json.te,
        tag: badgeParams,
      });
    }
  }
};

const firstProfileShouldBeAlwaysSelected = (dispatch, firstProfile) => {
  let fullName = '';
  let lastUpdated = null;

  const isPublicCandidate =
    firstProfile.visibility === true || firstProfile.visibility === 'true';

  const adminAnonimas =
    !isPublicCandidate && (firstProfile.firstName || firstProfile.lastName);

  if (isPublicCandidate || adminAnonimas) {
    fullName = firstProfile.firstName
      ? `${firstProfile.firstName} ${firstProfile.lastName}`
      : '';
  }

  if (
    firstProfile.lastRecruitmentUpdatedAt &&
    firstProfile.lastRecruitmentUpdatedAt !== '' &&
    firstProfile.lastRecruitmentUpdatedAt !== null &&
    typeof firstProfile.lastRecruitmentUpdatedAt !== 'object'
  ) {
    lastUpdated = new Date(firstProfile.lastRecruitmentUpdatedAt);
  }

  const profile = firstProfile;
  profile.fullName = fullName;
  profile.lastUpdated = lastUpdated;

  dispatch(successCandidateFetch(profile));
};

const startSearch =
  (search, type, firstRequest = false, searchResultRef = null) =>
  async (dispatch, getState) => {
    const queryObj = queryString.parse(search, urlParseOptions);
    const candidateGuid = queryObj?.cg || null;

    const {
      searchFilter: {
        filters: searchFilters,
        meta: { project, projectCandidateSearchStart },
      },
    } = getState();

    let tab = searchFilters.tab.value;

    if (candidateGuid) {
      const data = await ProjectApi.getProjectTabByCandidate({
        project_guid: project,
        candidate_guid: candidateGuid,
      });
      tab = data?.data?.tab || tab;
      if (tab !== searchFilters.tab.value) {
        await dispatch(setProjectTab(tab));
      }
    }

    if (firstRequest && tab === 0) {
      return;
    }

    let json = {};
    const locationParams = searchFilters.locations.value;
    const languagesParams = searchFilters.languages.value;
    const badges = searchFilters.badges.value;

    Object.keys(searchFilters).forEach((filter) => {
      const urlParam = toUrlParam(filter);
      const option = searchFilters[filter];

      const values = option.toJson(queryObj, urlParam, filter);
      json = {
        ...json,
        ...values,
      };
    });

    Object.keys(json).forEach((filter) => {
      if (
        Array.isArray(json[filter]) &&
        json[filter].length > 0 &&
        json[filter][0] === URL_DISABLED_FILTER_FLAG
      ) {
        delete json[filter];
      }
    });

    if (!project && type === 'search') {
      const cleanedObj = cleanSearchUrl(queryObj);
      if (Object.keys(cleanedObj).length === 0) {
        dispatch(searchBlocked(tab));
        return;
      }
      dispatch(searchStarts(tab));
      const data = await SearchApi.search(json);
      logAmplitudeEventData(
        json,
        null,
        locationParams,
        languagesParams,
        badges
      );

      dispatch(
        searchSuccess({
          data: data.data.docs,
          count: data.data.totalResults,
          tab,
          lastRequestParams: json,
        })
      );

      if (data.data.docs.length > 0) {
        const candidatesArr = data.data.docs.reduce((acc, candidate) => {
          acc.push(candidate.guid);
          return acc;
        }, []);

        CandidateApi.searchAppearance({
          candidates: candidatesArr,
          projectId: '',
        });
      }

      if (data.data.docs.length > 0) {
        firstProfileShouldBeAlwaysSelected(dispatch, data.data.docs[0]);
      }
    } else {
      json = {
        ...json,
        project_id: project,
        tab,
      };

      if (tab === 0 && projectCandidateSearchStart) {
        logAmplitudeEventData(
          json,
          json.project_id,
          locationParams,
          languagesParams,
          badges
        );
      }
      dispatch(onProjectCandidateSearchStop());

      dispatch(searchStarts(tab));

      const data = await ProjectApi.search(json, tab);

      const newData = {
        data: data.data.docs,
        tab,
        lastRequestParams: json,
      };
      const tabObject = recruitmentStages.find((stage) => stage.id === tab);

      if (tabObject?.tag === 'open') {
        newData.count = data.data.totalResults;
      }

      if (newData?.data.length > 0 && tab === 0) {
        const candidatesArr = newData.data.reduce((acc, candidate) => {
          acc.push(candidate.guid);
          return acc;
        }, []);

        CandidateApi.searchAppearance({
          candidates: candidatesArr,
          projectId: project,
        });
      }

      dispatch(searchSuccess(newData));

      if (newData.data && newData.data.length > 0) {
        let findCan = {};
        if (candidateGuid) {
          findCan = newData.data.find(
            (candidate) => candidate?.guid === candidateGuid
          );
        }
        if (findCan?.guid) {
          firstProfileShouldBeAlwaysSelected(dispatch, findCan);
        } else {
          firstProfileShouldBeAlwaysSelected(dispatch, newData.data[0]);
        }
      } else {
        dispatch({
          type: REMOVE_CANDIDATE_PROFILE,
          payload: {},
        });
      }
    }

    if (searchResultRef?.current) {
      searchResultRef.current.scrollTo(0, 0);
    }
  };

const updateAsNewCandidateSeen =
  (projectId, candidateId, newCandidate, isAlreadyClicked, tab) => async () => {
    if (projectId && newCandidate && !isAlreadyClicked) {
      if ([0, PROJECT_ITEM_STATE_CONST.SHORTLIST].includes(tab)) {
        // Suggest tab
        ProjectApi.setNewCandidateSeen({
          candidates: [candidateId],
          project_id: projectId,
          tab,
        });
      }
    }
  };

const initiateFilters = () => async (dispatch, getState) => {
  const { searchFilter: searchFilters } = getState();

  Object.keys(searchFilters).forEach((filter) => {
    dispatch({
      type: INITIATE_FILTERS,
      payload: {
        data: [],
        field: filter,
      },
    });
  });
};

const onAddProjectClick = (origin, profile) => {
  return {
    type: OPEN_ADD_PROJECT_POPUP,
    payload: {
      origin,
      profile: isUndefined(profile) ? [] : [profile],
    },
  };
};

const onCloseProjectClick = () => {
  return {
    type: CLOSE_ADD_PROJECT_POPUP,
  };
};

const onCloseProjectSuccess = () => {
  return {
    type: CLOSE_ADD_PROJECT_POPUP_SUCCESS,
  };
};

const onProjectSave =
  (formData = {}) =>
  async (dispatch, getState) => {
    const {
      title,
      jobTitle,
      countries,
      jd,
      companyId,
      // eslint-disable-next-line camelcase
      is_admin_project,
      owner,
      advocate,
      projectType,
      requireSponsorVisa,
      workingMode,
      noticePeriod,
      requireLanguages,
      isRelocate,
      comments,
      currentUrl,
    } = formData;

    dispatch({
      type: START_PROJECT_ADD,
      payload: {
        origin,
      },
    });

    const queryObj = queryString.parse(
      formatUrl(window.location.search),
      urlParseOptions
    );

    const {
      searchFilter: {
        filters: searchFilters,
        meta: {
          project,
          bulkSelect: { profiles },
        },
      },
    } = getState();

    const removeParams = [
      searchFilters.pagination.paramPage,
      searchFilters.pagination.paramPerPage,
      toUrlParam('sorting'),
    ];

    const newQuery = projectSaveUrl(queryObj, removeParams);

    const url = queryString.stringify(newQuery, urlParseOptions);
    let json = {
      title,
      jobTitle,
      countries,
      current_url: currentUrl || url,
      candidates: [],
      // eslint-disable-next-line camelcase
      is_admin_project,
      projectType,
      requireSponsorVisa,
      workingMode,
      noticePeriod,
      requireLanguages,
      isRelocate,
      comments,
    };

    if (companyId !== null && companyId) {
      json = {
        ...json,
        [toUrlParam('companyId')]: companyId,
      };
    }

    if (owner) {
      json = {
        ...json,
        [toUrlParam('owner')]: owner,
      };
    }

    if (advocate) {
      json = {
        ...json,
        [toUrlParam('advocate')]: advocate,
      };
    }

    const toBeBulkUpdate = profiles[searchFilters.tab.value].profiles;
    if (Array.isArray(toBeBulkUpdate) && toBeBulkUpdate.length > 0) {
      const shortList = recruitmentStages.find(
        (stage) => stage.tag === 'shortlist'
      );
      const bulkUpdateCandidates = toBeBulkUpdate.map((profile) => ({
        id: profile,
        // All candidates will be add to shortlist on saving with candidate selection
        status: shortList.id,
      }));

      json = {
        ...json,
        candidates: bulkUpdateCandidates,
      };
    }

    Object.keys(searchFilters).forEach((filter) => {
      const urlParam = toUrlParam(filter);
      const option = searchFilters[filter];
      const values = option.toJson(queryObj, urlParam, filter, false);
      json = {
        ...json,
        ...values,
      };
    });

    // Do some error handling..
    if (!isUndefined(title) && !project) {
      // create project
      try {
        const newProject = await ProjectApi.createProject(json);

        const pathname = generatePath(projectDetail, {
          id: newProject.data.guid,
        });

        let redirectSearch = currentUrl || url;
        if (Array.isArray(json.candidates) && json.candidates.length > 0) {
          const searchParams = queryString.parse(
            redirectSearch,
            urlParseOptions
          );
          const shortList = recruitmentStages.find(
            (stage) => stage.tag === 'shortlist'
          );

          searchParams.tab = shortList.id;
          redirectSearch = queryString.stringify(searchParams, urlParseOptions);
        }

        const { role } = AuthorizationService.getAuthorizationData();
        if (role === UserTypes.Company || role === UserTypes.RaasCompany) {
          if (!REACT_APP_IS_SANDBOX_ACCOUNT) {
            logAmplitudeEvent(AMPLITUDE_EVENT.COMPANY_PROJECT_CREATED, {
              projectId: `Project:${newProject.data.guid}`,
            });
          }
        }

        if (newProject && jd) {
          const newFormData = new FormData();
          newFormData.append('jd_file', jd);
          newFormData.append('project_id', newProject.data.guid);

          ProjectApi.addJobDescription(newFormData)
            .then(() => {
              dispatch(
                push({
                  pathname,
                  search: redirectSearch,
                })
              );

              dispatch(onCloseProjectClick());
            })
            .catch(() => {
              dispatch(
                push({
                  pathname,
                  search: redirectSearch,
                })
              );

              dispatch(onCloseProjectClick());
            });
        } else {
          dispatch(
            push({
              pathname,
              search: redirectSearch,
            })
          );
          dispatch(onCloseProjectClick());
        }
      } catch (error) {
        throw new Error(error);
      }
    } else {
      json = {
        ...json,
        [toUrlParam('projectId')]: project,
      };

      await ProjectApi.updateProjectBasedOnFilters(json);
    }

    dispatch({
      type: SUCCESS_PROJECT_ADD,
    });
  };

const logAmplitudeStatusChangeData = (from, to, projectId) => {
  const { role } = AuthorizationService.getAuthorizationData();
  if (role === UserTypes.Company || role === UserTypes.RaasCompany) {
    if (!REACT_APP_IS_SANDBOX_ACCOUNT) {
      logAmplitudeEvent(AMPLITUDE_EVENT.COMPANY_MOVED_TALENT, {
        projectId: `Project:${projectId}`,
        stage: {
          from: projectCandidateStatus[from],
          to: projectCandidateStatus[to],
        },
      });
    }
  }
};

const setRecruitmentStage =
  (origin, profileId, from, to, formData, scheduleFormData) =>
  async (dispatch, getState) => {
    dispatch({
      type: START_RECRUITMENT_STATUS_CHANGE,
      payload: {
        origin,
        profileId,
        from,
        to,
      },
    });

    const {
      meta: { project },
      filters: {
        tab: { value: activeTab },
      },
    } = getState().searchFilter;

    const result = await CandidateApi.changeRecruitmentStatus({
      [getUrlParam('candidateId')]: profileId,
      [getUrlParam('projectId')]: project,
      [getUrlParam('recruitmentStatus')]: to,
      rejectFormData: formData,
      scheduleFormData,
    });

    logAmplitudeStatusChangeData(from, to, project);

    dispatch({
      type: RECRUITMENT_STATUS_CHANGE_SUCCESS,
      payload: {
        origin,
        profileId,
        from,
        to,
        tab: activeTab,
        projectRecruitmentStats: result.data.stats,
      },
      toast: {
        msgNamespace: 'messages',
        msgKey: 'successMessage',
        show: showSuccessSnackbar,
      },
    });

    dispatch({
      type: REMOVE_CANDIDATE_PROFILE,
      payload: {},
    });

    if (scheduleFormData) {
      dispatch({
        type: UPDATE_LAST_SCHEDULE_DATE,
        payload: {
          lastScheduleData: scheduleFormData,
        },
      });
    }
  };

const setRecruitmentStageBulk = (from, to) => async (dispatch, getState) => {
  dispatch({
    type: START_RECRUITMENT_STATUS_BULK_CHANGE,
    payload: to,
  });

  const {
    meta: {
      project,
      bulkSelect: { profiles },
    },
  } = getState().searchFilter;

  const result = await CandidateApi.changeRecruitmentStatus({
    [getUrlParam('candidateId')]: profiles[from].profiles,
    [getUrlParam('projectId')]: project,
    [getUrlParam('recruitmentStatus')]: to,
  });

  logAmplitudeStatusChangeData(from, to, project);

  dispatch({
    type: RECRUITMENT_STATUS_BULK_CHANGE_SUCCESS,
    payload: {
      from,
      to,
      projectRecruitmentStats: result.data.stats,
    },
    toast: {
      msgNamespace: 'messages',
      msgKey: 'successMessage',
      show: showSuccessSnackbar,
    },
  });
};

const candidateProfileOptionChange = (option) => async (dispatch, getState) => {
  const { searchFilter } = getState();

  if (option.prepareData) {
    const data = option.prepareData(option);
    (async () => {
      await setRecruitmentStage(...data)(dispatch, getState);
    })();
  } else {
    option.trigger(dispatch, option, searchFilter);
  }
};

const closeAddToAnotherProject = () => ({
  type: CLOSE_ADD_TO_ANOTHER_PROJECT,
});

const closeHideProfilePopup = () => ({
  type: CLOSE_HIDE_PROFILE_CONFIRMATION,
});

const doAddToAnotherProject = (formData) => async (dispatch) => {
  dispatch({
    type: START_ADD_TO_ANOTHER_PROJECT,
  });

  const newFormData = {
    ...Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [toUrlParam(key), value])
    ),
  };

  const response = await CandidateApi.addToAnotherProject(newFormData).catch(
    () => {
      return {
        data: {
          message: 'project.candidate.add.failed',
        },
      };
    }
  );

  let dispatchProps = {
    type: SUCCESS_ADD_TO_ANOTHER_PROJECT,
    payload: {
      bulk: formData.bulk,
    },
    toast: {
      msgNamespace: 'messages',
      msgKey: 'successMessage',
      show: showSuccessSnackbar,
    },
  };

  if (!response.data.success) {
    if (response.data.message === 'project.candidate.add.exists') {
      dispatchProps = {
        ...dispatchProps,
        toast: {
          ...dispatchProps.toast,
          msgKey: 'addToAnotherProjectErrorAlreadyExists',
          show: showInfoSnackbar,
        },
      };
    } else if (response.data.message === 'project.candidate.add.failed') {
      dispatchProps = {
        ...dispatchProps,
        toast: {
          ...dispatchProps.toast,
          msgKey: 'addToAnotherProjectError',
          show: showErrorSnackbar,
        },
      };
    }
  }

  dispatch(dispatchProps);
};

const closeAnonymousJobDescription = () => {
  return {
    type: CLOSE_JOB_DESCRIPTION_POPUP,
  };
};

const sendInterviewRequestAction =
  (profileId, formData) => async (dispatch, getState) => {
    const {
      searchFilter: {
        meta: { project },
        filters: {
          tab: { value },
        },
      },
    } = getState();

    dispatch({
      type: START_SEND_INTERVIEW_REQUEST,
      payload: {
        profileId,
        tab: value,
      },
    });

    const newFormData = new FormData();
    newFormData.append(getUrlParam('jdText'), formData.description);
    newFormData.append(getUrlParam('jdFile'), formData.jd);
    newFormData.append(getUrlParam('projectId'), project);
    newFormData.append(getUrlParam('candidateId'), profileId);

    await CandidateApi.sendJobInterviewRequest(newFormData);

    dispatch({
      type: SUCCESS_SEND_INTERVIEW_REQUEST,
      payload: {
        profileId,
        tab: value,
      },
      toast: {
        msgNamespace: 'messages',
        msgKey: 'sendInterviewRequestSuccess',
        show: showSuccessSnackbar,
      },
    });
  };

const sendInterviewRequest = (profileId) => async (dispatch, getState) => {
  const {
    singleProject: { data: projectObj },
  } = getState();

  if (isEmpty(projectObj.jobDescription)) {
    dispatch({
      type: OPEN_JOB_DESCRIPTION_POPUP,
      payload: {
        profileId,
        requestAction: 'request_interview',
      },
    });

    return;
  }

  (async () => {
    await sendInterviewRequestAction(profileId, {})(dispatch, getState);
  })();
};

const sendJobDescription =
  (profileId, formData) => async (dispatch, getState) => {
    const {
      searchFilter: {
        meta: {
          project,
          jobDescription: { requestAction },
        },
        filters: {
          tab: { value },
        },
      },
    } = getState();

    if (requestAction === 'request_interview') {
      (async () => {
        await sendInterviewRequestAction(profileId, formData)(
          dispatch,
          getState
        );
      })();
      return;
    }

    dispatch({
      type: START_SEND_JOB_DESCRIPTION,
      payload: {
        profileId,
        tab: value,
      },
    });

    const newFormData = new FormData();
    newFormData.append(getUrlParam('jdText'), formData.description);
    newFormData.append(getUrlParam('jdFile'), formData.jd);
    newFormData.append(getUrlParam('projectId'), project);
    newFormData.append(getUrlParam('candidateId'), profileId);

    await CandidateApi.sendJobDescription(newFormData);

    dispatch({
      type: SUCCESS_SEND_JOB_DESCRIPTION,
      payload: {
        profileId,
        tab: value,
      },
      toast: {
        msgNamespace: 'messages',
        msgKey: 'successMessage',
        show: showSuccessSnackbar,
      },
    });
  };

const openAnonymousJobDescription =
  (profileId) => async (dispatch, getState) => {
    const {
      singleProject: { data: projectObj },
    } = getState();
    if (isEmpty(projectObj.jobDescription)) {
      dispatch({
        type: OPEN_JOB_DESCRIPTION_POPUP,
        payload: {
          profileId,
          requestAction: 'request_public',
        },
      });

      return;
    }

    (async () => {
      await sendJobDescription(profileId, {})(dispatch, getState);
    })();
  };

const openProjectsOfProfile = (profileId) => async (dispatch) => {
  dispatch({
    type: START_PROFILE_PROJECT_LIST_FETCH,
    payload: {
      profile: profileId,
    },
  });

  const response = await CandidateApi.getProjectList({
    [getUrlParam('candidateId')]: profileId,
  });

  dispatch({
    type: SUCCESS_PROFILE_PROJECT_LIST_FETCH,
    payload: {
      data: response.data,
    },
  });
};

const closeProjectsOfProfile = () => {
  return {
    type: CLOSE_PROFILE_PROJECT_LIST_FETCH,
  };
};

const changeSliderMinMax = (option, field, newLimits) => {
  return {
    type: SLIDING_FILTER_CHANGE_MIN_MAX,
    payload: {
      option,
      limits: newLimits,
      field,
    },
  };
};

const removeCardFromSearch = (profile) => {
  return {
    type: REMOVE_CANDIDATE_PROFILE_FROM_SEARCH,
    payload: profile,
  };
};

const onBulkSelectProfile = (profile) => {
  return {
    type: BULK_SELECT_PROFILE,
    payload: profile,
  };
};

const showSingleCandidateAnonymousRevealConfirmAction = (data) => {
  return {
    type: SHOW_SINGLE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
    payload: data,
  };
};

const showSkipCandidateAnonymousRevealConfirmAction = (data) => {
  return {
    type: SHOW_SKIP_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
    payload: data,
  };
};

const showBulkCandidateAnonymousRevealConfirmAction = (data) => {
  return {
    type: SHOW_BULK_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
    payload: data,
  };
};

const closeCandidateAnonymousRevealConfirmAction = () => {
  return {
    type: CLOSE_CANDIDATE_ANONYMOUS_REVEAL_CONFIRM,
  };
};

export const onGetProjectAccessUserInfo = (projectId) => async (dispatch) => {
  const res = await ProjectApi.getProjectAccessUserInfo(projectId);

  if (res?.data.success) {
    dispatch({
      type: SUCCESS_FETCH_PROJECT_SHARED_USER_INFO,
      payload: {
        moreCount: res?.data?.moreCount,
        totalCount: res?.data?.totalCount,
        users: res?.data?.users,
      },
    });
  }
};

export const onGetSharedUserList = (projectId) => async (dispatch) => {
  dispatch({
    type: START_FETCH_PROJECT_SHARED_USER_LIST,
  });

  const res = await ProjectApi.getProjectAccessUserList(projectId);

  if (res?.data?.success) {
    dispatch({
      type: SUCCESS_FETCH_PROJECT_SHARED_USER_LIST,
      payload: res?.data?.data,
    });
  }
};

export default searchFilterChange;

export {
  startSearch,
  onProjectCandidateSearchStart,
  onProjectCandidateSearchStop,
  initiateFilters,
  onAddProjectClick,
  onCloseProjectClick,
  onCloseProjectSuccess,
  onProjectSave,
  setRecruitmentStage,
  candidateProfileOptionChange,
  closeAddToAnotherProject,
  doAddToAnotherProject,
  closeHideProfilePopup,
  openAnonymousJobDescription,
  closeAnonymousJobDescription,
  sendJobDescription,
  openProjectsOfProfile,
  closeProjectsOfProfile,
  changeSliderMinMax,
  removeCardFromSearch,
  onBulkSelectProfile,
  setRecruitmentStageBulk,
  sendInterviewRequest,
  showSingleCandidateAnonymousRevealConfirmAction,
  showSkipCandidateAnonymousRevealConfirmAction,
  showBulkCandidateAnonymousRevealConfirmAction,
  closeCandidateAnonymousRevealConfirmAction,
  updateAsNewCandidateSeen,
};
