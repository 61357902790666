import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import CommonModal from '../../../shared/modals/CommonModal';
import IconComp from '../../../shared/icons/IconComp';
import AsyncAutoCompleteWithIconAndLabel from '../../../shared/inputs/AsyncAutoCompleteWithIconAndLabel';
import { RAAS_COMPANY_LIST_SEARCH_API } from '../../../../helpers/urlHelper';
import FigSearchSm from '../../../../assets/icons/figma-pro-2-8/general/FigSearchSm/FigSearchSm';
import {
  filterOptionsCompany,
  renderOptionCompany,
} from '../../../shared/inputs/async-auto-complete/AsyncAutoOptions';
import InputFieldWithIconLabel from '../../../shared/inputs/InputFieldWithIconLabel';
import YearMonthPickerWithLabel from '../../../shared/inputs/DatePicker/YearMonthPickerWithLabel';
import FigCreditCardPlus from '../../../../assets/icons/figma-pro-2-8/financeAndECommerce/FigCreditCardPlus/FigCreditCardPlus';
import FigPlus from '../../../../assets/icons/figma-pro-2-8/general/FigPlus/FigPlus';
import { showSuccessSnackbar } from '../../../../redux/actions/snackbarActions';
import { GUID_TITLES, PAGE_SIZES } from '../../../../common/constant';
import CreditApi from '../../../../api/CreditApi';
import { getCreditLogList } from '../../../../redux/actions/creditLogActions';
import { creditLog } from '../../../../common/paths';
import { getActiveCreditList } from '../../../../redux/actions/activeCreditsActions';
import FigSave01 from '../../../../assets/icons/figma-pro-2-8/general/FigSave01/FigSave01';

const maxExpiryDate = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
);

const AddUpdateCreditModal = ({
  creditEditData,
  showAddCreditModal,
  closeModal,
  showMessage,
  loadCreditLogList,
  getActiveCreditListFunc,
  pathname,
}) => {
  const [companyData, setCompanyData] = useState(null);
  const [form, setForm] = useState({
    companyGuid: '',
    noOfCredits: 1,
    expiryDate: maxExpiryDate,
    companyGuidErr: '',
    noOfCreditsErr: '',
  });
  const [loading, setLoading] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [tCommon] = useTranslation('common');
  const [tInput] = useTranslation('input');
  const [tCredit] = useTranslation('credit');
  const [tMessage] = useTranslation('messages');

  useEffect(() => {
    if (creditEditData) {
      setForm({
        ...form,
        noOfCredits: creditEditData.noOfCredits,
        expiryDate: creditEditData.expiryDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditEditData]);

  const handleChanges = useCallback(
    (name, value) => {
      if (name === 'expiryDate') {
        setForm({
          ...form,
          [name]: moment.utc(value).format(),
        });
      } else {
        setForm({
          ...form,
          [name]: value,
          [`${name}Err`]: '',
        });
      }
    },
    [form]
  );

  const onChangeAsync = useCallback(
    (name, value) => {
      setForm({
        ...form,
        [name]: value?.id,
        [`${name}Err`]: '',
      });

      if (name === 'companyGuid') {
        setCompanyData(value);
      }
    },
    [form]
  );

  const validate = useCallback(
    (isCABCheck = false) => {
      let companyGuidErr = '';
      let noOfCreditsErr = '';

      if (!creditEditData && !form.companyGuid) {
        companyGuidErr = tInput('company.errors.required');
      }

      if (!form.noOfCredits) {
        noOfCreditsErr = tInput('noOfCredits.errors.required');
      } else if (Number(form.noOfCredits) === 0) {
        noOfCreditsErr = tInput('noOfCredits.errors.zeroCredits');
      } else if (!_.isInteger(Number(form.noOfCredits))) {
        noOfCreditsErr = tInput('noOfCredits.errors.NotWholeNumber');
      }

      if (companyGuidErr || noOfCreditsErr) {
        if (!isCABCheck) {
          setForm({
            ...form,
            companyGuidErr,
            noOfCreditsErr,
          });
        }

        return false;
      }

      return true;
    },
    [form, creditEditData, tInput]
  );

  const handleAddUpdateCredit = async () => {
    if (!validate()) return;

    setLoading(true);

    if (creditEditData) {
      CreditApi.updateCredit(creditEditData?.creditId, {
        companyId: creditEditData?.companyGuid,
        creditDataId: creditEditData?.creditDataId,
        noOfCredits: Number(form.noOfCredits),
        expiryDate: moment(form.expiryDate).format('YYYY-MM-DD'),
      })
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            closeModal();
            showMessage(
              tCredit('modals.updateCredits.successMessage'),
              'success'
            );
            getActiveCreditListFunc({
              page_no: 1,
              page_size: PAGE_SIZES.TWENTY_FIVE,
              order_by: 'la',
              s: null,
              ts: null,
            });
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);

          if (err?.response?.data?.msg) {
            showMessage(tCredit(err.response.data.msg), 'error');
          } else {
            showMessage(tMessage('somethingWentWrong'), 'error');
          }
        });
    } else {
      CreditApi.addCredit({
        companyId: form.companyGuid.split(GUID_TITLES.COMPANY)[1],
        noOfCredits: Number(form.noOfCredits),
        expiryDate: moment(form.expiryDate).format('YYYY-MM-DD'),
      })
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            closeModal();
            showMessage(tCredit('modals.addCredits.successMessage'), 'success');
            if (pathname === creditLog) {
              loadCreditLogList({
                page_no: 1,
                page_size: PAGE_SIZES.TWENTY_FIVE,
                order_by: 'ca',
                s: null,
                ts: null,
                companyId: null,
              });
            } else {
              getActiveCreditListFunc({
                page_no: 1,
                page_size: PAGE_SIZES.TWENTY_FIVE,
                order_by: 'la',
                s: null,
                ts: null,
              });
            }
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);

          if (err?.response?.data?.msg) {
            showMessage(tCredit(err.response.data.msg), 'error');
          } else {
            showMessage(tMessage('somethingWentWrong'), 'error');
          }
        });
    }
  };

  const handleDeleteCredit = () => {
    setLoadingCancel(true);

    CreditApi.deleteCredit(
      creditEditData?.creditId,
      creditEditData?.creditDataId
    )
      .then(() => {
        setTimeout(() => {
          setLoadingCancel(false);
          closeModal();
          showMessage(
            tCredit('modals.deleteCredits.successMessage'),
            'success'
          );
          getActiveCreditListFunc({
            page_no: 1,
            page_size: PAGE_SIZES.TWENTY_FIVE,
            order_by: 'la',
            s: null,
            ts: null,
          });
        }, 3000);
      })
      .catch(() => {
        setLoadingCancel(false);
        showMessage(tMessage('somethingWentWrong'), 'error');
      });
  };

  return (
    <CommonModal
      title={
        creditEditData
          ? tCredit('modals.updateCredits.title')
          : tCredit('modals.addCredits.title')
      }
      desc={
        creditEditData
          ? tCredit('modals.updateCredits.description')
          : tCredit('modals.addCredits.description')
      }
      open={showAddCreditModal}
      onConfirm={handleAddUpdateCredit}
      onCancel={closeModal}
      cancelBtnLabel={tCommon('labels.cancel')}
      confirmBtnLabel={
        creditEditData ? tCommon('labels.save') : tCommon('labels.add')
      }
      icon={<IconComp icon={<FigCreditCardPlus />} />}
      confirmIcon={
        creditEditData ? (
          <IconComp icon={<FigSave01 />} />
        ) : (
          <IconComp icon={<FigPlus />} />
        )
      }
      loading={loading}
      id='add-credit-modal'
    >
      {!creditEditData && (
        <AsyncAutoCompleteWithIconAndLabel
          autoFocus
          queryName='label'
          fieldName='companyGuid'
          val={companyData}
          label={tInput('company.label')}
          placeholder={tInput('company.placeholder')}
          previewImg={{
            img: {
              url: companyData?.logo || null,
              objFit: 'contain',
            },
            avatarLabel: companyData?.label || '',
          }}
          icon={<IconComp icon={<FigSearchSm />} />}
          api={RAAS_COMPANY_LIST_SEARCH_API}
          searchPreventOnEmpty
          onChangeAsync={onChangeAsync}
          filterOptions={filterOptionsCompany}
          renderOption={renderOptionCompany}
          error={form.companyGuidErr}
          isRequired
          id='add-credit-modal-company-auto-complete-input'
        />
      )}

      <InputFieldWithIconLabel
        type='number'
        name='noOfCredits'
        label={tInput('noOfCredits.label')}
        icon={<IconComp icon={<FigCreditCardPlus />} />}
        handleChanges={handleChanges}
        value={form.noOfCredits}
        minValue={1}
        error={form.noOfCreditsErr}
        isRequired
        id='add-credit-modal-no-of-credits-input'
      />

      <YearMonthPickerWithLabel
        name='expiryDate'
        label={tInput('expirationDate.label')}
        value={form.expiryDate}
        minDate={moment().add(1, 'days')}
        maxDate={moment().add(5, 'years')}
        handleChanges={handleChanges}
        views={['year', 'month', 'day']}
        format='yyyy-MM-dd'
        error={form.expirationDateErr}
        isRequired
        id='add-credit-modal-expiration-date-select'
      />

      {creditEditData && (
        <div
          role='presentation'
          className='delete-record-link'
          onClick={handleDeleteCredit}
        >
          {loadingCancel ? (
            <CircularProgress size={14} color='error' />
          ) : (
            tCommon('labels.delete')
          )}
        </div>
      )}
    </CommonModal>
  );
};

const mapStateToProps = (state) => {
  const {
    router: {
      location: { pathname },
    },
  } = state;

  return {
    pathname,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadCreditLogList: (payload) => dispatch(getCreditLogList(payload)),
  getActiveCreditListFunc: (payload) => dispatch(getActiveCreditList(payload)),
  showMessage: (text, severity) =>
    dispatch(showSuccessSnackbar(text, severity)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUpdateCreditModal);
