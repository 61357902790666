import {
  GET_ADMIN_LIST,
  UPDATE_CHECKBOX,
  GET_ADMIN,
  UPDATE_ADMIN_USER_VALUES,
  CREATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_STATUS,
  DELETE_ADMIN,
  CLEAR_ADMIN,
  UPDATE_ADMIN_SUCCESS,
} from '../actionTypes';
import { adminList, editAdmin } from './initialState';

const adminListReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return adminList;
  }
  switch (action.type) {
    case GET_ADMIN_LIST:
      return { ...state, data: action.payload };
    case UPDATE_CHECKBOX:
      return { ...state };
    case CREATE_ADMIN_SUCCESS:
      state.data.data.unshift(action.payload.data);
      return { ...state };
    case UPDATE_ADMIN_SUCCESS: {
      const data = state;
      data.data.data[action.payload?.editAdminIndex] = {
        ...data.data.data[action.payload?.editAdminIndex],
        name: `${action.payload.data.firstName} ${action.payload.data.lastName}`,
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        subUserRole: action.payload.data.subUserRole,
        status: action.payload.data.status,
      };

      return data;
    }
    case UPDATE_ADMIN_STATUS: {
      const users = state.data.data.map((user) => {
        if (!action.payload.data.users.includes(user._id)) {
          return user;
        }

        return {
          ...user,
          status: action.payload.data.status === 1 ? 'Active' : 'Inactive',
          selected: false,
        };
      });

      return { ...state, data: { ...state.data, data: users } };
    }
    case DELETE_ADMIN: {
      const users = state.data.data.filter((user) => {
        return !action.payload.data.users.includes(user._id);
      });

      return { ...state, data: { ...state.data, data: users } };
    }
    default:
      return state;
  }
};

export const editAdminReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return editAdmin;
  }
  switch (action.type) {
    case GET_ADMIN:
      return { ...state, data: action.payload };
    case UPDATE_ADMIN_USER_VALUES:
      return { ...state, data: { ...state.data, ...action.payload } };
    case CLEAR_ADMIN:
      return editAdmin;
    default:
      return state;
  }
};

export default adminListReducer;
