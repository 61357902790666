import React from 'react';
import { Rating } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GroupOfAvatarTitleSubTitleComp from '../avatars-with-title/GroupOfAvatarTitleSubTitleComp';
import QuotationLeftIcon from '../../../assets/icons/quotation-mark-left.png';
import QuotationRightIcon from '../../../assets/icons/quotation-mark-right.png';

const useStyles = makeStyles({
  ratingStyles: {
    color: '#7F56D9 !important',
    fontSize: '1.25rem',
  },
});

const Testimonials = ({ data }) => {
  const classes = useStyles();

  return (
    <div className='testimonial-wrapper'>
      <div className='testimonial-header'>
        <GroupOfAvatarTitleSubTitleComp
          userPic={data.userLogo}
          companyLogo={data.companyLogo}
          title={data.userName}
          subTitle={data.jobTitleAndCompany}
          titleCusStyle={{ fontSize: 18, lineHeight: '24px' }}
          subTitleCusStyle={{ fontSize: 16, lineHeight: '22px' }}
        />
        <Rating
          size='20'
          name='read-only'
          value={data.ratings}
          precision={0.5}
          color='primary'
          classes={{
            iconFilled: classes.ratingStyles,
            icon: classes.ratingStyles,
          }}
          readOnly
        />
      </div>
      <p className='testimonial-desc'>
        <sup>
          <img src={QuotationLeftIcon} alt='Quotation left icon' width='18' />
        </sup>{' '}
        {data.desc}
        <sup>
          <img src={QuotationRightIcon} alt='Quotation right icon' width='18' />
        </sup>
      </p>
    </div>
  );
};

export default Testimonials;
