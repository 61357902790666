import React from 'react';
import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material';

const Dropdown = ({ submenus, dropdown, setDropdown, navigateToUrl }) => {
  return (
    <ul className={`dropdown ${dropdown ? 'show' : ''}`}>
      {submenus.map((subMenu) =>
        subMenu?.hasDivider ? (
          <Divider key={`company-dropdown-menu-${subMenu.id}`} />
        ) : (
          subMenu.allow && (
            <MenuItem
              key={subMenu.id}
              onClick={() => {
                setDropdown(false);
                navigateToUrl(subMenu, subMenu.actionRedirectBlockPath);
              }}
              id={subMenu.id}
            >
              <ListItemIcon>{subMenu.icon}</ListItemIcon>
              <ListItemText>{subMenu.name}</ListItemText>
            </MenuItem>
          )
        )
      )}
    </ul>
  );
};

export default Dropdown;
