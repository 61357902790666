import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import FilterLoading from '../loading/FilterLoading';
import CheckBoxGroup from '../../pages/search/CheckBoxGroup';
import { URL_DISABLED_FILTER_FLAG } from '../../../common/constant';
import ClearFiltersButton from './ClearFiltersButton';
import { isUndefined } from '../../../helpers/utilityHelper';
import IconComp from '../../../components/shared/icons/IconComp';
import FigPlus from '../../../assets/icons/figma-pro-2-8/general/FigPlus/FigPlus';

const CheckBoxFilter = ({
  option,
  field,
  addMore,
  onChange,
  overrideValues,
  onReset,
}) => {
  const [tCommon] = useTranslation('common');
  const [isHideClear, setIsHideClear] = useState(false);

  useEffect(() => {
    const selectedList = !isUndefined(overrideValues)
      ? overrideValues.value
      : option.getValues();
    setIsHideClear(!selectedList || selectedList.length === 0);
  }, [option, overrideValues]);

  const onChangeFilter = (currentSelected, selectedValue, selectedIndex) => {
    let newValues = [...currentSelected];

    if (
      currentSelected.length === 1 &&
      currentSelected[0] === URL_DISABLED_FILTER_FLAG
    ) {
      newValues = [];
    }

    if (selectedIndex >= 0) {
      newValues.splice(selectedIndex, 1);
    } else {
      newValues.push(selectedValue);
    }

    onChange(field, newValues, option);
  };

  const clearValues = useCallback(() => {
    onReset(field, [], option);
  }, [field, onReset, option]);

  return (
    <div className='filters__item'>
      {!option.loading ? (
        <>
          <div className='filters__head'>
            <div className='filters__title'>{tCommon(field)}</div>
            {onReset && !isHideClear && (
              <ClearFiltersButton
                label={tCommon('labels.reset')}
                clearValues={clearValues}
              />
            )}
            {addMore && (
              <IconButton
                aria-label='delete'
                color='primary'
                className='filters__option-btn'
              >
                <IconComp icon={<FigPlus />} />
              </IconButton>
            )}
          </div>
          <div className='filters__body'>
            <CheckBoxGroup
              filterSection={option}
              onChange={onChangeFilter}
              overrideValues={overrideValues}
            />
          </div>
        </>
      ) : (
        <FilterLoading />
      )}
    </div>
  );
};

export default memo(CheckBoxFilter);
