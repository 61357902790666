import { DEFAULT_LOCALE, DEFAULT_CURRENCY_CODE } from '../common/constant';

const formatCurrency = (
  amount,
  locale = DEFAULT_LOCALE,
  currencyCode = DEFAULT_CURRENCY_CODE
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).format(amount);
};

export const currencyFormatter = {
  number: 0,
  format(
    amount,
    locale = DEFAULT_LOCALE,
    currencyCode = DEFAULT_CURRENCY_CODE
  ) {
    this.number = formatCurrency(amount, locale, currencyCode);
    return this;
  },
  noDecimal() {
    this.number = this.number.replace(/\D00(?=\D*$)/, '');
    return this;
  },
  shorten() {
    this.number = this.number.replace(',000', 'K');
    return this;
  },
  get() {
    return this.number;
  },
};

export const numberWithCommas = (number) => {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
};
