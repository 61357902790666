import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Typography, CircularProgress, Autocomplete } from '@mui/material';
import TextBox from '../filter/pickAndType/TextBox';

export default function AsynchronousAutoComplete(props) {
  const {
    name,
    label,
    value,
    onChanges,
    onKeyDown,
    api,
    exitLocations,
    classes: overrideClasses = {},
    textFieldProps = {},
    autoCompleteProps = {},
    clearableOnSelect = false,
    clearableOnBlur = false,
    transformData,
    textFieldLoadingProps = {},
    appendToSearchQuery = {},
    searchQuery = 'title',
    resetOnSelect = false,
    searchPreventOnEmpty = false,
    variant,
    renderOption,
    isShowOptionOnLoad,
    filterOptions,
  } = props;
  const [tCommon] = useTranslation('common');
  // const classes = useStyles();
  const inputFocus = useRef();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [touched, setTouched] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  const getOptions = useCallback(
    async (params) => {
      setLoading(true);

      if (isShowOptionOnLoad) {
        setOptions([]);
      }

      const newParams = {
        ...params,
        ...appendToSearchQuery,
      };

      if (searchPreventOnEmpty && newParams[searchQuery].trim() === '') {
        setOptions([]);
        return;
      }

      if (!isShowOptionOnLoad && newParams[searchQuery].length < 2) {
        setOptions([]);
        setLoading(false);
        return;
      }

      const response = await axios.get(api, {
        params: newParams,
      });

      if (exitLocations) {
        let currentList = response.data;

        currentList = currentList.filter((objFromA) => {
          return !exitLocations.find((objFromB) => {
            return objFromA.id === objFromB.countryId;
          });
        });

        setOptions(currentList);
        setLoading(false);
        return;
      }

      setOptions(response.data);
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, appendToSearchQuery]
  );

  useEffect(() => {
    if (isShowOptionOnLoad) {
      if (inputFocus.current) {
        inputFocus.current.focus();
        setInputFocused(true);
        setOpen(true);
      }
    }

    const triggerFocus = (e) => {
      if (e.detail.name === name) {
        inputFocus.current.focus();
      }
    };
    document.addEventListener('the-input-focus-after-select', triggerFocus);

    return () => {
      document.removeEventListener(
        'the-input-focus-after-select',
        triggerFocus
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newOptions = useMemo(() => {
    if (transformData) {
      return transformData(options, value);
    }

    return options;
  }, [transformData, options, value]);

  useEffect(() => {
    const { selectOnFocus } = autoCompleteProps;

    if (touched && (isUndefined(selectOnFocus) || selectOnFocus === true)) {
      getOptions({
        [searchQuery]: inputValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  const typingTimeout = useRef(null);
  const onSearchChange = (e) => {
    clearTimeout(typingTimeout.current);
    const searchText = e.target.value;
    typingTimeout.current = setTimeout(() => {
      getOptions({
        [searchQuery]: searchText,
      });
    }, 200);
  };

  const onInputFocus = () => {
    if (!touched) {
      setTouched(true);
    }

    setOpen(false);
    setInputFocused(true);
    setOptions([]);
    setLoading(false);
  };

  const onBlur = () => {
    if (clearableOnBlur === true) {
      setOptions([]);
    }

    setInputFocused(false);
    setOptions([]);
    setLoading(false);
  };

  const getRootStyles = () => {
    if (!inputValue) {
      return 'no-value';
    }

    return '';
  };

  return (
    <Autocomplete
      classes={{
        root: getRootStyles(),
        // popper: isShowOptionOnLoad
        //   ? classes.autocompleteProjectPopper
        //   : classes.autocompletePopper,
        ...overrideClasses,
      }}
      fullWidth
      open={
        open &&
        inputFocused &&
        (isShowOptionOnLoad || inputValue.length > 1) &&
        (!loading || newOptions.length > 0)
      }
      onOpen={() => {
        setOpen(true);
      }}
      onClose={(event, reason) => {
        if (reason === 'create-option') {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        if (event.currentTarget !== inputFocus.current) {
          setOpen(false);
        }

        if (reason !== 'toggleInput' && resetOnSelect) {
          setTouched(false);
        }
      }}
      onChange={(event, newValue, reason) => {
        if (reason === 'create-option') {
          event.preventDefault();
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
          return;
        }

        onChanges(name, newValue, newOptions);
        if (clearableOnSelect) {
          setInputValue('');
        }

        if (reason === 'clear' && event.type === 'click') {
          getOptions({ [searchQuery]: '' });
        }
      }}
      value={value}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      loading={loading}
      inputValue={inputValue}
      noOptionsText={
        searchPreventOnEmpty && inputValue === '' ? null : (
          <Typography variant='body1'>{tCommon('tryDifferentText')}</Typography>
        )
      }
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={newOptions}
      loadingText={null}
      {...autoCompleteProps}
      renderInput={(params) => (
        <TextBox
          // className={classes.autocompleteInput}
          variant={variant || undefined}
          name={name}
          {...params}
          {...textFieldProps}
          placeholder={label}
          InputProps={{
            ...params.InputProps,
            inputRef: inputFocus,
            endAdornment: (
              <>
                {loading && (!searchPreventOnEmpty || inputValue) ? (
                  <CircularProgress
                    color='inherit'
                    size={14}
                    {...textFieldLoadingProps}
                  />
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
          onChange={onSearchChange}
          onFocus={onInputFocus}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (
              e.keyCode === 13 &&
              isUndefined(onKeyDown) === false &&
              isUndefined(value.label) === false &&
              value !== ''
            ) {
              onChanges(name, value, newOptions);
              onKeyDown();
              e.preventDefault();
            }
          }}
        />
      )}
      renderOption={renderOption || autoCompleteProps.renderOption}
      filterOptions={filterOptions || autoCompleteProps.filterOptions}
    />
  );
}
