import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@mui/material';
import { isUndefined } from '../../../helpers/utilityHelper';
import CheckboxComp from '../../../components/shared/inputs/CheckboxComp';

const CheckBoxGroup = ({ filterSection, onChange, overrideValues }) => {
  const [tCommon] = useTranslation('common');

  return filterSection.getOptions().map((option) => {
    const selectedList = !isUndefined(overrideValues)
      ? overrideValues.value
      : filterSection.getValues();
    const selectedIndex = selectedList.indexOf(option.id);

    return (
      <div key={option.id} className='filters__row'>
        <FormControlLabel
          control={
            <CheckboxComp
              checked={selectedIndex >= 0}
              handleChecked={() =>
                onChange(selectedList, option.id, selectedIndex)
              }
            />
          }
          label={filterSection.getOptionLabel(option.label, tCommon)}
        />
        {filterSection?.getPostFix?.(option)}
      </div>
    );
  });
};

export default memo(CheckBoxGroup);
