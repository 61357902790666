import {
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANY_LIST,
  UPDATE_FIRST_LEVEL_COMPANY_VALUES,
  UPDATE_CHECKBOX,
  CLEAR_COMPANY,
  UPDATE_COMPANY_STATUS,
  UPDATE_FILTER_STATUS,
  DELETE_COMPANY,
  UPDATE_COMPANY_ADD_MODAL_OPEN,
} from '../actionTypes';
import { companies, singleCompany } from './initialState';

const companiesReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return companies;
  }
  switch (action.type) {
    case GET_COMPANY_LIST:
      return { ...state, data: action.payload };
    case UPDATE_CHECKBOX:
      return { ...state };
    case UPDATE_COMPANY_STATUS: {
      const companiesData = state.data.data.map((company) => {
        if (!action.payload.data.companies.includes(company.id)) {
          return company;
        }

        let flag = '';
        if (action.payload.data.status === 0) {
          flag = 'Pending';
        } else if (action.payload.data.status === 1) {
          flag = 'Active';
        } else if (action.payload.data.status === 2) {
          flag = 'Inactive';
        }

        return {
          ...company,
          status: flag,
          selected: false,
        };
      });

      return { ...state, data: { ...state.data, data: companiesData } };
    }
    case UPDATE_COMPANY_ADD_MODAL_OPEN: {
      return { ...state, isCompanyAddModalOpen: action.payload };
    }
    case DELETE_COMPANY: {
      const companyList = state.data.data.filter((user) => {
        return !action.payload.data.companies.includes(user._id);
      });

      return { ...state, data: { ...state.data, data: companyList } };
    }
    case UPDATE_FILTER_STATUS: {
      return { ...state, data: { ...state.data, filters: action.payload } };
    }
    default:
      return state;
  }
};

export const singleCompanyReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return singleCompany;
  }
  switch (action.type) {
    case GET_COMPANY:
      return { ...state, data: { ...state.data, ...action.payload } };
    case GET_COMPANY_ERROR:
      return { ...state, data: { ...state.data }, error: action.payload };
    case UPDATE_FIRST_LEVEL_COMPANY_VALUES:
      return { ...state, data: { ...state.data, ...action.payload } };
    case CLEAR_COMPANY:
      return singleCompany;
    default:
      return state;
  }
};

export default companiesReducer;
