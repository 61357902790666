import dotenv from 'dotenv';
import { REACT_APP_SERVER_URL } from '../config/envConfig';

dotenv.config();

const rootUri = REACT_APP_SERVER_URL || 'http://localhost:3001';

const apiVersion = 'v1';
const apiBasePath = `${rootUri}/api/${apiVersion}`;

export const auth = {
  loginCookieBased: {
    url: `${rootUri}/api/v1/auth/check/user`,
    method: 'GET',
  },
  sendEmailOtp: {
    url: `${rootUri}/api/v1/auth/send-email-otp`,
    method: 'POST',
  },
  loginByEmailOtp: {
    url: `${rootUri}/api/v1/auth/login-by-email-otp`,
    method: 'POST',
  },
  getProfileViaSocialLogin(token) {
    return {
      url: `${rootUri}/api/v1/auth/get/profile/via/social/login?gnbsl=${token}`,
      method: 'GET',
    };
  },
  getNameViaGoogle: {
    url: `${rootUri}/api/v1/auth/name/via/google/`,
    method: 'GET',
  },
  loginGoogle: {
    url: `${rootUri}/api/v1/auth/google/signin`,
    method: 'GET',
  },
  signupGoogle(id) {
    return {
      url: `${rootUri}/api/v1/auth/google/signup/${id}`,
      method: 'GET',
    };
  },
  getNameViaLinkedin: {
    url: `${rootUri}/api/v1/auth//name/via/linked-in/`,
    method: 'GET',
  },
  loginLinkedin: {
    url: `${rootUri}/api/v1/auth/linkedin/signin`,
    method: 'GET',
  },
  signupLinkedin(id) {
    return {
      url: `${rootUri}/api/v1/auth/linkedin/signup/${id}`,
      method: 'GET',
    };
  },
  logout: {
    url: `${rootUri}/api/v1/auth/logout`,
    method: 'POST',
  },
  resetRequest: {
    url: `${rootUri}/api/v1/auth/send-password-reset-email`,
    method: 'POST',
  },
  verify: {
    url: `${rootUri}/api/v1/auth/confirm-email`,
    method: 'POST',
  },
  account: {
    url: `${rootUri}/api/v1/auth/account`,
    method: 'GET',
  },
  update: {
    url: `${rootUri}/api/v1/auth/account`,
    method: 'PUT',
  },
  communitySocialSignUp: {
    url: `${rootUri}/api/v1/auth/community/social/signup`,
    method: 'POST',
  },
  communityTraditionalSignUp: {
    url: `${rootUri}/api/v1/auth/community/traditional-email/signup`,
    method: 'POST',
  },
  communityMagicLinkSignUp: {
    url: `${rootUri}/api/v1/auth/community/magic-link/signup`,
    method: 'POST',
  },
  markAsWatchedInfoVideo: {
    url: `${rootUri}/api/v1/auth/info/video/seen`,
    method: 'POST',
  },
  selfSignUpStep1: {
    url: `${rootUri}/api/v1/auth/company/self/signup/step-1`,
    method: 'POST',
  },
  selfSignUpStep2: {
    url: `${rootUri}/api/v1/auth/company/self/signup/step-2`,
    method: 'POST',
  },
  selfSignUpStep3: {
    url: `${rootUri}/api/v1/auth/company/self/signup/step-3`,
    method: 'POST',
  },
};

export const authCompany = {
  checkCompanyUserInviteSignUpToken(token) {
    return {
      url: `${rootUri}/api/v1/auth-company/invite-signup/check?cist=${token}`,
      method: 'GET',
    };
  },
  getNameViaGoogle(token) {
    return {
      url: `${rootUri}/api/v1/auth/company/invite-signup/get/name/via/google?cist=${token}`,
      method: 'GET',
    };
  },
  getNameViaLinkedIn(token) {
    return {
      url: `${rootUri}/api/v1/auth/company/invite-signup/get/name/via/linked-in?cist=${token}`,
      method: 'GET',
    };
  },
  inviteSignUpComplete: {
    url: `${rootUri}/api/v1/auth-company/invite-signup/complete`,
    method: 'PUT',
  },
};

export const user = {
  findOne: {
    url: `${rootUri}/api/v1/user/:id`,
    method: 'GET',
  },
  all: {
    url: `${rootUri}/api/v1/users`,
    method: 'GET',
  },
  update: {
    url: `${rootUri}/api/v1/user`,
    method: 'POST',
  },
};

export const candidate = {
  cvData: {
    url: `${apiBasePath}/candidates/cv-data`,
    method: 'POST',
  },
  getCandidate: {
    url: `${rootUri}/api/v1/candidates`,
    method: 'GET',
  },
  interviewRequest: {
    url: `${apiBasePath}/projects/candidate/interview/request`,
    method: 'POST',
  },
  hideProfile: {
    url: `${apiBasePath}/projects/candidate/hidden`,
    method: 'POST',
  },
  toggleFavorite: {
    url: `${apiBasePath}/projects/candidate/favourite`,
    method: 'POST',
  },
  changeRecruitmentStatus: {
    url: `${apiBasePath}/projects/recruitment`,
    method: 'POST',
  },
  addToAnotherProject: {
    url: `${apiBasePath}/projects/candidate/add`,
    method: 'POST',
  },
  getProjectsList: {
    url: `${apiBasePath}/projects/candidate/list`,
    method: 'GET',
  },
  downloadPDF: {
    url: `${apiBasePath}/candidates/get-pdf`,
    method: 'GET',
  },
  downloadCV: {
    url: `${apiBasePath}/candidates`,
    method: 'GET',
  },
  createCandidateAdminFlow: {
    url: `${apiBasePath}/candidates/create`,
    method: 'POST',
  },
  adminCandidateSearch: {
    url: `${apiBasePath}/candidates/list`,
    method: 'GET',
  },
  changeStatus: {
    url: `${apiBasePath}/candidates/update/status`,
    method: 'POST',
  },
  isSendConfirmationEmailForUpdate: {
    url: `${apiBasePath}/candidate-email-change-request/is-send-confirmation-email-for-update`,
    method: 'GET',
  },
  confirmEmailToUpdate: {
    url: `${apiBasePath}/candidate-email-change-request/confirm-email-to-update`,
    method: 'PUT',
  },
  profileView: {
    url: `${apiBasePath}/candidate-search-or-view-log/profile-view`,
    method: 'POST',
  },
  searchAppearance: {
    url: `${apiBasePath}/candidate-search-or-view-log/search-appearance`,
    method: 'POST',
  },
  searchOrViewProfileInsights: {
    url: `${apiBasePath}/candidate-search-or-view-log/profile-insights`,
    method: 'GET',
  },
  recruitmentStagesProfileInsights: {
    url: `${apiBasePath}/candidate-recruitment-stages-activity-log/profile-insights`,
    method: 'GET',
  },
};

export const statics = {
  getJobTitles: {
    url: `${apiBasePath}/job-titles/search`,
    method: 'GET',
  },
  getLocations: {
    url: `${apiBasePath}/locations/search`,
    method: 'GET',
  },
  getLocationsIpInfo: {
    url: `${apiBasePath}/locations/ip-info`,
    method: 'GET',
  },
  getLocationById: {
    url: `${apiBasePath}/locations/id`,
    method: 'GET',
  },
  getLocationByTitle: {
    url: `${apiBasePath}/locations/get-by-title`,
    method: 'GET',
  },
  getLocationByCode: {
    url: `${apiBasePath}/locations/code`,
    method: 'GET',
  },
  getSkills: {
    url: `${apiBasePath}/skills/search`,
    method: 'GET',
  },
  getLanguages: {
    url: `${apiBasePath}/languages/search`,
    method: 'GET',
  },
  getLanguagesByCode: {
    url: `${apiBasePath}/languages/search-by-codes`,
    method: 'GET',
  },
  getCitizenship: {
    url: `${apiBasePath}/citizenships/search`,
    method: 'GET',
  },
  getIndustries: {
    url: `${apiBasePath}/industries/search`,
    method: 'GET',
  },
  getLocationsData: {
    url: `${apiBasePath}/locations/all-country-data`,
    method: 'GET',
  },
  getAdmins: {
    url: `${apiBasePath}/admin/admin-list`,
    method: 'GET',
  },
};

export const project = {
  create: {
    url: `${apiBasePath}/projects/create`,
    method: 'POST',
  },
  createNewProject: {
    url: `${apiBasePath}/projects/create/new-project`,
    method: 'POST',
  },
  addJobDescription: {
    url: `${apiBasePath}/projects/add-job-description`,
    method: 'POST',
  },
  updateProjectModal: {
    url: `${apiBasePath}/projects/update/project`,
    method: 'PUT',
  },
  updateProjectBasedOnFilters: {
    url: `${apiBasePath}/projects/update/filters`,
    method: 'put',
  },
  upgradeProject: {
    url: `${apiBasePath}/projects/upgrade`,
    method: 'POST',
  },
  cancelProject: {
    url: `${apiBasePath}/projects/cancel`,
    method: 'POST',
  },
  guaranteeCandidateToProject: {
    url: `${apiBasePath}/projects/guarantee`,
    method: 'POST',
  },
  duplicateProject: {
    url: `${apiBasePath}/projects/duplicate-project`,
    method: 'POST',
  },
  grantAccess: {
    url: `${apiBasePath}/projects/candidate/access/grant`,
    method: 'POST',
  },
  setCandidateSeen: {
    url: `${apiBasePath}/projects/candidate/seen`,
    method: 'POST',
  },
  get(projectGuid) {
    return {
      url: `${apiBasePath}/projects/find/${projectGuid}`,
      method: 'GET',
    };
  },
  filter: {
    url: `${apiBasePath}/projects/search`,
    method: 'GET',
  },
  search(tab) {
    return {
      url:
        tab === 0
          ? `${apiBasePath}/projects/candidates/search`
          : `${apiBasePath}/projects/tab/search`,
      method: 'GET',
    };
  },
  searchWithCompanyAndAdmin: {
    url: `${apiBasePath}/projects/company/admin/search`,
    method: 'GET',
  },
  checkAndApproveInterviewRequest: {
    url: `${apiBasePath}/projects/candidate/interview/request/check/and/approve`,
    method: 'POST',
  },
  submitInterviewRequestAsReject: {
    url: `${apiBasePath}/projects/candidate/interview/request/reject`,
    method: 'POST',
  },
  downloadJD(projectId) {
    return {
      url: `${apiBasePath}/projects/jd/${projectId}`,
      method: 'GET',
    };
  },
  downloadJDPublicly(projectId) {
    return {
      url: `${apiBasePath}/projects/download/jd/${projectId}`,
      method: 'GET',
    };
  },
  getProjectAccessUserList: {
    url: `${apiBasePath}/projects/access/user/list`,
    method: 'GET',
  },
  getProjectAccessUserInfo: {
    url: `${apiBasePath}/projects/access/user/info`,
    method: 'GET',
  },
  pipelineStats: {
    url: `${apiBasePath}/projects/pipe-line`,
    method: 'GET',
  },
  getProjectShareUsers: {
    url: `${apiBasePath}/project-share/access/user/list/to/add`,
    method: 'GET',
  },
  addUserToProject: {
    url: `${apiBasePath}/project-share/access/user`,
    method: 'POST',
  },
  allUserAccessToProject: {
    url: `${apiBasePath}/project-share/access/user/all`,
    method: 'POST',
  },
  deleteSharedUser: {
    url: `${apiBasePath}/project-share/access/user`,
    method: 'DELETE',
  },
  getProjectTabByCandidate: {
    url: `${apiBasePath}/projects/get-project-tab-by-candidate`,
    method: 'GET',
  },
  projectStatusStats: {
    url: `${apiBasePath}/projects/status/stats`,
    method: 'GET',
  },
  searchByJDAIMatch: {
    url: `${apiBasePath}/projects/ai-match`,
    method: 'POST',
  },
  sendQualifyWhatsappMessage: {
    url: `${apiBasePath}/projects/candidate/send/qualify/request`,
    method: 'POST',
  },
  getAllWhatsappTemplates: (nextHash) => {
    return {
      url: `${apiBasePath}/projects/candidate/get/qualify/request/template?after=${nextHash}`,
      method: 'GET',
    };
  },
  checkCompanyCreditsAvailability(companyId) {
    return {
      url: `${apiBasePath}/projects/raas/status?${
        companyId ? `companyId=${companyId}` : ''
      }`,
      method: 'GET',
    };
  },
};

export const search = {
  trigger: {
    url: `${apiBasePath}/search`,
    method: 'GET',
  },
};

export const company = {
  filter: {
    url: `${apiBasePath}/company/list`,
    method: 'GET',
  },
  get: {
    url: `${apiBasePath}/company/get`,
    method: 'GET',
  },
  bulkStatusUpdate: {
    url: `${apiBasePath}/company/status`,
    method: 'PUT',
  },
  create: {
    url: `${apiBasePath}/company/create`,
    method: 'POST',
  },
  update: {
    url: `${apiBasePath}/company/update`,
    method: 'PUT',
  },
  delete: {
    url: `${apiBasePath}/company`,
    method: 'DELETE',
  },
  search: {
    url: `${apiBasePath}/company/search`,
    method: 'GET',
  },
};

export const companyUser = {
  filter: {
    url: `${apiBasePath}/company-user`,
    method: 'GET',
  },
  getUser(userId) {
    return {
      url: `${apiBasePath}/company-user/${userId}`,
      method: 'GET',
    };
  },
  bulkStatusUpdate: {
    url: `${apiBasePath}/company-user/status`,
    method: 'PUT',
  },
  bulkRoleUpdate: {
    url: `${apiBasePath}/company-user/role`,
    method: 'PUT',
  },
  invite: {
    url: `${apiBasePath}/company-user/invite`,
    method: 'POST',
  },
  update: {
    url: `${apiBasePath}/company-user/update`,
    method: 'PUT',
  },
  resendInvitation: {
    url: `${apiBasePath}/company-user/resend-invitation`,
    method: 'PUT',
  },
  getUserListToTransfer: {
    url: `${apiBasePath}/company-user/user-list-to-transfer`,
    method: 'GET',
  },
  delete: {
    url: `${apiBasePath}/company-user`,
    method: 'DELETE',
  },
  teamStats: {
    url: `${apiBasePath}/company-user/user-stat`,
    method: 'GET',
  },
};

export const admin = {
  filter: {
    url: `${apiBasePath}/admin/list`,
    method: 'GET',
  },
  bulkUpdate: {
    url: `${apiBasePath}/admin`,
    method: 'PUT',
  },
  bulkDelete: {
    url: `${apiBasePath}/admin`,
    method: 'DELETE',
  },
  search: {
    url: `${apiBasePath}/admin/search`,
    method: 'GET',
  },
  adminList: {
    url: `${apiBasePath}/admin/admin-list`,
    method: 'GET',
  },
  get: {
    url: `${apiBasePath}/admin/get`,
    method: 'GET',
  },
  getAdminAdvocateList: {
    url: `${apiBasePath}/admin/advocate-list`,
    method: 'GET',
  },
  create: {
    url: `${apiBasePath}/admin/create`,
    method: 'POST',
  },
  update: {
    url: `${apiBasePath}/admin/update`,
    method: 'PUT',
  },
};

export const creditLog = {
  getCreditLogList: {
    url: `${apiBasePath}/credit/logs`,
    method: 'GET',
  },
  getActiveCreditList: {
    url: `${apiBasePath}/credit`,
    method: 'GET',
  },
  addCredit: {
    url: `${apiBasePath}/credit`,
    method: 'POST',
  },
  updateCredit(creditId) {
    return {
      url: `${apiBasePath}/credit/${creditId}`,
      method: 'PUT',
    };
  },
  deleteCredit(creditId, creditDataId) {
    return {
      url: `${apiBasePath}/credit/${creditId}/${creditDataId}`,
      method: 'DELETE',
    };
  },
  generateOrderForm: {
    url: `${apiBasePath}/credit/sales-order`,
    method: 'POST',
  },
  instantPurchaseCredit: {
    url: `${apiBasePath}/credit/instant-purchase/stripe`,
    method: 'POST',
  },
};

export const candidateNotes = {
  getAll(candidateId) {
    return {
      url: `${apiBasePath}/candidate-note/${candidateId}`,
      method: 'GET',
    };
  },
  create(candidateId) {
    return {
      url: `${apiBasePath}/candidate-note/${candidateId}`,
      method: 'POST',
    };
  },
  update(id) {
    return {
      url: `${apiBasePath}/candidate-note/${id}`,
      method: 'PUT',
    };
  },
  delete(id) {
    return {
      url: `${apiBasePath}/candidate-note/${id}`,
      method: 'DELETE',
    };
  },
};

export const tag = {
  filter: {
    url: `${apiBasePath}/tags/list`,
    method: 'GET',
  },
  search: {
    url: `${apiBasePath}/tags/search`,
    method: 'GET',
  },
  get: {
    url: `${apiBasePath}/tags/get`,
    method: 'GET',
  },
  getIds: {
    url: `${apiBasePath}/tags/get/ids`,
    method: 'GET',
  },
  checkTagIdentifierExist: {
    url: `${apiBasePath}/tags/check/identifier/exist`,
    method: 'GET',
  },
  create: {
    url: `${apiBasePath}/tags/create`,
    method: 'POST',
  },
  update(id) {
    return {
      url: `${apiBasePath}/tags/update/${id}`,
      method: 'PUT',
    };
  },
  bulkStatusUpdate: {
    url: `${apiBasePath}/tags/update/bulk/status`,
    method: 'PUT',
  },
  semiActiveTag: {
    url: `${apiBasePath}/tags/semi-active-tag`,
    method: 'GET',
  },
};

export const dashboard = {
  getStageListByTa: {
    url: `${apiBasePath}/ta-dashboard/ta-recruitment-event-log`,
    method: 'GET',
  },
  getProjectTAAssignedList: {
    url: `${apiBasePath}/ta-dashboard/ta-project-assignment-log`,
    method: 'GET',
  },
  getCandidateStageActivityWeeklyList: {
    url: `${apiBasePath}/ta-dashboard/activity-per-week`,
    method: 'GET',
  },
  getProjectOverviewList: {
    url: `${apiBasePath}/ta-dashboard/project-overview-log-per-week`,
    method: 'GET',
  },
  getInterviewRequestList: {
    url: `${apiBasePath}/ta-dashboard/interview-request-list`,
    method: 'GET',
  },
};

export const integration = {
  integrationList: {
    url: `${apiBasePath}/integrations/integration-list`,
    method: 'GET',
  },
  createGreenHouseIntegration: {
    url: `${apiBasePath}/integrations/greenhouse`,
    method: 'POST',
  },
  createJazzHreIntegration: {
    url: `${apiBasePath}/integrations/jazzhr`,
    method: 'POST',
  },
  manualSync(id) {
    return {
      url: `${apiBasePath}/integrations/manual/sync/${id}`,
      method: 'PUT',
    };
  },
  removeIntegration(id) {
    return {
      url: `${apiBasePath}/integrations/remove/${id}`,
      method: 'DELETE',
    };
  },
  leverSignIn: {
    url: `${apiBasePath}/integrations/lever/sign-in`,
    method: 'GET',
  },
};
