import { GET_ACTIVE_CREDITS } from '../actionTypes';
import CreditApi from '../../api/CreditApi';

// eslint-disable-next-line import/prefer-default-export
export const getActiveCreditList = (payload) => async (dispatch) => {
  try {
    const activeCreditsList = await CreditApi.getActiveCreditList(payload);
    dispatch({
      type: GET_ACTIVE_CREDITS,
      payload: activeCreditsList.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ACTIVE_CREDITS,
      payload: {},
    });
  }
};
