import axios from 'axios';
import { search } from '../providers/apiFeedProvider';

class SearchApi {
  static searchCancelToken = null;

  static search(params) {
    if (SearchApi.searchCancelToken !== null) {
      SearchApi.searchCancelToken();
    }

    const { CancelToken } = axios;
    return axios({
      ...search.trigger,
      cancelToken: new CancelToken(function executor(cancellableFn) {
        SearchApi.searchCancelToken = cancellableFn;
      }),
      params,
    });
  }
}

export default SearchApi;
