import { SNACKBAR_CLEAR, SHOW_SNACKBAR } from '../actionTypes';

const showSnackBar = (message, severity = 'success') => ({
  type: SHOW_SNACKBAR,
  message,
  severity,
});

export const showErrorSnackbar = (message) => {
  return (dispatch) => {
    dispatch(showSnackBar(message, 'error'));
  };
};

export const showInfoSnackbar = (message) => {
  return (dispatch) => {
    dispatch(showSnackBar(message, 'info'));
  };
};

export const showSuccessSnackbar = (message, severity = 'success') => {
  return (dispatch) => {
    dispatch(showSnackBar(message, severity));
  };
};

export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: SNACKBAR_CLEAR });
  };
};
