const candidateSource = {
  linkedIn: {
    option: 1,
    label: 'linkedIn',
  },
  jobStreet: {
    option: 2,
    label: 'jobStreet',
  },
  monster: {
    option: 3,
    label: 'monster',
  },
  eFinancialCareers: {
    option: 4,
    label: 'eFinancialCareers',
  },
  indeed: {
    option: 5,
    label: 'indeed',
  },
  jobsDB: {
    option: 6,
    label: 'jobsDB',
  },
  referral: {
    option: 7,
    label: 'referral',
  },
  other: {
    option: 8,
    label: 'other',
  },
};

export default candidateSource;
