import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthorizationService from '../authorization/AuthorizationService';
import { UserTypes } from '../common/roles';
import { redirectPathToLogin } from '../helpers/urlHelper';
import { setPageTitle } from '../redux/actions/appActions';
import { allProjects, search, talentProfile } from '../common/paths';
import LayoutWithNavbar from '../components/layouts/LayoutWithNavbar';

export const userAuthenticated = () => {
  return AuthorizationService.isAuthenticated();
};

export const userAuthorized = () => {
  return AuthorizationService.isAuthorized();
};

const PrivateRoutes = ({
  keyAttr,
  component: Component,
  restricted,
  allow,
  changePageTitle,
  pageTitleDisable,
  pageTitle,
  hasCustomContainer,
  ...rest
}) => {
  const [tCommon] = useTranslation('common');

  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated = userAuthenticated();
        const { role } = userAuthorized();

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /auth/login page
        if (!isAuthenticated) {
          const redirectTo = redirectPathToLogin();
          return <Redirect to={redirectTo} />;
        }

        // Show the component only when the user role is allowed
        // Otherwise, redirect the user to /unauthorized page
        if (
          allow &&
          (allow.length > 0 || restricted) &&
          allow.indexOf(role) === -1
        ) {
          if (role === UserTypes.Admin) return <Redirect to={search} />;
          if (role === UserTypes.Company || role === UserTypes.RaasCompany)
            return <Redirect to={allProjects} />;
          if (role === UserTypes.Candidate)
            return <Redirect to={talentProfile} />;
        }

        const title = pageTitle
          ? `${pageTitle} | ${tCommon('pageTitles.gritSearch')}`
          : tCommon('pageTitles.gritSearch');
        if (!pageTitleDisable) changePageTitle(title);

        return (
          <LayoutWithNavbar
            key={`navbar-${keyAttr}`}
            keyAttr={keyAttr}
            hasCustomContainer={hasCustomContainer}
            isPrivate
          >
            <Component {...props} role={role} />
          </LayoutWithNavbar>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  changePageTitle: (payload) => dispatch(setPageTitle(payload)),
});

export default connect(null, mapDispatchToProps)(PrivateRoutes);
