import axios from 'axios';
import { companyUser } from '../providers/apiFeedProvider';

class CompanyUserApi {
  static getCompanyUsersList(params) {
    return axios({
      ...companyUser.filter,
      params,
    });
  }

  static getCompanyUser(userId) {
    return axios({
      ...companyUser.getUser(userId),
    });
  }

  static inviteUser(data) {
    return axios({
      ...companyUser.invite,
      data,
    });
  }

  static updateCompanyUser(data) {
    return axios({
      ...companyUser.update,
      data,
    });
  }

  static resendInvitation(params) {
    return axios({
      ...companyUser.resendInvitation,
      params,
    });
  }

  static getUserListToTransfer(params) {
    return axios({
      ...companyUser.getUserListToTransfer,
      params,
    });
  }

  static deleteCompanyUser(data) {
    return axios({
      ...companyUser.delete,
      data,
    });
  }

  static updateCompaniesUserStatus(data, status) {
    return axios({
      ...companyUser.bulkStatusUpdate,
      data: {
        users: data,
        status,
      },
    });
  }

  static updateCompanyUserRole(data, role) {
    return axios({
      ...companyUser.bulkRoleUpdate,
      data: {
        users: data,
        companyRole: role,
      },
    });
  }

  static searchCompaniesUser(params) {
    return axios({
      ...companyUser.search,
      params,
    });
  }

  static teamStats(params) {
    return axios({
      ...companyUser.teamStats,
      params,
    });
  }
}

export default CompanyUserApi;
