import loadable from '@loadable/component';
import { UserTypes } from '../common/roles';
import {
  addTalent,
  admins,
  allCompanies,
  allCompanyUsers,
  allProjects,
  allTalent,
  allTeam,
  communitySignUp,
  companyInviteSignUp,
  companySignUpComplete,
  editTalent,
  integrations,
  login,
  myProfile,
  previewTalent,
  projectDetail,
  search,
  tags,
  talentProfile,
  companySignUpStep1,
  companySignUpStep2,
  companySignUpStep3,
  JDPreview,
  page404,
  activeCredits,
  creditLog,
  companyCredits,
  aiMatch,
  taDashboard,
  taAssignedActivity,
  taStageActivity,
  taProjectOverview,
  interviewRequestList,
} from '../common/paths';

const routesProvider = [
  {
    path: '/',
    private: true,
    restricted: true,
    allow: [],
    component: loadable(() => import('../pages/home/HomePage')),
  },
  {
    path: login,
    private: false,
    restricted: true,
    allow: [],
    pageTitle: 'pageTitles.login',
    component: loadable(() => import('../pages/auth/login/LoginPage')),
  },
  {
    path: search,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin, UserTypes.Company],
    hasCustomContainer: false,
    pageTitle: 'pageTitles.search',
    component: loadable(() => import('../pages/search/SearchPage')),
  },
  {
    path: projectDetail,
    private: true,
    restricted: false,
    allow: [UserTypes.Admin, UserTypes.Company, UserTypes.RaasCompany],
    pageTitleDisable: true,
    hasCustomContainer: false,
    component: loadable(() => import('../old/pages/project/ProjectDetailPage')),
  },
  {
    path: addTalent,
    private: true,
    restricted: false,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.addTalent',
    hasCustomContainer: false,
    component: loadable(() =>
      import('../pages/candidate/AddEditCandidatePage')
    ),
  },
  {
    path: editTalent,
    private: true,
    restricted: false,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.editTalent',
    hasCustomContainer: false,
    component: loadable(() =>
      import('../pages/candidate/AddEditCandidatePage')
    ),
  },
  {
    path: previewTalent,
    private: true,
    restricted: false,
    allow: [UserTypes.Admin],
    pageTitleDisable: true,
    hasCustomContainer: false,
    component: loadable(() =>
      import('../pages/candidate/PreviewCandidatePage')
    ),
  },
  {
    path: companySignUpStep1,
    private: false,
    restricted: true,
    allow: [],
    pageTitle: 'pageTitles.companySignup',
    showSignUpLayout: true,
    component: loadable(() =>
      import('../pages/auth/company/SelfSignUpStep1Page')
    ),
  },
  {
    path: companySignUpStep2,
    private: false,
    restricted: true,
    allow: [],
    pageTitle: 'pageTitles.companySignup',
    component: loadable(() =>
      import('../pages/auth/company/SelfSignUpStep2Page')
    ),
  },
  {
    path: companySignUpStep3,
    private: false,
    restricted: true,
    allow: [],
    pageTitle: 'pageTitles.companySignup',
    component: loadable(() =>
      import('../pages/auth/company/SelfSignUpStep3Page')
    ),
  },
  {
    path: companyInviteSignUp,
    private: false,
    restricted: true,
    allow: [],
    showSignUpLayout: true,
    pageTitle: 'pageTitles.companySignup',
    component: loadable(() => import('../pages/auth/company/InviteSignUpPage')),
  },
  {
    path: companySignUpComplete,
    private: false,
    restricted: true,
    allow: [],
    pageTitle: 'pageTitles.companySignup',
    component: loadable(() =>
      import('../pages/auth/company/SignUpCompletePage')
    ),
  },
  {
    path: communitySignUp,
    private: false,
    restricted: true,
    allow: [],
    pageTitle: 'pageTitles.communitySignup',
    component: loadable(() =>
      import('../old/pages/candidate/CommunitySignUpPage')
    ),
  },
  {
    path: allCompanies,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.companies',
    component: loadable(() => import('../pages/company/AllCompaniesPage')),
  },
  {
    path: allCompanyUsers,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.allCompanyUsers',
    component: loadable(() => import('../pages/company/AllCompanyUsersPage')),
  },
  {
    path: allTeam,
    private: true,
    restricted: true,
    allow: [UserTypes.Company, UserTypes.RaasCompany],
    pageTitle: 'pageTitles.allCompanyUsers',
    component: loadable(() => import('../pages/company/AllCompanyUsersPage')),
  },
  {
    path: allProjects,
    private: true,
    restricted: true,
    allow: [UserTypes.Company, UserTypes.RaasCompany, UserTypes.Admin],
    pageTitle: 'pageTitles.projects',
    component: loadable(() => import('../pages/project/AllProjectsPage')),
  },
  {
    path: allTalent,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.talent',
    component: loadable(() => import('../pages/candidate/AllCandidatesPage')),
  },
  {
    path: activeCredits,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.activeCredits',
    component: loadable(() => import('../pages/credits/ActiveCreditsPage')),
  },
  {
    path: creditLog,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.creditLog',
    component: loadable(() => import('../pages/credits/CreditLogPage')),
  },
  {
    path: companyCredits,
    private: true,
    restricted: true,
    allow: [UserTypes.RaasCompany],
    pageTitle: 'pageTitles.credits',
    component: loadable(() => import('../pages/credits/CompanyCreditsPage')),
  },
  {
    path: tags,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.tags',
    component: loadable(() => import('../pages/tag/AllTagsPage')),
  },
  {
    path: admins,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    pageTitle: 'pageTitles.admins',
    component: loadable(() => import('../pages/admin/AllAdminPage')),
  },
  {
    path: myProfile,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin, UserTypes.Company, UserTypes.RaasCompany],
    pageTitle: 'pageTitles.myProfile',
    component: loadable(() => import('../pages/account/ProfilePage')),
  },
  {
    path: integrations,
    private: true,
    restricted: true,
    allow: [UserTypes.Company],
    pageTitle: 'pageTitles.integrations',
    component: loadable(() => import('../pages/integration/IntegrationPage')),
  },
  {
    path: talentProfile,
    private: true,
    restricted: true,
    allow: [UserTypes.Candidate],
    pageTitle: 'pageTitles.myProfile',
    component: loadable(() =>
      import('../pages/candidate-profile/CandidateProfilePage')
    ),
  },
  {
    path: JDPreview,
    private: false,
    restricted: false,
    allow: [],
    component: loadable(() => import('../pages/jd/JDPreviewPage')),
  },
  {
    path: page404,
    private: false,
    restricted: false,
    allow: [],
    component: loadable(() => import('../pages/page-not-found/PageNotFound')),
  },
  {
    path: aiMatch,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    component: loadable(() => import('../pages/ai-match/AiMatch')),
    hasCustomContainer: false,
  },
  {
    path: taDashboard,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    component: loadable(() =>
      import('../pages/dashboard/TADashboardWeeklyPage')
    ),
    pageTitle: 'pageTitles.countEachActivityPerWeek',
  },
  {
    path: taAssignedActivity,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    component: loadable(() => import('../pages/dashboard/TAAssignActivityLog')),
    pageTitle: 'pageTitles.tAProjectAssignmentLog',
  },
  {
    path: taStageActivity,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    component: loadable(() => import('../pages/dashboard/TAStageActivityLog')),
    pageTitle: 'pageTitles.tARecruitmentActivityLog',
  },
  {
    path: taProjectOverview,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    component: loadable(() => import('../pages/dashboard/TAProjectOverview')),
    pageTitle: 'pageTitles.projectOverviewWeekly',
  },
  {
    path: interviewRequestList,
    private: true,
    restricted: true,
    allow: [UserTypes.Admin],
    component: loadable(() => import('../pages/dashboard/InterviewRequest')),
    pageTitle: 'pageTitles.interviewRequestList',
  },
];

export default routesProvider;
