import React from 'react';
import { TextField } from '@mui/material';

const DatePickerRenderInput = (params) => {
  const { inputProps } = params;

  return (
    <TextField
      {...params}
      inputProps={{ ...inputProps, readOnly: true }}
      placeholder='MMM YYYY'
    />
  );
};

export default DatePickerRenderInput;
