import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import urlSyncMiddleware from './middleware/urlSyncMiddleware';
import toastMessageMiddleware from './middleware/toastMessageMiddleware';

export const history = createBrowserHistory();
const middlewares = [
  thunk,
  routerMiddleware(history),
  urlSyncMiddleware,
  toastMessageMiddleware,
];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(rootReducer(history), {}, composedEnhancers);

export default store;
