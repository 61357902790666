import React from 'react';
import { useTranslation } from 'react-i18next';
import IconComp from '../icons/IconComp';
import BootstrapToolTip from './BootstrapToolTip';
import FigEyeOff from '../../../assets/icons/figma-pro-2-8/general/FigEyeOff/FigEyeOff';

const IsAnonymousProfileIndicator = ({ cusStyle, id = '' }) => {
  const [common] = useTranslation('common');

  return (
    <BootstrapToolTip title={common('toolTips.visibilityCondition')}>
      <span className='hide-these-fields' style={cusStyle} id={id}>
        <IconComp icon={<FigEyeOff />} />
      </span>
    </BootstrapToolTip>
  );
};

export default IsAnonymousProfileIndicator;
