import axios from 'axios';
import { REACT_APP_SERVER_URL } from '../config/envConfig';
import { candidate } from '../providers/apiFeedProvider';

class CandidateApi {
  static filterApi = `${REACT_APP_SERVER_URL}/api/v1/candidates`;

  static cvParserDataWithResume(data) {
    return axios({
      ...candidate.cvData,
      data,
    });
  }

  static saveCandidateObject(params) {
    return axios.post(`${CandidateApi.filterApi}`, params);
  }

  static updateCandidateObject(params, cusConfig = {}) {
    return axios.put(`${CandidateApi.filterApi}`, params, cusConfig);
  }

  static createCandidateAdminFlow(data) {
    return axios({
      ...candidate.createCandidateAdminFlow,
      data,
    });
  }

  static getExperienceAndEducation(params) {
    return axios.get(`${CandidateApi.filterApi}`, { params });
  }

  static getCandidate(id = '', cusConfig = {}) {
    return axios({
      url: `${candidate.getCandidate.url}?id=${id}`,
      method: candidate.getCandidate.method,
      ...cusConfig,
    });
  }

  static changeRecruitmentStatus(data) {
    return axios({
      ...candidate.changeRecruitmentStatus,
      data,
    });
  }

  static addToAnotherProject(data) {
    return axios({
      ...candidate.addToAnotherProject,
      data,
    });
  }

  static toggleHide(data) {
    return axios({
      ...candidate.hideProfile,
      data,
    });
  }

  static toggleFavories(data) {
    return axios({
      ...candidate.toggleFavorite,
      data,
    });
  }

  static sendJobDescription(data) {
    return axios({
      ...candidate.jobDescription,
      data,
    });
  }

  static sendJobInterviewRequest(data) {
    return axios({
      ...candidate.interviewRequest,
      data,
    });
  }

  static getProjectList(params) {
    return axios({
      ...candidate.getProjectsList,
      params,
    });
  }

  static downloadPDF(id) {
    return axios({
      url: `${candidate.downloadPDF.url}?id=${id}`,
      method: candidate.downloadPDF.method,
    });
  }

  static downloadCV(id) {
    return axios({
      url: `${candidate.downloadCV.url}/${id}/cv`,
      method: candidate.downloadCV.method,
    });
  }

  static getResume(fileName) {
    return axios({
      url: `${candidate.downloadCV.url}/resume/${fileName}`,
      method: candidate.downloadCV.method,
    });
  }

  static listCandidateCancelToken = null;

  static adminCandidateSearch(params) {
    if (CandidateApi.listCandidateCancelToken !== null) {
      CandidateApi.listCandidateCancelToken();
    }

    const { CancelToken } = axios;

    return axios({
      ...candidate.adminCandidateSearch,
      cancelToken: new CancelToken(function executor(cancellableFn) {
        CandidateApi.listCandidateCancelToken = cancellableFn;
      }),
      params,
    });
  }

  static changeStatus(data) {
    return axios({
      ...candidate.changeStatus,
      data,
    });
  }

  static isSendConfirmationEmailForUpdate(userId) {
    return axios({
      url: `${candidate.isSendConfirmationEmailForUpdate.url}/${userId}`,
      method: candidate.isSendConfirmationEmailForUpdate.method,
    });
  }

  static confirmEmailToUpdate(token) {
    return axios({
      url: `${candidate.confirmEmailToUpdate.url}/${token}`,
      method: candidate.confirmEmailToUpdate.method,
    });
  }

  static profileView(data) {
    return axios({
      ...candidate.profileView,
      data,
    });
  }

  static searchAppearance(data) {
    return axios({
      ...candidate.searchAppearance,
      data,
    });
  }

  static searchOrViewProfileInsights(candidateId, filterBy) {
    return axios({
      url: `${candidate.searchOrViewProfileInsights.url}/${candidateId}/${filterBy}`,
      method: candidate.searchOrViewProfileInsights.method,
    });
  }

  static recruitmentStagesProfileInsights(candidateId, filterBy) {
    return axios({
      url: `${candidate.recruitmentStagesProfileInsights.url}/${candidateId}/${filterBy}`,
      method: candidate.recruitmentStagesProfileInsights.method,
    });
  }
}

export default CandidateApi;
