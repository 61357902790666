import _, { isEmpty } from 'lodash';
import {
  CLEAR_COMPANY_USER,
  GET_COMPANY_USER,
  GET_COMPANY_USER_ERROR,
  GET_COMPANY_USER_LIST,
  GET_COMPANY_USER_ROLE_STATS,
  UPDATE_FIRST_LEVEL_COMPANY_USER_VALUES,
  UPDATE_COMPANY_USER_STATUS,
  UPDATE_COMPANY_USER_ROLE,
  DELETE_COMPANY_USER,
  UPDATE_COMPANY_USER_FILTER_STATUS,
  UPDATE_COMPANY_USER_ADD_MODAL_OPEN,
} from '../actionTypes';
import CompanyUserApi from '../../api/CompanyUserApi';
import { singleCompanyUser } from '../reducers/initialState';

export const getUserRoleStats = (trans) => async (dispatch) => {
  const resp = await CompanyUserApi.teamStats();

  const statData = [
    {
      id: trans('labels.users'),
      value: resp?.data?.totalCount,
    },
    {
      id: trans('labels.roles.1'),
      value: resp?.data?.adminCount,
    },
    {
      id: trans('labels.roles.2'),
      value: resp?.data?.memberCount,
    },
  ];

  dispatch({
    type: GET_COMPANY_USER_ROLE_STATS,
    payload: statData,
  });
};

export const getCompanyUsers = (payload, trans) => async (dispatch) => {
  const companyUsers = await CompanyUserApi.getCompanyUsersList(payload);
  if (companyUsers?.data?.success) {
    const companyDataObject = companyUsers.data.data;

    // formatting the payload to frontend standards
    if (companyDataObject !== null && companyDataObject !== undefined) {
      Object.keys(companyDataObject).forEach((item, key) => {
        companyDataObject[key].status = trans(
          `labels.statuses.status_${companyDataObject[key].status}`
        );

        companyDataObject[key].role = trans(
          `labels.roles.${companyDataObject[key].role}`
        );
      });
    }

    companyUsers.data.data = companyDataObject;

    dispatch({
      type: GET_COMPANY_USER_LIST,
      payload: companyUsers.data,
    });
  }
};

export const getCompanyUser = (payload) => async (dispatch) => {
  if (isEmpty(payload.id)) {
    dispatch({
      type: GET_COMPANY_USER,
      payload: singleCompanyUser.data,
    });
  } else {
    try {
      const companyUsers = await CompanyUserApi.getCompanyUser(payload.id);

      dispatch({
        type: GET_COMPANY_USER,
        payload: companyUsers.data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPANY_USER_ERROR,
        payload: error?.response?.data || {},
      });
    }
  }
};

export const clearCompanyUserObject = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPANY_USER,
  });
};

export const updateFirstLevelCompanyUserValues =
  (name, value) => async (dispatch) => {
    dispatch({
      type: UPDATE_FIRST_LEVEL_COMPANY_USER_VALUES,
      payload: { [name]: value },
    });
  };

export const createCompanyUser =
  (data, trans) => async (dispatch, getState) => {
    const { companyUsers } = getState();

    if (companyUsers?.data?.data && !_.isEmpty(data)) {
      data.forEach((user) => {
        const newObj = {
          ...user,
          name: 'Unknown name',
          status: trans(`labels.statuses.status_${user.status}`),
          role: trans(`labels.roles.${user.role}`),
        };

        if (companyUsers.data.pagination.currentPage === 1) {
          companyUsers.data.data.unshift(newObj);

          if (
            companyUsers.data.data.length >
            companyUsers.data.pagination.pageSize
          ) {
            companyUsers.data.data.pop();
          }
        }
      });

      dispatch({
        type: GET_COMPANY_USER_LIST,
        payload: companyUsers.data,
      });
    }
  };

export const updateCompanyUser =
  (data, trans) => async (dispatch, getState) => {
    const { companyUsers } = getState();

    if (companyUsers?.data?.data && !_.isEmpty(data)) {
      const newData = companyUsers.data.data.map((el) => {
        if (el.id === `User:${data.id}`) {
          return {
            ...el,
            name: data.name,
            email: data.email,
            phone: data.phone,
            status: trans(`labels.statuses.status_${data.status}`),
            role: trans(`labels.roles.${data.role}`),
            profilePic: data?.profilePic || el.profilePic,
          };
        }

        return el;
      });

      companyUsers.data.data = newData;

      dispatch({
        type: GET_COMPANY_USER_LIST,
        payload: companyUsers.data,
      });
    }
  };

export const updateCompanyUserStatus = (ids, status) => async (dispatch) => {
  const resp = await CompanyUserApi.updateCompaniesUserStatus(ids, status);

  dispatch({
    type: UPDATE_COMPANY_USER_STATUS,
    payload: {
      data: {
        companyUsers: resp?.data?.message?.success || [],
        status,
      },
    },
  });
  return resp;
};

export const updateCompanyUserRole = (ids, role) => async (dispatch) => {
  const resp = await CompanyUserApi.updateCompanyUserRole(ids, role);

  dispatch({
    type: UPDATE_COMPANY_USER_ROLE,
    payload: {
      data: {
        companyUsers: resp?.data?.message?.success || [],
        role,
      },
    },
  });
  return resp;
};

export const updateCompanyUserFilterStatuses = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_USER_FILTER_STATUS,
    payload: data,
  });
};

export const deleteCompanyUser = (data) => async (dispatch) => {
  const resp = await CompanyUserApi.deleteCompanyUser(data);
  dispatch({
    type: DELETE_COMPANY_USER,
    payload: {
      data: {
        companyUsers: [data.userId],
      },
    },
  });
  return resp;
};

export const increaseCompanyUserListCountBy =
  (count) => async (dispatch, getState) => {
    const { companyUsers } = getState();

    if (
      count > 0 &&
      companyUsers?.data?.pagination?.total &&
      companyUsers?.data?.pagination?.dataCount
    ) {
      companyUsers.data.pagination.total += count;
      companyUsers.data.pagination.dataCount += count;

      dispatch({
        type: GET_COMPANY_USER_LIST,
        payload: companyUsers.data,
      });
    }
  };

export const updateCompanyUserAddModalOpenAction =
  (open) => async (dispatch) => {
    dispatch({
      type: UPDATE_COMPANY_USER_ADD_MODAL_OPEN,
      payload: open,
    });
  };
