import { useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';
import { useLocation } from 'react-router-dom';

const CrispHandler = () => {
  const location = useLocation();

  Crisp.setSafeMode(true);
  Crisp.setZIndex(99999);

  useEffect(() => {
    const match = /^\/project\/[^/]+$/.test(location.pathname);

    if (!match) {
      if (Crisp.isCrispInjected() === true) {
        Crisp.chat.hide();
      }
      // eslint-disable-next-line no-console
      console.log('hiding from component');
    }
  }, [location.pathname]);

  return null;
};

export default CrispHandler;
