import React from 'react';
import Navbar from '../shared/navbar/Navbar';
import Messages from '../shared/snackbar/Messages';

const LayoutWithNavbar = ({
  keyAttr,
  isPrivate,
  children,
  hasCustomContainer = true,
}) => {
  return (
    <div key={keyAttr}>
      <Messages />
      <Navbar isPrivate={isPrivate} />
      {hasCustomContainer ? (
        <div className='custom-container'>{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default LayoutWithNavbar;
