// Operations with local storage
export const getFromLocalStorage = (key) => localStorage.getItem(key);

export const setToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

// Operations with session storage
export const getFromSessionStorage = (key) =>
  JSON.parse(sessionStorage.getItem(key));

export const setToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};
