import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { clearSnackbar } from '../../../redux/actions/snackbarActions';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function SuccessSnackbar() {
  const dispatch = useDispatch();

  const { successSnackbarMessage, successSnackbarOpen, severity } = useSelector(
    (state) => state.snackbar
  );

  return (
    <Snackbar
      open={successSnackbarOpen}
      autoHideDuration={6000}
      onClose={() => dispatch(clearSnackbar())}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ top: 80 }}
    >
      <Alert onClose={() => dispatch(clearSnackbar())} severity={severity}>
        {successSnackbarMessage}
      </Alert>
    </Snackbar>
  );
}
