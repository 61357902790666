import React from 'react';
import { Avatar, AvatarGroup } from '@mui/material';

const GroupOfAvatarTitleSubTitleComp = ({
  userPic,
  companyLogo,
  userAvatarName,
  companyAvatarName,
  title,
  subTitle,
  titleCusStyle = {},
  subTitleCusStyle = {},
}) => {
  return (
    <div
      className='group-of-avatar-title-sub-title-wrapper'
      id='navbar-my-account-menu-btn'
      role='presentation'
    >
      <AvatarGroup max={2} spacing={20} className='group-avatar-wrapper'>
        <Avatar alt='user name' src={userPic} className='user-avatar'>
          {userAvatarName}
        </Avatar>
        {companyLogo && (
          <Avatar alt='Company name' src={companyLogo}>
            {companyAvatarName}
          </Avatar>
        )}
      </AvatarGroup>

      <div role='presentation' className='name-wrapper'>
        <p className='title' style={titleCusStyle}>
          {title}
        </p>
        {subTitle && (
          <p className='sub-title' style={subTitleCusStyle}>
            {subTitle}
          </p>
        )}
      </div>
    </div>
  );
};

export default GroupOfAvatarTitleSubTitleComp;
