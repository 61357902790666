import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import Dropdown from './Dropdown';

const MenuItems = ({ menu, navigateToUrl, history }) => {
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef();

  const onMouseEnter = () => {
    if (window.innerWidth > 769) {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth > 769) {
      setDropdown(false);
    }
  };

  return (
    <li
      className='menu-items'
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {menu.menuList ? (
        <>
          <Button
            type='button'
            aria-haspopup='menu'
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => history.push(menu.menuList[0].path)}
            className={menu?.allowingPaths?.map((path) => {
              return history.location.pathname === path
                ? 'nav-btn active'
                : 'nav-btn';
            })}
          >
            {menu.title}
          </Button>
          <Dropdown
            submenus={menu.menuList}
            dropdown={dropdown}
            setDropdown={setDropdown}
            navigateToUrl={navigateToUrl}
          />
        </>
      ) : (
        <Button
          className={menu?.allowingPaths?.map((path) => {
            return history.location.pathname === path
              ? 'nav-btn active'
              : 'nav-btn';
          })}
          onClick={menu.path ? () => history.push(menu.path) : menu.clickFunc}
          href={menu.href}
          target='blank'
          disableRipple
          id={menu.id}
        >
          {menu.title}
        </Button>
      )}
    </li>
  );
};

export default MenuItems;
