import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import UncheckedIcon from '../../../assets/icons/un-checked.svg';
import CheckedIcon from '../../../assets/icons/checked.svg';

const CheckboxComp = ({
  id = '',
  name,
  label,
  checked,
  handleChecked,
  className = '',
  cusStyle = { padding: 0 },
}) => {
  const CusCheckbox = () => {
    return (
      <Checkbox
        color='primary'
        className={className}
        checked={checked}
        onClickCapture={handleChecked}
        icon={<img src={UncheckedIcon} alt='unchecked icon' />}
        checkedIcon={<img src={CheckedIcon} alt='checked icon' />}
        disableRipple
        id={id}
        name={name}
        style={cusStyle}
      />
    );
  };
  return label ? (
    <FormControlLabel control={<CusCheckbox />} label={label} />
  ) : (
    <CusCheckbox />
  );
};

export default CheckboxComp;
