import React, { memo } from 'react';
import _ from 'lodash';
import { List, ListItem, Menu, MenuItem, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { searchVisaTypeOption } from '../../../../helpers/locationHelper';
import { langLevels } from '../../../../helpers/langHelper';
import FigChevronDown from '../../../../assets/icons/figma-pro-2-8/arrows/FigChevronDown/FigChevronDown';
import IconComp from '../../../../components/shared/icons/IconComp';

const useStyles = makeStyles({
  locationIcon: {
    borderRadius: '5px',
    fontSize: '1.5rem',
    position: 'relative',
    width: '16px',
    height: '16px',
    right: 0,
  },
  paddingZero: {
    padding: 0,
  },
});

const FilterSwitchV2 = ({ option, checked, onSwitch, index, name }) => {
  const classes = useStyles();
  const [tCommon] = useTranslation('common');

  const options = name === 'location' ? searchVisaTypeOption : langLevels;
  const shortLabel =
    name === 'location' ? 'visa_option_short_label' : 'lang_level_short_label';
  const longLabel =
    name === 'location' ? 'visa_option_long_label' : 'lang_level_long_label';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSelectVisaType = (event) => {
    onSwitch(index, event.currentTarget.value);
    handleClose();
  };

  return (
    <div className='filter-chip__inner'>
      <List
        style={{ overflow: 'auto', minWidth: '100%', cursor: 'pointer' }}
        onClick={handleClick}
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
      >
        <ListItem
          className={classes.paddingZero}
          style={{ fontWeight: 'bold' }}
        >
          <Tooltip title={option.label}>
            <span className='filter-chip__inner-txt'>{option.label} </span>
          </Tooltip>

          <IconComp
            icon={<FigChevronDown className={classes.locationIcon} />}
          />
        </ListItem>

        <ListItem
          className='filter-chip__inner-txt'
          style={{ paddingLeft: 0, paddingBottom: 0 }}
        >
          <Tooltip
            title={`${tCommon(`${longLabel}.${checked}.1`)} ${tCommon(
              `${longLabel}.${checked}.2`
            )} ${tCommon(`${longLabel}.${checked}.3`)}`}
          >
            <span className='filter-chip__inner-txt'>
              {tCommon(`${longLabel}.${checked}.1`)}
              <span>{tCommon(`${longLabel}.${checked}.2`)}</span>
              {tCommon(`${longLabel}.${checked}.3`)}
            </span>
          </Tooltip>
        </ListItem>
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        {/* eslint-disable-next-line no-shadow */}
        {_.map(options, (eachType) => (
          <MenuItem
            key={eachType}
            value={eachType}
            selected={eachType === checked}
            onClick={onSelectVisaType}
          >
            {tCommon(`${shortLabel}.${eachType}`)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default memo(FilterSwitchV2);
