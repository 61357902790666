import React, { memo, useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TextSearch = ({
  field,
  className,
  onChange,
  option,
  onReset,
  ...rest
}) => {
  const [t] = useTranslation('commonV1');
  const [text, setText] = useState('');

  useEffect(() => {
    setText(option.value);
  }, [option.value]);

  const timeout = useRef(null);
  const onTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onChange(field, newText, option);
    }, 200);
  };

  return (
    <div className='filters__item'>
      <div className='filters__head'>
        <div className='filters__title'>{t(option.label || field)}</div>
      </div>
      <div className='filters__body'>
        <TextField
          className={className}
          {...rest}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onTextChange}
          value={text}
        />
      </div>
    </div>
  );
};

export default memo(TextSearch);
